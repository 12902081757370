import { useTranslation } from 'react-i18next';
import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { CardToolbox, Main } from '@/container/styled';
import { PageHeader } from '@/components/page-headers/page-headers';
import withAuthLayout from '@/layouts/withAuthLayout';
import Table from '@/widgets/Table/Table';
import AsyncSelectFilter from '@/widgets/Table/AsyncSelectFilter';
import TextFilter from '@/widgets/Table/TextFilter';

const StoreIndex = () => {
  const { t } = useTranslation();

  const columns = React.useMemo(() => [
    {
      title: t('stores.fields.city'),
      dataIndex: 'city',
      key: 'city_id',
      render: (value) => <span>{value ? value.name : 'Не визначено'}</span>,
      filterDropdown: (props) => <AsyncSelectFilter {...props} searchUrl="cities/search" />,
    },
    {
      title: t('stores.fields.name'),
      dataIndex: 'name',
      key: 'name',
      filterDropdown: (props) => <TextFilter {...props} />,
    },
    {
      title: t('stores.fields.address'),
      dataIndex: 'address',
      key: 'address',
    },
  ]);

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={t('stores.list_page_header')}
          buttons={[
            <Button className="btn-add_new" size="default" type="primary" key="1">
              <Link to="/cms/stores/create">+ {t('stores.create_button')}</Link>
            </Button>,
          ]}
        />
      </CardToolbox>

      <Main>
        <Table route="cms/stores" resource="stores" columns={columns} permissionNamespace="cms.stores" actionOptions={{ isDeletable: false }} />
      </Main>
    </>
  );
};

export default withAuthLayout(StoreIndex);
