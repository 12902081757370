import React from 'react';
import PropTypes from 'prop-types';
import { PopoverStyle, Title, Content } from '@/components/popup/style';

const Popover = ({ content, placement, title, action, open, onOpenChange, children }) => {
  return (
    <PopoverStyle
      placement={placement}
      title={title && <Title>{title}</Title>}
      content={<Content>{content}</Content>}
      trigger={action}
      open={open}
      onOpenChange={onOpenChange}
    >
      {children}
    </PopoverStyle>
  );
};

Popover.propTypes = {
  placement: PropTypes.string,
  title: PropTypes.string,
  action: PropTypes.string,
  content: PropTypes.node,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  onOpenChange: PropTypes.func.isRequired,
};

export { Popover };
