import React, { useRef } from 'react';
import { Button, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const TextFilter = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, placeholder = 'Пошук' }) => {
  const searchInput = useRef(null);

  return (
    <div style={{ padding: '10px' }}>
      <Input
        ref={searchInput}
        placeholder={placeholder}
        value={selectedKeys}
        onChange={(e) => setSelectedKeys(e.target.value ? e.target.value : '')}
        onPressEnter={confirm}
        style={{
          marginBottom: 8,
          display: 'block',
        }}
      />
      <Space>
        <Button type="primary" onClick={confirm} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
          Пошук
        </Button>
        <Button type="link" size="small" onClick={clearFilters}>
          Очистити
        </Button>
      </Space>
    </div>
  );
};

TextFilter.propTypes = {
  setSelectedKeys: PropTypes.func,
  selectedKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  confirm: PropTypes.func,
  clearFilters: PropTypes.func,
  placeholder: PropTypes.string,
};

export default TextFilter;
