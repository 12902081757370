import { useTranslation } from 'react-i18next';
import { Col, Form, InputNumber, Row } from 'antd';
import React from 'react';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import useFormValues from '@/hooks/useFormValues';
import { PageHeader } from '@/components/page-headers/page-headers';
import { Main } from '@/container/styled';
import { Cards } from '@/components/cards/frame/cards-frame';
import { ActionsWrapper, FormWrapper } from '@/container/pages/style';
import FormInput from '@/form/FormInput';
import FormSubmit from '@/form/FormSubmit';
import withAuthLayout from '@/layouts/withAuthLayout';
import FormSelect from '@/form/FormSelect';
import DatePicker from '@/widgets/DatePicker';
import { PROMO_CODE_MODE_PERCENT, PROMO_CODE_MODE_VALUE } from '@/constants/promoCodes';
import MoneyInput from '@/widgets/MoneyInput';

dayjs.extend(isSameOrBefore);

const PromoCodeForm = () => {
  const { t } = useTranslation();
  const { form, loading, handleSubmit, errors } = useFormValues('promo-codes');

  const disabledDate = (current) => {
    return dayjs(current).isBefore(dayjs().startOf('day'));
  };

  const mode = Form.useWatch('mode', form);

  return (
    <>
      <PageHeader ghost title={t('promo_codes.list_page_header')} />
      <Main>
        <Cards headless>
          <Row justify="center">
            <Col xl={12} md={16} xs={24}>
              <FormWrapper>
                <Form style={{ width: '100%' }} form={form} layout="vertical" onFinish={handleSubmit} initialValues={{ valid_from: new Date() }}>
                  <FormInput name="name" label={t('promo_codes.fields.name')} required error={errors.name} />
                  <FormInput name="code" label={t('promo_codes.fields.code')} required error={errors.code} />
                  <Row gutter={24}>
                    <Col md={12} sm={24}>
                      <FormSelect
                        options={[
                          { value: PROMO_CODE_MODE_VALUE, label: t(`promo_codes.modes.${PROMO_CODE_MODE_VALUE}`) },
                          { value: PROMO_CODE_MODE_PERCENT, label: t(`promo_codes.modes.${PROMO_CODE_MODE_PERCENT}`) },
                        ]}
                        name="mode"
                        label={t('promo_codes.fields.mode')}
                        required
                        error={errors.mode}
                      />
                    </Col>
                    <Col md={12} sm={24}>
                      <Form.Item name="value" label={t('promo_codes.fields.value')} required validateStatus={errors.value ? 'error' : null} help={errors.value}>
                        {mode === PROMO_CODE_MODE_PERCENT ? <InputNumber style={{ width: '100%' }} max={100} min={1} /> : <MoneyInput min={1} />}
                      </Form.Item>
                    </Col>
                    <Col md={12} sm={24}>
                      <Form.Item
                        name="min_order_sum"
                        label={t('promo_codes.fields.min_order_sum')}
                        validateStatus={errors.min_order_sum ? 'error' : null}
                        help={errors.min_order_sum}
                      >
                        <MoneyInput min={1} />
                      </Form.Item>
                    </Col>
                    <Col md={12} sm={24}>
                      <Form.Item
                        name="usage_limit"
                        label={t('promo_codes.fields.usage_limit')}
                        validateStatus={errors.usage_limit ? 'error' : null}
                        help={errors.usage_limit}
                      >
                        <InputNumber style={{ width: '100%' }} min={1} />
                      </Form.Item>
                    </Col>
                    <Col md={12} sm={24}>
                      <Form.Item
                        required
                        name="valid_from"
                        label={t('promo_codes.fields.valid_from')}
                        validateStatus={errors.valid_from ? 'error' : null}
                        help={errors.valid_from}
                      >
                        <DatePicker disabledDate={disabledDate} />
                      </Form.Item>
                    </Col>
                    <Col md={12} sm={24}>
                      <Form.Item
                        required
                        name="valid_to"
                        label={t('promo_codes.fields.valid_to')}
                        validateStatus={errors.valid_to ? 'error' : null}
                        help={errors.valid_to}
                      >
                        <DatePicker disabledDate={disabledDate} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <ActionsWrapper>
                    <FormSubmit loading={loading} />
                  </ActionsWrapper>
                </Form>
              </FormWrapper>
            </Col>
          </Row>
        </Cards>
      </Main>
    </>
  );
};

export default withAuthLayout(PromoCodeForm);
