const packCount = {
  '1-10': {
    '0-49': 3,
    '50-69': 4,
    '70-89': 7,
    '90-1000': 8,
  },
  '11-24': {
    '0-49': 4,
    '50-69': 5,
    '70-89': 8,
    '90-1000': 9,
  },
  '25-34': {
    '0-49': 5,
    '50-69': 7,
    '70-89': 10,
    '90-1000': 11,
  },
  '35-50': {
    '0-49': 6,
    '50-69': 8,
    '70-89': 11,
    '90-1000': 12,
  },
  '51-74': {
    '0-49': 7,
    '50-69': 9,
    '70-89': 12,
    '90-1000': 13,
  },
  '75-100': {
    '0-49': 8,
    '50-69': 10,
    '70-89': 13,
    '90-1000': 14,
  },
  '101-150': {
    '0-49': 9,
    '50-69': 11,
    '70-89': 14,
    '90-1000': 15,
  },
  '151-2000': {
    '0-49': 12,
    '50-69': 14,
    '70-89': 16,
    '90-1000': 17,
  },
};

export const calculatePackCount = (count, height) => {
  // eslint-disable-next-line
  for (const countRange in packCount) {
    const split = countRange.split('-');

    if (count >= parseInt(split[0]) && count <= parseInt(split[1] || split[0])) {
      // eslint-disable-next-line
      for (const heightRange in packCount[countRange]) {
        const split = heightRange.split('-');

        if (height >= parseInt(split[0]) && height <= parseInt(split[1] || split[0])) {
          return packCount[countRange][heightRange];
        }
      }
    }
  }
};
