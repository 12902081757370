import React from 'react';
import { Button, Checkbox, Col, Form, Input, Row, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import FeatherIcon from 'feather-icons-react';
import slugify from 'slugify';
import { useTranslation } from 'react-i18next';
import { ProductSizeStyled } from './style';

const ProductSizeElem = ({ size = {}, onChange, onIsPrimaryChange, nameError, heightError, slugError, isPrimaryError, hasHeight }) => {
  const { t } = useTranslation();
  const makeSlug = () => {
    const slug = slugify(size.name, { lower: true });
    onChange('slug', slug);
  };

  return (
    <>
      <ProductSizeStyled>
        <Row gutter={24} className="w-100">
          <Col span={6}>
            <Form.Item label={t('products.sizes_fields.title')} validateStatus={nameError ? 'error' : false} help={nameError}>
              <Input value={size.name} onChange={(e) => onChange('name', e.target.value)} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={t('products.sizes_fields.slug')} validateStatus={slugError ? 'error' : false} help={slugError}>
              <Input.Group compact>
                <Input
                  value={size.slug}
                  onChange={(e) => onChange('slug', e.target.value)}
                  style={{
                    width: 'calc(100% - 40px)',
                  }}
                />
                <Tooltip title={t('products.sizes_fields.create_to_basis_name')}>
                  <Button onClick={makeSlug} icon={<FeatherIcon icon="refresh-ccw" size="12" />} />
                </Tooltip>
              </Input.Group>
            </Form.Item>
          </Col>
          {hasHeight && (
            <Col span={6}>
              <Form.Item label={t('products.sizes_fields.height')} validateStatus={heightError ? 'error' : false} help={heightError}>
                <Input value={size.height} onChange={(e) => onChange('height', e.target.value)} />
              </Form.Item>
            </Col>
          )}
          <Col span={4}>
            <Form.Item label={<span />} validateStatus={isPrimaryError ? 'error' : false} help={isPrimaryError}>
              <Checkbox checked={size.is_primary} onChange={(e) => onIsPrimaryChange(e.target.checked)}>
                {t('products.sizes_fields.main')}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </ProductSizeStyled>
    </>
  );
};

ProductSizeElem.propTypes = {
  size: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onIsPrimaryChange: PropTypes.func,
  nameError: PropTypes.array,
  heightError: PropTypes.array,
  slugError: PropTypes.array,
  isPrimaryError: PropTypes.array,
  hasHeight: PropTypes.bool,
};

export default ProductSizeElem;
