import React, { Suspense } from 'react';
import 'antd/dist/antd.less';
import './static/css/style.css';
import { Route, Routes } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { ThemeProvider } from 'styled-components';
import 'moment/locale/uk';
import locale from 'antd/es/locale/uk_UA';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import queryString from 'query-string';
import { UserContextProvider } from './helpers/userContext';
import { ErrorContextProvider } from './helpers/errorContext';
import ErrorBoundary from './helpers/ErrorBoundary';
import config from './config/config';
import mapRoutes from './routes';
import Spinner from '@/widgets/Spinner';
import NotFound from '@/container/pages/404';

const { theme } = config;

function App() {
  return (
    <ConfigProvider locale={locale}>
      <ErrorContextProvider>
        <ThemeProvider theme={{ ...theme }}>
          <ErrorBoundary>
            <UserContextProvider>
              <QueryParamProvider
                adapter={ReactRouter6Adapter}
                options={{
                  objectToSearchString: queryString.stringify,
                  enableBatching: true,
                }}
              >
                <Suspense fallback={<Spinner />}>
                  <Routes>
                    {mapRoutes()}
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Suspense>
              </QueryParamProvider>
            </UserContextProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </ErrorContextProvider>
    </ConfigProvider>
  );
}

export default App;
