import React, { useEffect, useState } from 'react';
import { Col, Drawer, Form, Input, InputNumber, Radio, Row } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { Button } from '../buttons/buttons';
import AsyncSelect from '../../widgets/AsyncSelect';
import {
  productItemTypeToBillingGoodType,
  PRODUCT_ITEM_TYPE_BOX,
  PRODUCT_ITEM_TYPE_FLOWER,
  PRODUCT_ITEM_TYPE_PACK,
  PRODUCT_ITEM_TYPE_TAPE,
} from '@/constants/products';

const ProductItemDrawer = ({ item, open, packCount, onClose, onSave, index, errors }) => {
  const { t } = useTranslation();
  const [data, setData] = useState(item);

  const handleClose = () => {
    setData(item);
    onClose();
  };

  const handleChange = (key, value) => {
    setData((data) => {
      return { ...data, [key]: value };
    });
  };

  const handleChangeBillingGoodId = (value) => {
    handleChange('billing_good_id', value);
    handleChange('billing_good_characteristic_id', null);
  };

  const handleChangeBillingGoodCharacteristicId = (value, ext) => {
    handleChange('billing_good_characteristic_id', value);

    const { children } = ext;

    if (data.type === PRODUCT_ITEM_TYPE_FLOWER) {
      if (children.match(/(\d+)\s*см/)) {
        handleChange('height', children.match(/(\d+)\s*см/)[1]?.toString());
      }
    }
  };

  const handleChangeType = (value) => {
    handleChange('name', null);
    handleChange('billing_good_id', null);
    handleChange('billing_good_characteristic_id', null);
    handleChange('quantity', null);
    handleChange('type', value);
  };

  const handleSave = () => {
    onSave(data);
    onClose();
  };

  useEffect(() => {
    if (data.type === PRODUCT_ITEM_TYPE_PACK) {
      handleChange('quantity', packCount);
    }
  }, [packCount, data.type]);

  const disabledSave = !data.name || !data.billing_good_id || !data.billing_good_characteristic_id || !data.quantity;

  const hasColor = [PRODUCT_ITEM_TYPE_BOX, PRODUCT_ITEM_TYPE_TAPE, PRODUCT_ITEM_TYPE_PACK].includes(data.type);

  return (
    <Drawer
      title={t('products.items.drawer_title')}
      open={open}
      onCancel={onClose}
      onClose={handleClose}
      width={600}
      footer={
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 25 }}>
          <Button type="info" size="large" onClick={onClose}>
            {t('close')}
          </Button>
          <Button type="success" size="large" onClick={handleSave} disabled={disabledSave}>
            {t('save')}
          </Button>
        </div>
      }
    >
      <Form layout="vertical">
        <Form.Item label="Тип">
          <Radio.Group value={data.type} onChange={(e) => handleChangeType(e.target.value)}>
            <Radio name="type" value={PRODUCT_ITEM_TYPE_FLOWER}>
              {t(`products.product_item_types.${PRODUCT_ITEM_TYPE_FLOWER}`)}
            </Radio>
            <Radio name="type" value={PRODUCT_ITEM_TYPE_PACK}>
              {t(`products.product_item_types.${PRODUCT_ITEM_TYPE_PACK}`)}
            </Radio>
            <Radio name="type" value={PRODUCT_ITEM_TYPE_BOX}>
              {t(`products.product_item_types.${PRODUCT_ITEM_TYPE_BOX}`)}
            </Radio>
            <Radio name="type" value={PRODUCT_ITEM_TYPE_TAPE}>
              {t(`products.product_item_types.${PRODUCT_ITEM_TYPE_TAPE}`)}
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label={t('products.billing_good_id')}
              validateStatus={get(errors, `items.${index}.billing_good_id`) ? 'error' : null}
              help={get(errors, `items.${index}.billing_good_id`)}
            >
              <AsyncSelect
                resource="billing/goods-list"
                params={{ type: productItemTypeToBillingGoodType[data.type] }}
                value={data.billing_good_id}
                onChange={(value) => handleChangeBillingGoodId(value)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('products.billing_good_characteristic_id')}
              validateStatus={get(errors, `items.${index}.billing_good_characteristic_id`) ? 'error' : null}
              help={get(errors, `items.${index}.billing_good_characteristic_id`)}
            >
              <AsyncSelect
                disabled={!data.billing_good_id}
                resource="billing/goods-characteristic-list"
                params={{ billing_good_id: data.billing_good_id }}
                value={data.billing_good_characteristic_id}
                onChange={(value, ext) => handleChangeBillingGoodCharacteristicId(value, ext)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t('products.items.name')}
              validateStatus={get(errors, `items.${index}.name`) ? 'error' : null}
              help={get(errors, `items.${index}.name`)}
            >
              <Input required value={data.name} onChange={(e) => handleChange('name', e.target.value)} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('products.items.count')}
              validateStatus={get(errors, `items.${index}.quantity`) ? 'error' : null}
              help={get(errors, `items.${index}.quantity`)}
            >
              <InputNumber
                style={{ width: '100%' }}
                value={data.quantity}
                onChange={(value) => handleChange('quantity', value)}
                disabled={!data.billing_good_id || !data.billing_good_characteristic_id}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('products.items.height')}
              validateStatus={get(errors, `items.${index}.height`) ? 'error' : null}
              help={get(errors, `items.${index}.height`)}
            >
              <InputNumber
                style={{ width: '100%' }}
                value={data.height}
                onChange={(value) => handleChange('height', value)}
                disabled={data.type !== PRODUCT_ITEM_TYPE_FLOWER || !data.billing_good_id || !data.billing_good_characteristic_id}
              />
            </Form.Item>
          </Col>
          {hasColor && (
            <Col span={24}>
              <Form.Item
                label={t('products.items.color')}
                validateStatus={get(errors, `items.${index}.color`) ? 'error' : null}
                help={get(errors, `items.${index}.color`)}
              >
                <Input required value={data.color} onChange={(e) => handleChange('color', e.target.value)} />
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
    </Drawer>
  );
};

ProductItemDrawer.propTypes = {
  item: PropTypes.object,
  open: PropTypes.bool,
  packCount: PropTypes.number,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.object,
};

export default ProductItemDrawer;
