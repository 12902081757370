import PropTypes from 'prop-types';
import { Divider, Empty } from 'antd';
import { OrderedMap } from 'immutable';
import { v4 as uuidv4 } from 'uuid';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProductItemElem from './ProductItemElem';
import { Button } from '../buttons/buttons';
import useImmutableData from '../../hooks/useImmutableData';
import { PRODUCT_ITEM_TYPE_FLOWER } from '@/constants/products';
import ProductItemDrawer from '@/components/Product/ProductItemDrawer';
import { calculatePackCount } from '@/helpers/calculatePackCount';
import { ProductItemStyled } from '@/components/Product/style';

const item = {
  name: '',
  quantity: 0,
  billing_good_id: '',
  billing_good_characteristic_id: '',
  prices: [],
  type: PRODUCT_ITEM_TYPE_FLOWER,
  height: null,
};

const ProductItemsForm = ({ value = [], onChange, errors }) => {
  const [data, setData] = useImmutableData(OrderedMap(value), onChange);
  const [activeId, setActiveId] = useState(null);

  const { t } = useTranslation();

  const addItem = () => {
    const id = uuidv4();
    setActiveId(id);
  };

  const handleChange = (id) => {
    return (value) => {
      setData((data) => {
        return data.set(id, value);
      });
    };
  };

  const handleDelete = (id) => {
    setData((data) => {
      return data.delete(id);
    });
  };

  const totalFlowersCount = data.reduce((sum, x) => {
    return x.type === PRODUCT_ITEM_TYPE_FLOWER ? sum + x.quantity : sum;
  }, 0);

  const maxHeight = data.filter((elem) => elem.type === PRODUCT_ITEM_TYPE_FLOWER)?.max((a, b) => a.quantity > b.quantity)?.height;

  const packCount = calculatePackCount(totalFlowersCount, maxHeight);

  return (
    <div>
      {activeId && (
        <ProductItemDrawer
          open
          item={data.get(activeId) || item}
          packCount={packCount}
          onClose={() => setActiveId(null)}
          onSave={handleChange(activeId)}
          index={data.findKey((el) => +el.id === +activeId)}
          errors={errors}
        />
      )}
      {data.size ? (
        data.entrySeq().map(([id, elem], i) => {
          const invalid = Object.keys(errors).findIndex((el) => el.includes(`items.${i + 1}`)) !== -1;

          return (
            <div key={id}>
              <ProductItemStyled key={id}>
                <ProductItemElem item={elem} onEdit={() => setActiveId(id)} onDelete={() => handleDelete(id)} invalid={invalid} />
              </ProductItemStyled>
              <Divider />
            </div>
          );
        })
      ) : (
        <Empty style={{ marginBottom: '20px' }} />
      )}
      <Button type="dashed" style={{ width: '100%' }} onClick={addItem}>
        {t('add')}
      </Button>
    </div>
  );
};

ProductItemsForm.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  errors: PropTypes.object,
};

export default ProductItemsForm;
