import { useCallback, useState } from 'react';

export function useToggle(init = false) {
  const [open, setOpen] = useState(init);
  const toggle = useCallback(() => {
    setOpen((open) => !open);
  }, []);

  return [open, toggle];
}
