import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { TRANSLATIONS_UA } from './ua';

i18n.use(initReactI18next).init({
  resources: {
    ua: {
      translation: TRANSLATIONS_UA,
    },
  },
});

i18n.changeLanguage('ua');

export default i18n;
