import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, Col, Form, InputNumber, Row, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import FontAwesome from 'react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { Main, SegmentFormStyled } from '../../../styled';
import { FormWrapper } from '../../style';
import FormInput from '@/form/FormInput';
import withAuthLayout from '@/layouts/withAuthLayout';
import useFormValues from '@/hooks/useFormValues';
import { PageHeader } from '@/components/page-headers/page-headers';
import { Cards } from '@/components/cards/frame/cards-frame';
import { Button } from '@/components/buttons/buttons';
import DeliveryPoints from '@/components/City/DeliveryPoints';
import AsyncSelect from '@/widgets/AsyncSelect';
import GeoClient from '@/helpers/apiClient/GeoClient';
import FormSelectAsync from '@/form/FormSelectAsync';
import DrawZoneModal from '@/components/City/DrawZoneModal';
import ApiClient from '@/helpers/apiClient/ApiClient';

const CityForm = () => {
  const { t } = useTranslation();
  const { form, id, loading, handleSubmit, model, errors } = useFormValues('cities');
  const navigate = useNavigate();
  const regionId = Form.useWatch('ext_region_id', form);
  const billingCityId = Form.useWatch('billing_city_id', form);
  const [cityName, setCityName] = useState(null);
  const [center, setCenter] = useState([]);
  const [drawZoneMapOpen, setDrawZoneMapOpen] = useState(false);

  useEffect(() => {
    ApiClient.call('get', 'billing/get-cities', { ids: [billingCityId] }).data((data) => {
      if (data.length) {
        setCityName(data[0].label);
      }
    });
  }, [billingCityId]);

  const updateCityCoords = useCallback(async () => {
    if (cityName) {
      const coords = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&accept-language=ua-UA&limit=1&addressdetails=1&countrycode=UA&city=${cityName}&country=UA`,
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.length) {
            const { lat, lon } = data[0];
            return { lat, lon };
          }

          return null;
        })
        .catch(() => {
          return null;
        });

      if (coords) {
        setCenter([coords.lat, coords.lon]);
      }
    }
  }, [cityName, model.name]);

  useEffect(() => {
    updateCityCoords();
  }, [updateCityCoords]);

  return (
    <>
      <PageHeader
        ghost
        title={t('cities.list_page_header')}
        subTitle={id && model.name}
        buttons={[
          <Button size="large" type="success" onClick={() => handleSubmit(form.getFieldsValue())} raised key={1} loading={loading}>
            {t('save')}
          </Button>,
          <Button
            key={2}
            className="btn-cancel"
            size="large"
            onClick={() => {
              navigate(-1);
            }}
          >
            {t('cancel')}
          </Button>,
        ]}
      />
      <Main>
        <SegmentFormStyled>
          <FormWrapper>
            <Form
              style={{ width: '100%' }}
              form={form}
              layout="vertical"
              onFinish={handleSubmit}
              initialValues={{
                is_default: false,
              }}
            >
              <Row justify="center" gutter={24}>
                <Col xl={10} md={12} xs={24}>
                  <div className="segment-block">
                    <div className="segment-content">
                      <Cards title={t('cities.details')}>
                        <FormSelectAsync name="billing_city_id" label={t('cities.fields.billing_city_id')} resource="billing/get-cities" required />
                        <FormInput name="name" label={t('cities.fields.name')} required />
                        <FormInput name="slug" label={t('cities.fields.slug')} error={errors.slug} placeholder="vn" required />
                        <Form.Item
                          name="orders_for_delivery_limit"
                          label={t('cities.fields.orders_for_delivery_limit')}
                          validateStatus={errors.orders_for_delivery_limit ? 'error' : null}
                          help={errors.orders_for_delivery_limit}
                        >
                          <InputNumber style={{ width: '100%' }} min={1} />
                        </Form.Item>
                        <FormInput name="instagram_link" label={t('cities.fields.instagram_link')} error={errors.instagram_link} required />
                        <FormInput name="phone" label={t('cities.fields.phone')} error={errors.phone} required />
                        <FormInput name="email" label={t('cities.fields.email')} error={errors.email} required />
                        <Form.Item name="is_default" valuePropName="checked">
                          <Checkbox>
                            {t('cities.fields.is_default')}{' '}
                            <Tooltip title={t('cities.default_city_explain')}>
                              <FontAwesome name="question-circle-o" className="super-crazy-colors" />
                            </Tooltip>
                          </Checkbox>
                        </Form.Item>
                      </Cards>
                    </div>
                  </div>
                </Col>

                <Col xl={14} md={12} xs={24}>
                  <div className="segment-block">
                    <div className="segment-content">
                      <Cards title={t('delivery_zones.modal_title')}>
                        <Row gutter={24}>
                          <Col span={12}>
                            <Form.Item name="ext_region_id" validateStatus={errors.ext_region_id ? 'error' : false} help={errors.ext_region_id} label="Область">
                              <AsyncSelect
                                resource="regions"
                                client={GeoClient}
                                onChange={(value, option) => {
                                  form.setFieldsValue({
                                    ext_region_id: value,
                                    region_name: option ? option.children : '',
                                  });
                                }}
                              />
                            </Form.Item>
                            <Form.Item name="region_name" hidden />
                          </Col>
                          {id && (
                            <Col span={12}>
                              <Form.Item label=" ">
                                <Button type="primary" size="large" style={{ width: '100%' }} onClick={() => setDrawZoneMapOpen(true)} loading={!center.length}>
                                  {t('delivery_zones.modal_title')}
                                </Button>
                              </Form.Item>
                            </Col>
                          )}
                        </Row>
                        {Boolean(center.length) && id && drawZoneMapOpen && (
                          <DrawZoneModal cityId={id} center={center} onClose={() => setDrawZoneMapOpen(false)} />
                        )}

                        <Form.Item name="delivery_points" required label={t('cities.fields.delivery_points')}>
                          {regionId && <DeliveryPoints regionId={regionId} errors={errors} />}
                        </Form.Item>
                      </Cards>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          </FormWrapper>
        </SegmentFormStyled>
      </Main>
    </>
  );
};

export default withAuthLayout(CityForm);
