import React, { useEffect, useState } from 'react';
import { Row, Col, Switch, message, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import { List } from 'immutable';
import { NotificationWrapper } from './style';
import { Cards } from '@/components/cards/frame/cards-frame';
import { Button } from '@/components/buttons/buttons';
import Heading from '@/components/heading/heading';
import ApiClient from '@/helpers/apiClient/ApiClient';
import { useAuth } from '@/helpers/userContext';
import { NOTIFICATION_CHANNEL_SMS } from '@/constants/notifications';

const listStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: 0,
  padding: 0,
};

function Notification() {
  const { t } = useTranslation();

  const { user } = useAuth();
  const [all, setAll] = useState({});
  const [selected, setSelected] = useState(List(user.notifications));

  useEffect(() => {
    ApiClient.call('get', 'notifications').data(({ data }) => {
      setAll(data);
    });
  }, []);

  const onSwitch = (type, channel, checked) => {
    const index = selected.findIndex((elem) => elem.type === type);

    if (index !== -1) {
      setSelected((prev) => {
        return checked
          ? prev.updateIn([index, 'channels'], (arr) => [...arr, channel])
          : prev.updateIn([index, 'channels'], (arr) => arr.filter((el) => el !== channel));
      });
    } else {
      setSelected((prev) => {
        return prev.push({ type, channels: [channel] });
      });
    }
  };

  const onSave = () => {
    const data = selected.filter((notification) => notification.channels.length !== 0);

    ApiClient.call('post', 'auth/update-notifications', {}, { notifications: data.toJS() }).data(() => {
      message.success('Успішно');
    });
  };

  return (
    <NotificationWrapper>
      <Cards
        title={
          <div className="setting-card-title">
            <Heading as="h4">{t('account.notification.heading')}</Heading>
            <span>{t('account.notification.description')}</span>
          </div>
        }
      >
        <Row gutter={[24, 24]}>
          {Boolean(all) &&
            map(all, (group) => {
              return (
                <Col xs={24} key={group.name}>
                  <div className="notification-box-single">
                    <Cards headless bodyStyle={{ backgroundColor: '#F7F8FA', borderRadius: 10 }}>
                      <div
                        style={{
                          height: '50px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                        className="notification-header"
                      >
                        <Heading className="notification-header__text" as="h4">
                          {group.name}
                        </Heading>
                      </div>
                      <div className="notification-body">
                        <Cards headless>
                          <nav>
                            <ul
                              style={{
                                margin: 0,
                                padding: 0,
                              }}
                            >
                              {group.values.map((notification, i) => {
                                return (
                                  <li style={listStyle} key={i}>
                                    <div className="notification-list-single">
                                      <Heading className="notification-list-single__title" as="h4">
                                        {notification.title}
                                      </Heading>
                                      <p>{notification.description}</p>
                                    </div>
                                    <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
                                      {notification.channels.map((channel, i) => {
                                        const checked = selected.findIndex((el) => el.type === notification.value && el.channels.includes(channel)) !== -1;

                                        return (
                                          <Switch
                                            key={i}
                                            checked={checked}
                                            onChange={(checked) => onSwitch(notification.value, channel, checked)}
                                            checkedChildren={t(`account.notification.channels.${channel}`)}
                                            unCheckedChildren={t(`account.notification.channels.${channel}`)}
                                            disabled={!user.phone_number && channel === NOTIFICATION_CHANNEL_SMS}
                                          />
                                        );
                                      })}
                                    </Space>
                                  </li>
                                );
                              })}
                            </ul>
                          </nav>
                        </Cards>
                      </div>
                    </Cards>
                  </div>
                </Col>
              );
            })}
        </Row>
        <div className="notification-actions">
          <Button size="default" type="primary" onClick={onSave}>
            {t('save')}
          </Button>
        </div>
      </Cards>
    </NotificationWrapper>
  );
}

export default Notification;
