import { useCallback, useEffect } from 'react';
import moment from 'moment/moment';

export default function useFetchStatistics(fetchReceipts, fetchRevenue) {
  useEffect(() => {
    fetchReceipts(moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'));
    fetchRevenue(moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'));
  }, []);

  const fetchCallback = useCallback(
    (start, end) => {
      fetchReceipts(start, end);
      fetchRevenue(start, end);
    },
    [fetchReceipts, fetchRevenue],
  );

  return { fetchCallback };
}
