import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { CardToolbox, Main } from '@/container/styled';
import { PageHeader } from '@/components/page-headers/page-headers';
import withAuthLayout from '@/layouts/withAuthLayout';
import Table from '@/widgets/Table/Table';

const MainPageCarouselIndex = () => {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        title: t('main_page_carousel.fields.city_id'),
        dataIndex: ['city', 'name'],
        key: 'city_id',
      },
    ],
    [],
  );

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={t('main_page_carousel.list_page_header')}
          buttons={[
            <Button className="btn-add_new" size="default" type="primary" key="1">
              <Link to="/cms/main-page/carousel/create">+ {t('main_page_carousel.create_button')}</Link>
            </Button>,
          ]}
        />
      </CardToolbox>

      <Main>
        <Table route="cms/main-page/carousel" resource="main-page/carousel" columns={columns} permissionNamespace="cms.main_page.carousel" />
      </Main>
    </>
  );
};

export default withAuthLayout(MainPageCarouselIndex);
