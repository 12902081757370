import React from 'react';
import { Form, Select } from 'antd';
import PropTypes from 'prop-types';

const FormSelect = ({ name, label, options, mode, error, placeholder, required = false }) => {
  return (
    <Form.Item label={label} required={required} validateStatus={error ? 'error' : false} help={error} name={name}>
      <Select
        mode={mode}
        placeholder={placeholder}
        allowClear
        showSearch
        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
        options={options}
      />
    </Form.Item>
  );
};

FormSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  mode: PropTypes.oneOf(['multiple', 'tags']),
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  required: PropTypes.any,
};

export default FormSelect;
