import { Empty, Select } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import ApiClient from '../helpers/apiClient/ApiClient';
import { SelectWrapperStyle } from '@/container/ui-elements/ui-elements-styled';

const { Option } = Select;

const AsyncSelect = ({ value, onChange, resource, params, disabled = false, multiple = false, client = ApiClient }) => {
  const [options, setOptions] = useState([]);
  const [cachedValue, setCachedValue] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const paramsRef = useRef(params);

  if (!isEqual(paramsRef.current, params)) {
    paramsRef.current = params;
  }

  const onSearch = useCallback(
    (text = '') => {
      setLoading(true);
      client
        .call('get', `${resource}`, { ...paramsRef.current, q: text })
        .data((data) => {
          setError(null);
          setOptions(data);
          setLoading(false);
        })
        .catch(({ data }) => {
          setError(data.message);
          setOptions([]);
        });
    },
    [resource, paramsRef.current],
  );

  useEffect(() => {
    if (!disabled) {
      onSearch();
    }
  }, [disabled, onSearch]);

  useEffect(() => {
    if (multiple && value?.length === 0) {
      return;
    }
    if (value) {
      client.call('get', `${resource}`, { ids: multiple ? value : [value], ...paramsRef.current }).data((data) => {
        setCachedValue(data);
      });
    } else {
      setCachedValue(undefined);
    }
  }, [value, resource, multiple, paramsRef.current]);

  const handelChange = (selected, ext) => {
    onChange(selected, ext);
    setCachedValue(selected);
  };

  const handleClear = () => {
    handelChange(null);
    onSearch();
  };

  return (
    <SelectWrapperStyle>
      <Select
        mode={multiple && 'multiple'}
        style={{ width: '100%' }}
        showSearch
        onSearch={onSearch}
        onChange={handelChange}
        filterOption={false}
        value={cachedValue}
        disabled={disabled}
        placeholder="Оберіть..."
        loading={loading}
        allowClear
        notFoundContent={error ? <span>{error}</span> : <Empty />}
        onClear={handleClear}
      >
        {options.map((option) => {
          return (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          );
        })}
      </Select>
    </SelectWrapperStyle>
  );
};

AsyncSelect.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  resource: PropTypes.string.isRequired,
  params: PropTypes.object,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  client: PropTypes.object,
};

export default AsyncSelect;
