import React from 'react';
import { Checkbox, Col, Form, Row, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { MapContainer, Marker, useMapEvents } from 'react-leaflet';
import { Icon } from 'leaflet/src/layer';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { Main } from '../../../styled';
import { ActionsWrapper, FormWrapper } from '../../style';
import FormInput from '@/form/FormInput';
import withAuthLayout from '@/layouts/withAuthLayout';
import useFormValues from '@/hooks/useFormValues';
import { Cards } from '@/components/cards/frame/cards-frame';
import { PageHeader } from '@/components/page-headers/page-headers';
import FormSubmit from '@/form/FormSubmit';
import FormSelectAsync from '@/form/FormSelectAsync';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { defaultCenter } from '@/constants/leaflet';
import config from '@/config.json';

const CoordsSelector = ({ value, onChange }) => {
  const Position = () => {
    useMapEvents({
      click(e) {
        onChange([e.latlng.lat, e.latlng.lng]);
      },
    });

    return value ? <Marker icon={new Icon({ iconUrl: '/location.svg', iconSize: [32, 32] })} position={value} interactive={false} /> : null;
  };

  return (
    <div style={{ width: '100%' }}>
      <div style={{ height: 400 }}>
        <MapContainer center={value || defaultCenter} zoom={15} scrollWheelZoom>
          <ReactLeafletGoogleLayer apiKey={config.googleMapsApiKey} type="roadmap" />
          <Position />
        </MapContainer>
      </div>
    </div>
  );
};

CoordsSelector.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
};

const StoreForm = () => {
  const { t } = useTranslation();
  const { form, id, loading, handleSubmit, errors } = useFormValues('stores');

  const cityId = Form.useWatch('city_id', form);

  return (
    <>
      <PageHeader ghost title={t('stores.list_page_header')} />
      <Main>
        <Cards headless>
          <Row justify="center">
            <Col xl={16} md={24} xs={24}>
              <FormWrapper>
                <Form
                  style={{ width: '100%' }}
                  form={form}
                  layout="vertical"
                  onFinish={handleSubmit}
                  initialValues={{ is_primary: false, is_self_pick_available: false, is_shown_in_contacts: false }}
                >
                  <FormSelectAsync
                    resource="cities/search"
                    name="city_id"
                    label={t('stores.fields.city')}
                    error={errors.city_id}
                    required
                    disabled={Boolean(id) && cityId}
                  />
                  <FormSelectAsync
                    required
                    name="billing_storage_id"
                    label={t('stores.fields.billing_storage_id')}
                    resource={`billing/get-storages-by-local-city/${cityId}`}
                    disabled={Boolean(id) || !cityId}
                  />
                  <FormInput name="name" label={t('stores.fields.name')} error={errors.name} required placeholder="Соборна" />
                  <FormInput name="address" label={t('stores.fields.address')} error={errors.address} required />
                  <FormInput name="p_service_counter_id" label={t('stores.fields.counter_p_service')} error={errors.counter_p_service} />

                  <Row>
                    <Col md={8} sm={24}>
                      <Form.Item name="is_primary" valuePropName="checked">
                        <Checkbox>
                          {t('stores.fields.is_primary')}{' '}
                          <Tooltip title={t('stores.primary_explain')}>
                            <FontAwesome name="question-circle-o" className="super-crazy-colors" />
                          </Tooltip>
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col md={8} sm={24}>
                      <Form.Item name="is_self_pick_available" valuePropName="checked">
                        <Checkbox>{t('stores.fields.is_self_pick_available')}</Checkbox>
                      </Form.Item>
                    </Col>
                    <Col md={8} sm={24}>
                      <Form.Item name="is_shown_in_contacts" valuePropName="checked">
                        <Checkbox>{t('stores.fields.is_shown_in_contacts')}</Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item label={t('stores.fields.coords')} name="coords" validateStatus={errors.coords ? 'error' : false} help={errors.coords}>
                    <CoordsSelector />
                  </Form.Item>
                  <ActionsWrapper>
                    <FormSubmit loading={loading} />
                  </ActionsWrapper>
                </Form>
              </FormWrapper>
            </Col>
          </Row>
        </Cards>
      </Main>
    </>
  );
};

export default withAuthLayout(StoreForm);
