import React, { useCallback, useEffect, useState } from 'react';
import { Input, Button } from 'antd';
import PropTypes from 'prop-types';
import { SearchOutlined } from '@ant-design/icons';
import { AutoCompleteStyled } from './style';
import ApiClient from '../../helpers/apiClient/ApiClient';

const AutoComplete = ({ customComponent, patterns, patternButtons, width = '350px', placeholder = 'Input here', searchUrl, onSelect }) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const onSearch = useCallback(
    (text = '') => {
      ApiClient.call('get', searchUrl, { q: text }).data((data) => {
        setOptions(
          data.map((elem) => {
            return {
              value: elem.value.toString(),
              label: elem.label,
            };
          }),
        );
      });
    },
    [searchUrl],
  );

  useEffect(() => {
    onSearch();
  }, [onSearch]);

  const handleSelect = (value, label) => {
    onSelect(value);
    setInputValue(label);
  };

  return customComponent ? (
    <AutoCompleteStyled options={options} style={{ width }} onSelect={(value, label) => handleSelect(value, label.title)} onSearch={onSearch}>
      {customComponent}
    </AutoCompleteStyled>
  ) : patterns ? (
    <AutoCompleteStyled
      className="certain-category-search"
      popupClassName="certain-category-search-dropdown"
      dropdownMatchSelectWidth={false}
      dropdownStyle={{ width: 300 }}
      style={{ width }}
      options={options}
      placeholder={placeholder}
      onSearch={(text) => onSearch(text)}
      onSelect={(value, elem) => handleSelect(value, elem.label)}
      value={inputValue}
      onChange={setInputValue}
    >
      <Input
        suffix={
          patternButtons ? (
            <Button className="search-btn" style={{ marginRight: -20 }} type="primary">
              <SearchOutlined />
            </Button>
          ) : (
            <SearchOutlined />
          )
        }
      />
    </AutoCompleteStyled>
  ) : (
    <AutoCompleteStyled options={options} style={{ width }} onSearch={(text) => onSearch(text)} placeholder={placeholder} />
  );
};

AutoComplete.propTypes = {
  customComponent: PropTypes.node,
  patterns: PropTypes.bool,
  patternButtons: PropTypes.bool,
  width: PropTypes.string,
  searchUrl: PropTypes.string,
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
};

export { AutoComplete };
