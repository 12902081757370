import React, { useState } from 'react';
import { message, Modal } from 'antd';
import FeatherIcon from 'feather-icons-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/buttons/buttons';
import ApiClient from '@/helpers/apiClient/ApiClient';

const CreateOrViewReceiptAction = ({ id, resource, receipt, onSell }) => {
  const [receiptId, setReceiptId] = useState(receipt?.checkbox_receipt_id);
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      {receiptId && (
        <Modal
          open={showReceiptModal}
          onCancel={() => setShowReceiptModal(false)}
          onOk={() => setShowReceiptModal(false)}
          style={{ height: '100%' }}
          bodyStyle={{ height: '100%' }}
        >
          <iframe src={`https://check.checkbox.ua/${receiptId}`} title={receiptId} width="100%" height="100%" />
        </Modal>
      )}
      {receipt ? (
        <Button onClick={() => setShowReceiptModal(true)} title={t('invoices.review_check')}>
          <FeatherIcon icon="file-text" />
        </Button>
      ) : (
        <Button
          type="info"
          onClick={() =>
            ApiClient.call('post', `${resource}/${id}/manual-sell`)
              .data(({ receipt }) => {
                onSell(receipt);
                setReceiptId(receipt.checkbox_receipt_id);
                setShowReceiptModal(true);
              })
              .catch(({ data }) => {
                message.error(data.message);
              })
          }
        >
          {t('invoices.fiscalization')}
        </Button>
      )}
    </>
  );
};

CreateOrViewReceiptAction.propTypes = {
  id: PropTypes.number.isRequired,
  resource: PropTypes.string.isRequired,
  receipt: PropTypes.object,
  onSell: PropTypes.func.isRequired,
};

export default CreateOrViewReceiptAction;
