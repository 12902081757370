import { useMemo } from 'react';
import { Empty, Row } from 'antd';
import { Chart } from 'react-google-charts';
import PropTypes from 'prop-types';
import { generateSerias, parseKeyValueString } from '@/components/utilities/statistic/utilities';
import { ComboChartWrap } from '@/components/statistic/conversion/styled';

const options = {
  hAxis: {
    title: 'Дата',
    format: 'dd/MM/YYYY',
  },
  vAxis: { title: 'Кількість відвідувачів та чеків' },
  seriesType: 'bars',
  animation: {
    startup: true,
    easing: 'linear',
    duration: 400,
  },
  bar: { groupWidth: '90%' },
  chartArea: { left: 20, top: 0, bottom: 80, width: '80%', height: '100%' },
  legend: {
    position: 'right',
    textStyle: {
      fontSize: 12,
      fontWeight: 'bold',
    },
  },
  width: `100%`,
  height: '400px',
};

const calcMaxZoomIn = (length) => {
  return (length / 20) * 4;
};

function ComboChart({ length, data = [] }) {
  const seriasString = generateSerias(length);

  const seriasData = useMemo(() => parseKeyValueString(seriasString), [seriasString]);

  return (
    <ComboChartWrap>
      {data.length > 1 ? (
        <Chart
          chartType="ComboChart"
          data={data}
          chartLanguage="uk"
          options={{
            ...options,
            series: seriasData,
            explorer: {
              maxZoomIn: calcMaxZoomIn(data.length),
              keepInBounds: true,
              axis: 'horizontal',
              maxZoomOut: 1.5,
            },
          }}
        />
      ) : (
        <Row className="combo-chart_container">
          <Empty />
        </Row>
      )}
    </ComboChartWrap>
  );
}

ComboChart.propTypes = {
  length: PropTypes.number,
  data: PropTypes.array.isRequired,
};

export default ComboChart;
