import React from 'react';
import PropTypes from 'prop-types';
import { Content, DropdownStyle } from './dropdown-style';

const Dropdown = ({ content, placement = 'bottomRight', title, action = 'click', children, style = {}, className = 'strikingDash-dropdown' }) => {
  return (
    <DropdownStyle overlayClassName={className} style={style} placement={placement} title={title} overlay={<Content>{content}</Content>} trigger={action}>
      {children}
    </DropdownStyle>
  );
};

Dropdown.propTypes = {
  placement: PropTypes.string,
  title: PropTypes.string,
  action: PropTypes.array,
  content: PropTypes.node,
  children: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.string,
};

export { Dropdown };
