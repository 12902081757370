import moment from 'moment';
import i18n from '@/locales/i18n';

const getTodayRange = () => ({
  startDate: moment().startOf('day').toDate(),
  endDate: moment().endOf('day').toDate(),
});

const getYesterdayRange = () => ({
  startDate: moment().subtract(1, 'day').startOf('day').toDate(),
  endDate: moment().subtract(1, 'day').endOf('day').toDate(),
});

const getThisWeekRange = () => ({
  startDate: moment().startOf('week').toDate(),
  endDate: moment().endOf('week').toDate(),
});

const getLastWeekRange = () => ({
  startDate: moment().subtract(1, 'week').startOf('week').toDate(),
  endDate: moment().subtract(1, 'week').endOf('week').toDate(),
});

const getThisMonthRange = () => ({
  startDate: moment().startOf('month').toDate(),
  endDate: moment().endOf('month').toDate(),
});

const getLastMonthRange = () => ({
  startDate: moment().subtract(1, 'month').startOf('month').toDate(),
  endDate: moment().subtract(1, 'month').endOf('month').toDate(),
});

const isSelected = (range, definedRange) => {
  return moment(range.startDate).isSame(moment(definedRange.startDate), 'day') && moment(range.endDate).isSame(moment(definedRange.endDate), 'day');
};

export const customCalendarLabels = [
  {
    label: i18n.t('site_statistic.calendar_labels.today'),
    range: getTodayRange,
    isSelected: (range) => isSelected(range, getTodayRange()),
  },
  {
    label: i18n.t('site_statistic.calendar_labels.yesterday'),
    range: getYesterdayRange,
    isSelected: (range) => isSelected(range, getYesterdayRange()),
  },
  {
    label: i18n.t('site_statistic.calendar_labels.this_week'),
    range: getThisWeekRange,
    isSelected: (range) => isSelected(range, getThisWeekRange()),
  },
  {
    label: i18n.t('site_statistic.calendar_labels.last_week'),
    range: getLastWeekRange,
    isSelected: (range) => isSelected(range, getLastWeekRange()),
  },
  {
    label: i18n.t('site_statistic.calendar_labels.this_month'),
    range: getThisMonthRange,
    isSelected: (range) => isSelected(range, getThisMonthRange()),
  },
  {
    label: i18n.t('site_statistic.calendar_labels.last_month'),
    range: getLastMonthRange,
    isSelected: (range) => isSelected(range, getLastMonthRange()),
  },
];

const getToday = () => moment().startOf('day');
const addDays = (date, days) => moment(date).add(days, 'days');
const differenceInCalendarDays = (endDate, startDate) => moment(endDate).diff(moment(startDate), 'days') + 1;

export const customCalendarInputs = [
  {
    label: i18n.t('site_statistic.calendar_labels.days_to_day'),
    range(value) {
      const today = getToday();
      const startDate = addDays(today, -(Math.max(Number(value), 1) - 1)).toDate();
      return {
        startDate,
        endDate: today.endOf('day').toDate(),
      };
    },
    getCurrentValue(range) {
      const today = getToday().toDate();
      if (!moment(range.endDate).isSame(today, 'day')) return '-';
      if (!range.startDate) return '∞';
      return differenceInCalendarDays(today, range.startDate);
    },
  },
];
