import React from 'react';
import { Form, Input } from 'antd';
import PropTypes from 'prop-types';

const FormTextArea = ({ label, name, rows = 4, placeholder, required, disabled, error, showCount = false }) => {
  return (
    <Form.Item required={required} validateStatus={error ? 'error' : false} help={error} name={name} label={label}>
      <Input.TextArea rows={rows} disabled={disabled} initialvalues={placeholder} showCount={showCount} />
    </Form.Item>
  );
};

FormTextArea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  rows: PropTypes.number,
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  disabled: PropTypes.bool,
  required: PropTypes.any,
  showCount: PropTypes.bool,
};

export default FormTextArea;
