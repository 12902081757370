import { useMemo, useState } from 'react';
import moment from 'moment';
import { groupBy } from 'lodash/collection';
import { map, sumBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Checkbox, Col, Radio, Row } from 'antd';
import dayjs from 'dayjs';
import { RadioGroupStyle } from '@/components/statistic/conversion/styled';

export const PERIOD_DAY = 'day';
export const PERIOD_WEEK = 'week';
export const PERIOD_MONTH = 'month';
export const PERIOD_QUARTER = 'quarter';

const formatPeriod = (data, period = PERIOD_DAY) => {
  return data.map((item) => {
    const groups = groupBy(item.get('data'), (dt) => moment(dt.date).startOf(period));

    const data = map(groups, (group) => {
      return {
        date: group[0].date,
        visitors: sumBy(group, 'visitors'),
        receipts: sumBy(group, 'receipts'),
      };
    });

    return {
      ...item.toJS(),
      data,
    };
  });
};

export default function useStatisticChartsData(data) {
  const [period, setPeriod] = useState(PERIOD_DAY);
  const [storeIds, setStoreIds] = useState([]);

  const chartsData = useMemo(() => {
    if (data.every((item) => item.get('visitors_total') === 0 && item.get('receipts_total') === 0)) {
      return [];
    }

    let someData = data;

    if (storeIds.length) {
      someData = someData.filter((item) => storeIds.includes(item.get('id')));
    }

    someData = formatPeriod(someData, period);

    const dates = [...new Set(someData.flatMap((store) => store.data.map((item) => item.date)))];

    const newData = [['date', ...someData.flatMap((store) => [`${store.name} Відвідувачі`, `${store.name} Чеки`])]];

    dates.forEach((date) => {
      const rowData = [dayjs(date).toDate()];

      someData.forEach((store) => {
        const dataForDate = store.data.find((item) => item.date === date);

        rowData.push(dataForDate ? dataForDate.visitors : 0);
        rowData.push(dataForDate ? dataForDate.receipts : 0);
      });

      newData.push(rowData);
    });

    return newData;
  }, [data, period, storeIds]);

  const PeriodFilter = () => {
    const { t } = useTranslation();

    return (
      <RadioGroupStyle>
        <Radio.Group value={period} size="small" className="custom-radio-group" onChange={(e) => setPeriod(e.target.value)}>
          <Radio.Button value={PERIOD_DAY}>{t(`statistics.periods.${PERIOD_DAY}`)}</Radio.Button>
          <Radio.Button value={PERIOD_WEEK}>{t(`statistics.periods.${PERIOD_WEEK}`)}</Radio.Button>
          <Radio.Button value={PERIOD_MONTH}>{t(`statistics.periods.${PERIOD_MONTH}`)}</Radio.Button>
          <Radio.Button value={PERIOD_QUARTER}>{t(`statistics.periods.${PERIOD_QUARTER}`)}</Radio.Button>
        </Radio.Group>
      </RadioGroupStyle>
    );
  };

  const StoreFilter = () => {
    const toggleStore = (id) => {
      const data = new Set(storeIds);
      if (data.has(id)) {
        data.delete(id);
      } else {
        data.add(id);
      }
      setStoreIds([...data]);
    };

    return (
      <Row gutter={[16, 16]} type="flex">
        <Col span={24}>
          {data.map((store) => (
            <Checkbox checked={storeIds.includes(store.get('id'))} key={store.get('id')} onChange={() => toggleStore(store.get('id'))}>
              {store.get('name')}
            </Checkbox>
          ))}
        </Col>
      </Row>
    );
  };

  return {
    chartsData,
    PeriodFilter,
    StoreFilter,
  };
}
