import { Col, Row } from 'antd';
import { TypeAnimation } from 'react-type-animation';
import withAuthLayout from '../../layouts/withAuthLayout';
import { Main } from '../styled';
import { PageHeader } from '@/components/page-headers/page-headers';
import { useAuth } from '@/helpers/userContext';
import SmsBalance from '@/components/smsBalance/SmsBalance';

const Home = () => {
  const { user, checkPerm } = useAuth();

  return (
    <>
      <PageHeader title={<TypeAnimation cursor={false} sequence={[`Привіт, ${user.full_name}! ❤`]} wrapper="h2" />} />
      <Main>
        <Row gutter={25}>
          <Col xxl={6} xl={6} lg={6} md={6} sm={8} xs={24}>
            {checkPerm('administrating.third_parties.view_sms_balance') && <SmsBalance />}
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default withAuthLayout(Home);
