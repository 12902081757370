import FeatherIcon from 'feather-icons-react';
import React from 'react';
import hexRgb from 'hex-rgb';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { ZoneElemStyled } from '@/components/City/styled';
import { Button } from '@/components/buttons/buttons';
import { alertModal } from '@/components/modals/antd-modals';

const ZoneList = ({ zones, onSelect, onDelete, errors }) => {
  const { t } = useTranslation();

  const showConfirm = (zoneId) => {
    alertModal.confirm({
      title: t('modal.accept_delete_message'),
      onOk() {
        onDelete(zoneId);
      },
      onCancel() {},
    });
  };

  return (
    <div>
      {Boolean(zones.length) && (
        <ul>
          {zones.map((zone, i) => {
            const hasError =
              get(errors, `zones.${i}.delivery_price`) || get(errors, `zones.${i}.free_delivery_from_sum`) || get(errors, `zones.${i}.info_text`);

            return (
              <ZoneElemStyled key={zone.id} hasError={hasError}>
                <div className="color" style={{ backgroundColor: hexRgb(zone.color, { alpha: 0.8, format: 'css' }) }} />
                <span>
                  {`Зона ${i + 1}`} {zone.delivery_price && `(${zone.delivery_price / 100} грн)`}
                </span>
                <div>
                  <Button type="primary" onClick={() => onSelect(zone.id)} shape="circle">
                    <FeatherIcon icon="edit" size={16} />
                  </Button>
                  <Button type="danger" onClick={() => showConfirm(zone.id)} shape="circle">
                    <FeatherIcon icon="trash-2" size={16} />
                  </Button>
                  {hasError && <FeatherIcon icon="info" style={{ color: 'red' }} />}
                </div>
              </ZoneElemStyled>
            );
          })}
        </ul>
      )}
    </div>
  );
};

ZoneList.propTypes = {
  zones: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  errors: PropTypes.any,
};

export default ZoneList;
