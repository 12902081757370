import styled from 'styled-components';

const CategoryGridStyled = styled.div`
  margin: 40px auto;
  display: grid;
  gap: 20px 40px;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: dense;
  grid-auto-rows: 135px;

  .gridElem {
    @media (max-width: 720px) {
      height: 240px;

      &:first-child,
      &:last-child {
        height: 400px;
      }
    }
  }
  .gridElem:nth-child(10n + 1) {
    grid-column: span 1;
    grid-row: span 2;
  }

  .gridElem:nth-child(10n + 2) {
    grid-column: span 1;
    grid-row: span 1;
  }

  .gridElem:nth-child(10n + 3) {
    grid-column: 2 / span 1;
    grid-row: span 1;
  }

  .gridElem:nth-child(10n + 4) {
    grid-column: span 1;
    grid-row: span 1;
  }

  .gridElem:nth-child(10n + 5) {
    grid-column: span 1;
    grid-row: span 1;
  }

  .gridElem:nth-child(10n + 6) {
    grid-column: span 1;
    grid-row: span 1;
  }

  .gridElem:nth-child(10n + 7) {
    grid-column: 1 / span 1;
    grid-row: span 1;
  }

  .gridElem:nth-child(10n + 8) {
    grid-column: 2 / span 1;
    grid-row: span 2;
  }

  .gridElem:nth-child(10n + 9) {
    grid-column: span 1;
    grid-row: span 1;
  }

  .gridElem:nth-child(10n + 10) {
    grid-column: 2 / span 1;
    grid-row: span 1;
  }

  .gridElemFw {
    grid-column: span 2 !important;
    grid-row: span 1 !important;
  }

  .gridElemMh {
    grid-column: span 1 !important;
    grid-row: span 1 !important;
  }
`;

const CategoryStyled = styled.div`
  border-radius: 20px;
  background: #bcdcd2;
  display: flex;
  min-height: 100px;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  transition: 0.5s;
  height: ${({ isMobile }) => (isMobile ? '120px' : '100%')};
  margin-bottom: 16px;

  &:last-child,
  &:first-child {
    height: ${({ isMobile }) => (isMobile ? '200px' : '100%')};
  }

  &:hover {
    background: linear-gradient(180deg, rgba(70, 134, 115, 0) 0%, #468673 100%);
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    &:hover {
      opacity: 0.8;
    }
  }

  span {
    text-transform: capitalize;
    position: absolute;
    bottom: 20px;
    left: 20px;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
  }

  a {
    color: grey;
    position: absolute;
    top: 16px;
    right: 20px;
  }
`;

const CardHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    margin-bottom: 0;
  }

  button {
    span {
      color: #fff;
      margin-left: 0;
      font-size: 12px;
    }
  }
`;

const DraggableStyled = styled.span`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export { CategoryGridStyled, CategoryStyled, CardHeaderStyled, DraggableStyled };
