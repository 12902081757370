import React, { useCallback, useEffect, useState } from 'react';
import { Drawer } from 'antd';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import FeatherIcon from 'feather-icons-react';
import classNames from 'classnames';
import { List } from 'immutable';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button } from '../../components/buttons/buttons';

const DragHandle = sortableHandle(() => <FeatherIcon style={{ cursor: 'pointer', color: '#999' }} icon="move" />);

const DraggableColumns = ({ columns, userColumns, onClose, onSave }) => {
  const { t } = useTranslation();
  const [items, setItems] = useState(List(columns));

  useEffect(() => {
    const data = columns
      .map((column) => {
        return {
          ...column,
          hidden: userColumns.length ? !userColumns.includes(column.key) : false,
        };
      })
      .sort((a, b) => userColumns.indexOf(a.key) - userColumns.indexOf(b.key))
      .sort((a, b) => a.hidden - b.hidden);

    setItems(List(data));
  }, [columns, userColumns]);

  const saveColumns = () => {
    const data = items.filter((e) => !e.hidden).map((e) => e.key);
    onSave(data.toArray());
  };

  const SortableItem = sortableElement(({ title, hidden, toggle }) => (
    <div className={classNames('dragging-elem', hidden && 'hidden')}>
      <div>{!hidden && <DragHandle />}</div>
      <div>{title}</div>
      <div>
        <FeatherIcon onClick={toggle} style={{ cursor: 'pointer', color: '#999' }} icon={hidden ? 'plus-circle' : 'minus-circle'} />
      </div>
    </div>
  ));

  const SortableContainer = sortableContainer((props) => <div {...props} />);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const oldItem = items.get(oldIndex);
      const firstHidden = items.findIndex((e) => e.hidden === true);

      if (firstHidden !== -1 && newIndex >= firstHidden) {
        setItems(items.delete(oldIndex).insert(firstHidden - 1, oldItem));
      } else {
        setItems(items.delete(oldIndex).insert(newIndex, oldItem));
      }
    }
  };

  const toggle = useCallback(
    (index) => {
      setItems((data) => {
        const hidden = data.getIn([index, 'hidden']);
        const updatedList = data.setIn([index, 'hidden'], !hidden);
        const lastNotHiddenIndex = data.findLastIndex((e) => e.hidden === false);

        return updatedList.delete(index).insert(hidden ? lastNotHiddenIndex + 1 : lastNotHiddenIndex, updatedList.get(index));
      });
    },
    [setItems],
  );

  const reset = () => {
    const data = columns.map((column) => {
      return {
        ...column,
        hidden: false,
      };
    });

    setItems(List(data));
  };

  return (
    <Drawer
      title="Налаштування колонок"
      open
      onCancel={onClose}
      onClose={onClose}
      footer={
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 25 }}>
          <Button type="info" size="large" onClick={reset}>
            {t('reset')}
          </Button>
          <Button type="success" size="large" onClick={saveColumns}>
            {t('save')}
          </Button>
        </div>
      }
    >
      <SortableContainer useDragHandle helperClass="row-dragging" onSortEnd={onSortEnd}>
        {items.map((item, index) => {
          return <SortableItem index={index} key={item.key} hidden={item.hidden} title={item.title} toggle={() => toggle(index)} />;
        })}
      </SortableContainer>
    </Drawer>
  );
};

DraggableColumns.propTypes = {
  columns: PropTypes.array.isRequired,
  userColumns: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default React.memo(DraggableColumns);
