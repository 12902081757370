import { Chart } from 'react-google-charts';
import { Row } from 'antd';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { PieChartWrap } from '@/components/statistic/conversion/styled';
import NoDataWrapper from '@/components/statistic/conversion/NoDataWrapper';

const pieOnlineChartOptions = {
  title: 'Виторг online',
  titleTextStyle: { fontSize: 12 },
  is3D: false,
  pieSliceText: 'value',
  pieSliceTextStyle: {
    fontSize: 14,
  },
  chartArea: {
    left: '2%',
    top: '6%',
    height: '90%',
    width: '100%',
  },
  legend: {
    position: 'right',
    maxLines: 5,
    alignment: 'start',
  },
};
const pieOfflineChartOptions = {
  title: 'Виторг offline',
  titleTextStyle: { fontSize: 12 },
  is3D: false,
  pieSliceText: 'value',
  pieSliceTextStyle: {
    fontSize: 14,
  },
  chartArea: {
    top: '6%',
    left: '2%',
    height: '90%',
    width: '100%',
  },
  legend: {
    position: 'rigth',
    maxLines: 5,
    alignment: 'start',
  },
};
const pieTotalChartOptions = {
  title: 'Загальний виторг',
  titleTextStyle: { fontSize: 12 },
  pieSliceTextStyle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  is3D: false,
  pieSliceText: 'value',
  legend: {
    position: 'rigth',
    maxLines: 5,
    alignment: 'start',
  },
  chartArea: {
    left: '2%',
    top: '6%',
    height: '90%',
    width: '100%',
  },
};

function PieChart({ onlineData, offlineData, totalData, summary }) {
  const { t } = useTranslation();
  return (
    <PieChartWrap>
      <Row className="pie-chart_container">
        <NoDataWrapper noData={onlineData?.length < 2} title={t('statistics.revenue_online')}>
          <Row className="pie-chart_elements_container">
            <Chart chartLanguage="uk" chartType="PieChart" width="auto" height="300px" data={onlineData} options={pieOnlineChartOptions} />
          </Row>
        </NoDataWrapper>

        <NoDataWrapper noData={offlineData?.length < 2} title={t('statistics.revenue_offline')}>
          <Row className="pie-chart_elements_container">
            <Chart chartLanguage="uk" chartType="PieChart" width="auto" height="300px" data={offlineData} options={pieOfflineChartOptions} />
          </Row>
        </NoDataWrapper>

        <NoDataWrapper noData={totalData?.length < 2 || !get(totalData, [1, 1])} title={t('statistics.revenue_total')}>
          <Row className="pie-chart_elements_container">
            <Chart chartLanguage="uk" chartType="PieChart" width="auto" height="300px" data={totalData} options={pieTotalChartOptions} />
            {Boolean(summary) && (
              <p style={{ margin: '0 0 0 35px' }}>
                {t('statistics.revenue_all')}:<b> {Intl.NumberFormat('uk-UA').format(summary)}</b> {t('uah_short')}.
              </p>
            )}
          </Row>
        </NoDataWrapper>
      </Row>
    </PieChartWrap>
  );
}

PieChart.propTypes = {
  onlineData: PropTypes.array.isRequired,
  offlineData: PropTypes.array.isRequired,
  totalData: PropTypes.array.isRequired,
  summary: PropTypes.number,
};

export default PieChart;
