import styled from 'styled-components';

const ImageCard = styled.div`
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 5px 20px ${({ theme }) => theme['light-color']}03;
  max-height: 400px;

  figure {
    width: auto;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: fill;
    }
  }

  .type {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }
`;

export { ImageCard };
