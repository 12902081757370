import React, { useCallback, useState } from 'react';
import { Input, message } from 'antd';
import FontAwesome from 'react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ApiClient from '@/helpers/apiClient/ApiClient';
import { Icon, IconWrapper } from '@/container/icons/IconStyled';
import { Modal } from '@/components/modals/antd-modals';
import { Button } from '@/components/buttons/buttons';

const ApproveOrCancelOrderCell = ({ id }) => {
  const [approved, setApproved] = useState(false);
  const [canceled, setCanceled] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [cancelReason, setCancelReason] = useState('');

  const { t } = useTranslation();

  const approveOrder = useCallback(() => {
    ApiClient.call('post', `orders/${id}/approve`)
      .then(() => {
        message.success(t('proved'));
        setApproved(true);
      })
      .catch(({ data }) => {
        message.error(data?.message);
      });
  }, [id]);

  const cancelOrder = useCallback(() => {
    ApiClient.call('post', `orders/${id}/cancel`, {}, { cancel_reason: cancelReason })
      .then(() => {
        message.success(t('undone'));
        setCanceled(true);
        setShowCancelModal(false);
      })
      .catch(({ data }) => {
        message.error(data?.message);
      });
  }, [id, cancelReason]);

  return (
    <>
      {approved && (
        <IconWrapper>
          <Icon className="icon-single" style={{ justifyContent: 'center' }}>
            <FontAwesome name="check-circle" className="icon-success" style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)', color: 'green' }} />
          </Icon>
        </IconWrapper>
      )}
      {canceled && (
        <IconWrapper>
          <Icon className="icon-single" style={{ justifyContent: 'center' }}>
            <FontAwesome name="close" className="icon-success" style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)', color: 'red' }} />
          </Icon>
        </IconWrapper>
      )}
      {showCancelModal && (
        <Modal visible title={t('orders.cancel_order')} onCancel={() => setShowCancelModal(false)} onOk={cancelOrder}>
          <Input id="cancel_reason" value={cancelReason} onChange={(e) => setCancelReason(e.target.value)} placeholder={t('orders.cause')} />
        </Modal>
      )}
      {!canceled && !approved && (
        <div className="d-flex justify-content-between" style={{ gap: 20 }}>
          <Button type="success" onClick={approveOrder}>
            {t('confirm')}
          </Button>
          <Button type="danger" onClick={() => setShowCancelModal(true)}>
            {t('undo')}
          </Button>
        </div>
      )}
    </>
  );
};

ApproveOrCancelOrderCell.propTypes = {
  id: PropTypes.number.isRequired,
};

export default ApproveOrCancelOrderCell;
