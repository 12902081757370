import { Table } from 'antd';
import React, { useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import withAuthLayout from '@/layouts/withAuthLayout';
import { CardToolbox, Main } from '@/container/styled';
import { PageHeader } from '@/components/page-headers/page-headers';
import usePaginator from '@/hooks/usePaginator';
import { checkboxOperations } from '@/constants/checkboxErrorLogs';

const PaymentCheckboxErrorsIndex = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data, fetch } = usePaginator(`payments/${id}/checkbox-errors`);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const columns = React.useMemo(() => [
    {
      title: t('fops.checkbox_errors.columns.id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('fops.checkbox_errors.columns.message'),
      dataIndex: 'message',
      key: 'message',
    },
    {
      title: t('fops.checkbox_errors.columns.operation'),
      dataIndex: 'operation',
      key: 'operation',
      render: (value) => <span>{checkboxOperations[value]}</span>,
    },
    {
      title: t('fops.checkbox_errors.columns.amount'),
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: t('fops.checkbox_errors.columns.updated_at'),
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (value) => <span>{moment(value).format('DD.MM.Y HH:mm:ss')}</span>,
    },
  ]);

  return (
    <>
      <CardToolbox>
        <PageHeader ghost title={t('payments.checkbox_errors_page_title')} />
      </CardToolbox>

      <Main>
        <Table
          columns={columns}
          dataSource={data}
          rowKey="id"
          permissionNamespace="accounting.payments"
          actionOptions={{ isEditable: false, isDeletable: false }}
        />
      </Main>
    </>
  );
};

export default withAuthLayout(PaymentCheckboxErrorsIndex);
