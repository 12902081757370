import { useEffect, useState } from 'react';

export default function useUploadImage(file, defaultSrc) {
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    setPreview(defaultSrc);
  }, [defaultSrc]);

  useEffect(() => {
    if (!file) {
      setPreview(defaultSrc);
      return;
    }

    if (file?.status === 'removed') {
      setPreview(defaultSrc);
      return;
    }

    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  return preview;
}
