export const ORDER_STATUS_NEED_CONFIRMATION = 1;
export const ORDER_STATUS_PROCESSING = 2;
export const ORDER_STATUS_DONE = 3;
export const ORDER_STATUS_CANCELLED = 4;

export const ORDER_STAGE_IN_QUE_FOR_PACKING = 'IN_QUE_FOR_PACKING';
export const ORDER_STAGE_BOUQUET_READY = 'BOUQUET_READY';
export const ORDER_STAGE_DELIVERING = 'DELIVERING';
export const ORDER_STAGE_COMPLETED = 'COMPLETED';

export const ORDER_PAYMENT_STATUS_PENDING = 1;
export const ORDER_PAYMENT_STATUS_PAID = 2;
export const ORDER_PAYMENT_STATUS_FAILED = 3;
export const ORDER_PAYMENT_STATUS_REVERSED = 5;

export const ORDER_PAYMENT_TYPE_CASH = 1;
export const ORDER_PAYMENT_TYPE_ACQUIRING = 3;

export const ORDER_DELIVERY_TYPE_COURIER = 1;
export const ORDER_DELIVERY_TYPE_SELF = 2;
