import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { AuthWrapper } from './style';
import Heading from '../../../../components/heading/heading';
import GuestLayout from '../Index';
import ApiClient from '../../../../helpers/apiClient/ApiClient';
import { useAuth } from '../../../../helpers/userContext';
import FormInput from '../../../../form/FormInput';

function SignIn() {
  const navigate = useNavigate();
  const { authorize } = useAuth();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();
  const {
    state: { from },
  } = useLocation();

  const handleSubmit = (values) => {
    ApiClient.call('POST', 'auth/login', {}, { ...values })
      .data(({ token }) => {
        authorize(token).then(() => navigate({ pathname: from.pathname, search: decodeURIComponent(from.search) }));
      })
      .unauthorized((message) => {
        setErrors({ password: message });
      })
      .validation((message, err) => {
        setErrors(err);
      });
  };

  return (
    <AuthWrapper>
      <div className="auth-contents">
        <Form name="login" form={form} onFinish={handleSubmit} layout="vertical">
          <Heading as="h3">
            <span className="color-secondary">{t('login_welcome')}</span>
          </Heading>
          <FormInput label="Email" name="email" error={errors.email} placeholder="Email" />
          <FormInput label={t('password')} name="password" type="password" error={errors.password} placeholder={t('password')} />
          <Form.Item>
            <Button className="btn-signin" htmlType="submit" type="primary" size="large">
              {t('sign_in')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </AuthWrapper>
  );
}

export default GuestLayout(SignIn);
