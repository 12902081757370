import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import PropTypes from 'prop-types';

const ColorPicker = ({ value, onChange, colorSet }) => {
  const [active, setActive] = useState(false);

  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <button
        type="button"
        style={{
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
          outline: 'none',
          border: 'none',
          width: '100%',
        }}
        onClick={() => setActive((active) => !active)}
      >
        <span
          style={{
            display: 'block',
            width: '100%',
            height: '14px',
            borderRadius: '2px',
            background: value,
          }}
        />
      </button>
      {active ? (
        <div
          style={{
            position: 'absolute',
            zIndex: '2',
          }}
        >
          {/* eslint-disable-next-line */}
          <span
            style={{
              position: 'fixed',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px',
            }}
            onClick={() => setActive(false)}
          />
          <SketchPicker color={value} onChange={onChange} presetColors={colorSet} />
        </div>
      ) : null}
    </div>
  );
};

ColorPicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  colorSet: PropTypes.arrayOf(PropTypes.string),
};

export default ColorPicker;
