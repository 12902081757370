import React, { useEffect, useState } from 'react';
import { Card, Col, Row, List as SortList, Button, message } from 'antd';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import FeatherIcon from 'feather-icons-react';
import { arrayMoveImmutable } from 'array-move';
import { useTranslation } from 'react-i18next';
import { CardHeaderStyled, DraggableStyled } from './style';
import CategoryGridWeb from './CategoryGridWeb';
import CategoryGridMobile from './CategoryGridMobile';
import ApiClient from '../../helpers/apiClient/ApiClient';
import Alert from '../alerts/alerts';

const DragHandle = SortableHandle(() => <FeatherIcon size={14} style={{ cursor: 'pointer', color: '#999' }} icon="move" />);

const SortableItem = SortableElement(({ value }) => (
  <SortList.Item.Meta
    style={{ cursor: 'grab' }}
    description={
      <DraggableStyled>
        <DragHandle /> <span>{value.name}</span>
      </DraggableStyled>
    }
  />
));

const SortableList = SortableContainer(({ items }) => {
  return <SortList bordered dataSource={items} renderItem={(item) => <SortableItem value={item} index={item.order} />} />;
});

const CategoryPreview = () => {
  const [sortedCategories, setSortedCategories] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    ApiClient.call('get', 'categories/active-list').data(({ data }) => {
      setSortedCategories(data);
    });
  }, []);

  const onSave = () => {
    ApiClient.call('post', 'categories/update-order', {}, { items: sortedCategories }).then(() => {
      message.success(t('categories.message_success'));
    });
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setSortedCategories((items) => {
      const data = arrayMoveImmutable(items, oldIndex, newIndex);
      return data.map((e, i) => {
        return { ...e, order: i };
      });
    });
  };

  return (
    <Row gutter={24}>
      <Col xl={6} style={{ display: 'flex', alignSelf: 'stretch' }}>
        <Card
          title={
            <CardHeaderStyled>
              <h3>{t('categories.card_sort_title')}</h3>
            </CardHeaderStyled>
          }
          style={{ width: '100%' }}
        >
          <div style={{ marginBottom: '20px' }}>
            <Alert showIcon icon={<FeatherIcon icon="layers" size={15} />} message="" description={t('categories.card_alert_text')} type="info" />
          </div>
          <Button style={{ width: '100%', margin: '0 0 20px' }} type="primary" onClick={onSave}>
            {t('categories.card_button_text')}
          </Button>
          <SortableList items={sortedCategories} onSortEnd={onSortEnd} />
          <p style={{ marginTop: '20px' }}>
            <b>{t('categories.card_hint_text')}</b>
          </p>
        </Card>
      </Col>
      <Col xl={12} md={24} style={{ display: 'flex', alignSelf: 'stretch' }}>
        <Card title={t('categories.card_web_title')} style={{ width: '100%' }}>
          <CategoryGridWeb items={sortedCategories} />
        </Card>
      </Col>
      <Col xl={6} md={24} style={{ display: 'flex', alignSelf: 'stretch' }}>
        <Card title={t('categories.card_mobile_title')} style={{ width: '100%' }}>
          <CategoryGridMobile items={sortedCategories} />
        </Card>
      </Col>
    </Row>
  );
};

export default CategoryPreview;
