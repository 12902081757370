/* eslint-disable */
import React, {useState} from 'react';
import {Button, Input, Upload} from 'antd';
import FeatherIcon from 'feather-icons-react';
import { ProductImageUploadStyled } from './style';
import Heading from '../heading/heading';
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import classNames from "classnames";

const { Dragger } = Upload;

const imageUploadProps = {
  multiple: true,
  beforeUpload: () => {
    return false;
  },
  showUploadList: false,
  accept: 'image/png, image/jpeg, image/jpg',
};

const ProductImageUpload = ({ value = [], onChange }) => {
  const {t} = useTranslation();
  const [primaryId, setPrimaryId] = useState(value.find((e) => e.is_primary)?.id);

  const removeImage = (index) => {
    const data = value.filter((e, i) => i !== index);
    if (data.length === 1) {
      onChange([{...data[0], is_primary: true}])
    } else {
      onChange(data);
    }
  };

  const makePrimary = (index) => {
    const data = value.map((e, i) => {
      return { ...e, is_primary: i === index };
    });

    onChange(data);
  };

  return (
    <ProductImageUploadStyled>
      <Dragger
        {...imageUploadProps}
        onChange={(e) => {
          const files = e.fileList.map((file, i) => {
            if (file.id) {
              return {...file, is_primary: file.id === primaryId};
            }
            return {file: file.originFileObj || file, is_primary: primaryId ? primaryId === file.uid : (value.length === 0 && i === 0)}
          });
          const primaryFile = files.find(e => e.is_primary);
          setPrimaryId(primaryFile?.id || primaryFile?.file.uid);
          onChange([...files]);
        }}
        fileList={value.map((e) => e.file || e)}
      >
        <p className="ant-upload-drag-icon">
          <FeatherIcon icon="upload" size={50} />
        </p>
        <Heading as="h4" className="ant-upload-text">{t("products.image_upload.drag_image")}</Heading>
        <p className="ant-upload-hint">{t("products.image_upload.choose_file")}</p>
      </Dragger>
      {value && (
        <div className="list-container">
          {value
            .sort((e) => !e.is_primary)
            .map((image, i) => {
              const {file, sizes, is_primary} = image;
              const isFile = file instanceof File;
              const src = isFile ? URL.createObjectURL(file) : sizes.medium;

              return (
                <div key={i} className="list-item">
                  <div className="list-item-info">
                      <a className="list-item-thumbnail">
                        <img src={src} alt="xxx.png" className={classNames(is_primary && "primary-image")}/>
                      </a>
                      <span className="list-item-actions">
                          <Button
                            htmlType="button"
                            type={is_primary ? "dashed" : "primary"}
                            disabled={is_primary}
                            onClick={() => makePrimary(i)}
                          >{is_primary ? t("products.image_upload.primary") : t("products.image_upload.make_primary")}</Button>
                        <Button
                          htmlType="button"
                          danger
                          onClick={() => removeImage(i)}
                        >{t("products.image_upload.remove")}</Button>
                      </span>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </ProductImageUploadStyled>
  );
};

ProductImageUpload.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func
}

export default ProductImageUpload;
