import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import dayjs from 'dayjs';
import { DatePicker as Picker } from 'antd';
import PropTypes from 'prop-types';
import { DatePickerWrapper } from '@/container/styled';

const FORMATS = {
  date: 'DD.MM.YYYY',
  time: 'HH:mm',
};

const DatePicker = ({ type = 'date', value, onChange, error, disabledDate }) => {
  const [date, setDate] = useState(value ? moment(value) : null);

  const handelChange = (newDate) => {
    setDate(moment(newDate.toDate()));
  };

  useEffect(() => {
    if (date) {
      onChange(date.format(FORMATS[type]));
    }
  }, [date]);

  return (
    <DatePickerWrapper>
      <Picker
        picker={type}
        format={FORMATS[type]}
        disabledDate={(current) => current && disabledDate(current)}
        value={date}
        status={error && 'error'}
        showTime={
          type === 'time' && {
            defaultValue: dayjs().add(1, 'hour'),
            minuteStep: 5,
          }
        }
        onChange={handelChange}
      />
    </DatePickerWrapper>
  );
};

DatePicker.propTypes = {
  type: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  error: PropTypes.any,
  disabledDate: PropTypes.func,
};
export default DatePicker;
