import PropTypes from 'prop-types';
import { List } from 'immutable';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import useImmutableData from '@/hooks/useImmutableData';
import { Button } from '@/components/buttons/buttons';
import DeliveryPointBlock from '@/components/City/DeliveryPointBlock';

const zone = {
  ext_district_id: '',
  district_name: '',
  ext_city_id: '',
  city_name: '',
  delivery_zone_coords: null,
  delivery_price: 0,
};

const DeliveryPoints = ({ value = [], onChange, errors, regionId }) => {
  const [data, setData] = useImmutableData(List(value.sort((a, b) => a.delivery_price - b.delivery_price)), onChange);
  const { t } = useTranslation();

  const addItem = () => {
    setData((data) => {
      return data.push(zone);
    });
  };

  const handleChange = (index) => {
    return (key, value) => {
      setData((data) => {
        return data.setIn([index, key], value);
      });
    };
  };

  const onDelete = (index) => {
    setData((data) => {
      return data.delete(index);
    });
  };

  return (
    <div>
      {Boolean(data.size) &&
        data.map((zone, index) => {
          return (
            <Fragment key={index}>
              <DeliveryPointBlock
                index={index}
                zone={zone}
                onChange={handleChange(index)}
                regionId={regionId}
                onDelete={() => onDelete(index)}
                errors={errors}
              />
            </Fragment>
          );
        })}
      <Button type="dashed" style={{ width: '100%' }} onClick={addItem}>
        {t('add')}
      </Button>
    </div>
  );
};

DeliveryPoints.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  regionId: PropTypes.string.isRequired,
  errors: PropTypes.object,
};

export default DeliveryPoints;
