import moment from 'moment';
import { useState } from 'react';

export default function () {
  const [range, setRange] = useState([moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]);

  const handleRangeChange = (value) => {
    if (value) {
      setRange(value.map((date) => date.format('YYYY-MM-DD')));
    }
  };

  const clearRange = () => {
    setRange([]);
  };

  return {
    range,
    handleRangeChange,
    clearRange,
  };
}
