import React from 'react';
import PropTypes from 'prop-types';
import './style.css';
import { Content, PopoverStyle, Title } from './style';

const Popover = ({ content, placement = 'bottomCenter', title, action = 'hover', children }) => {
  const content1 = <Content>{content}</Content>;

  return (
    <PopoverStyle placement={placement} title={title && <Title>{title}</Title>} content={content1} trigger={action}>
      {children}
    </PopoverStyle>
  );
};

Popover.propTypes = {
  placement: PropTypes.string,
  title: PropTypes.string,
  action: PropTypes.string,
  content: PropTypes.node,
  children: PropTypes.node.isRequired,
};

export { Popover };
