import React from 'react';
import { Route } from 'react-router-dom';
import SiteStatistic from '@/container/pages/statistics/siteStatistic';
import AccountPage from '@/container/pages/account';
import { withAuth } from '@/hoc/withAuth';
import Home from '@/container/pages/Home';
import SignIn from '@/container/profile/authentication/overview/SignIn';
import UserIndex from '@/container/pages/administrating/users/UserIndex';
import UserForm from '@/container/pages/administrating/users/UserForm';
import RoleIndex from '@/container/pages/administrating/roles/RoleIndex';
import RoleForm from '@/container/pages/administrating/roles/RoleForm';
import FopsIndex from '@/container/pages/accounting/fops/FopsIndex';
import FopForm from '@/container/pages/accounting/fops/FopForm';
import InvoiceIndex from '@/container/pages/accounting/invoices/InvoiceIndex';
import InvoiceCheckboxErrorsIndex from '@/container/pages/accounting/invoices/InvoiceCheckboxErrorsIndex';
import PaymentIndex from '@/container/pages/accounting/payments/PaymentIndex';
import PaymentCheckboxErrorsIndex from '@/container/pages/accounting/payments/PaymentCheckboxErrorsIndex';
import CategoryIndex from '@/container/pages/cms/categories/CategoryIndex';
import CategoryForm from '@/container/pages/cms/categories/CategoryForm';
import ProductIndex from '@/container/pages/cms/products/ProductIndex';
import ProductForm from '@/container/pages/cms/products/ProductForm';
import CityIndex from '@/container/pages/cms/cities/CityIndex';
import CityForm from '@/container/pages/cms/cities/CityForm';
import StoreIndex from '@/container/pages/cms/stores/StoreIndex';
import StoreForm from '@/container/pages/cms/stores/StoreForm';
import SliderElementIndex from '@/container/pages/cms/sliderElements/SliderElementIndex';
import SliderElementForm from '@/container/pages/cms/sliderElements/SliderElementForm';
import DefaultPackingIndex from '@/container/pages/cms/defaultPacking/DefaultPackingIndex';
import DefaultPackingForm from '@/container/pages/cms/defaultPacking/DefaultPackingForm';
import OrderIndex from '@/container/pages/crm/orders/OrderIndex';
import SiteApplicationIndex from '@/container/pages/crm/site-applications/SiteApplicationIndex';
import BinotelAutoReplyIndex from '@/container/pages/crm/binotel-auto-replies/BinotelAutoReplyIndex';
import BinotelAutoReplyForm from '@/container/pages/crm/binotel-auto-replies/BinotelAutoReplyForm';
import PromoCodeIndex from '@/container/pages/crm/promo-codes/PromoCodeIndex';
import PromoCodeForm from '@/container/pages/crm/promo-codes/PromoCodeForm';
import MainPageCarouselIndex from '@/container/pages/cms/mainPageCarousel/MainPageCarouselIndex';
import MainPageCarouselForm from '@/container/pages/cms/mainPageCarousel/MainPageCarouselForm';
import StatisticsPage from '@/container/pages/statistics';
import Conversion from '@/container/pages/statistics/conversion';
import FaqsElementIndex from '@/container/pages/cms/faqsElements/FaqsElementIndex';
import FaqsElementForm from '@/container/pages/cms/faqsElements/FaqsElementForm';
import HeadCategoriesElementIndex from '@/container/pages/cms/headCategories/HeadCategoriesElementIndex';
import HeadCategoriesForm from '@/container/pages/cms/headCategories/HeadCategoriesForm';
import RecommendedProductsElementIndex from '@/container/pages/cms/recommendedProducts/RecommendedProductsElementIndex';
import RecommendedProductsForm from '@/container/pages/cms/recommendedProducts/RecommendedProductsForm';

const routes = [
  {
    path: '/',
    element: Home,
  },
  {
    path: '/login',
    noAuth: true,
    element: SignIn,
  },
  {
    path: 'account/*',
    element: AccountPage,
  },
  {
    path: 'administrating',
    children: [
      {
        path: 'users',
        children: [
          {
            path: '',
            permission: 'administrating.users.view',
            element: UserIndex,
          },
          {
            path: 'create',
            permission: 'administrating.users.edit',
            element: UserForm,
          },
          {
            path: ':id',
            permission: 'administrating.users.edit',
            element: UserForm,
          },
        ],
      },
      {
        path: 'roles',
        children: [
          {
            path: '',
            permission: 'administrating.roles.view',
            element: RoleIndex,
          },
          {
            path: 'create',
            permission: 'administrating.roles.edit',
            element: RoleForm,
          },
          {
            path: ':id',
            permission: 'administrating.roles.edit',
            element: RoleForm,
          },
        ],
      },
    ],
  },
  {
    path: 'accounting',
    children: [
      {
        path: 'fops',
        children: [
          {
            path: '',
            permission: 'accounting.fops.view',
            element: FopsIndex,
          },
          {
            path: 'create',
            permission: 'accounting.fops.edit',
            element: FopForm,
          },
          {
            path: ':id',
            permission: 'accounting.fops.edit',
            element: FopForm,
          },
        ],
      },
      {
        path: 'invoices',
        children: [
          {
            path: '',
            permission: 'accounting.invoices.view',
            element: InvoiceIndex,
          },
          {
            path: ':id/checkbox-errors',
            permission: 'accounting.invoices.view',
            element: InvoiceCheckboxErrorsIndex,
          },
        ],
      },
      {
        path: 'payments',
        children: [
          {
            path: '',
            permission: 'accounting.payments.view',
            element: PaymentIndex,
          },
          {
            path: ':id/checkbox-errors',
            permission: 'accounting.payments.view',
            element: PaymentCheckboxErrorsIndex,
          },
        ],
      },
    ],
  },
  {
    path: 'cms',
    children: [
      {
        path: 'categories',
        children: [
          {
            path: '',
            permission: 'cms.categories.view',
            element: CategoryIndex,
          },
          {
            path: 'create',
            permission: 'cms.categories.edit',
            element: CategoryForm,
          },
          {
            path: ':id',
            permission: 'cms.categories.edit',
            element: CategoryForm,
          },
        ],
      },
      {
        path: 'head-categories',
        children: [
          {
            path: '',
            permission: 'cms.header_categories.view',
            element: HeadCategoriesElementIndex,
          },
          {
            path: 'create',
            permission: 'cms.header_categories.edit',
            element: HeadCategoriesForm,
          },
          {
            path: ':id',
            permission: 'cms.header_categories.edit',
            element: HeadCategoriesForm,
          },
        ],
      },
      {
        path: 'recommended-lists',
        children: [
          { path: '', permission: 'cms.recommended_lists.view', element: RecommendedProductsElementIndex },
          { path: 'create', permission: 'cms.recommended_lists.edit', element: RecommendedProductsForm },
          { path: ':id', permission: 'cms.recommended_lists.edit', element: RecommendedProductsForm },
        ],
      },
      {
        path: 'products',
        children: [
          {
            path: '',
            permission: 'cms.categories.view',
            element: ProductIndex,
          },
          {
            path: 'create',
            permission: 'cms.products.edit',
            element: ProductForm,
          },
          {
            path: ':id',
            permission: 'cms.products.edit',
            element: ProductForm,
          },
        ],
      },
      {
        path: 'cities',
        children: [
          {
            path: '',
            permission: 'cms.cities.view',
            element: CityIndex,
          },
          {
            path: 'create',
            permission: 'cms.cities.edit',
            element: CityForm,
          },
          {
            path: ':id',
            permission: 'cms.cities.edit',
            element: CityForm,
          },
        ],
      },
      {
        path: 'stores',
        children: [
          {
            path: '',
            permission: 'cms.stores.view',
            element: StoreIndex,
          },
          {
            path: 'create',
            permission: 'cms.stores.edit',
            element: StoreForm,
          },
          {
            path: ':id',
            permission: 'cms.stores.edit',
            element: StoreForm,
          },
        ],
      },
      {
        path: 'main-page',
        children: [
          {
            path: 'slider-elements',
            children: [
              {
                path: '',
                permission: 'cms.main_page.slider_elements.view',
                element: SliderElementIndex,
              },
              {
                path: 'create',
                permission: 'cms.main_page.slider_elements.edit',
                element: SliderElementForm,
              },
              {
                path: ':id',
                permission: 'cms.main_page.slider_elements.edit',
                element: SliderElementForm,
              },
            ],
          },
          {
            path: 'carousel',
            children: [
              {
                path: '',
                permission: 'cms.main_page.carousel.view',
                element: MainPageCarouselIndex,
              },
              {
                path: 'create',
                permission: 'cms.main_page.carousel.edit',
                element: MainPageCarouselForm,
              },
              {
                path: ':id',
                permission: 'cms.main_page.carousel.edit',
                element: MainPageCarouselForm,
              },
            ],
          },
          {
            path: 'faqs',
            children: [
              { path: '', permission: 'cms.additional_faqs.view', element: FaqsElementIndex },
              { path: 'create', permission: 'cms.additional_faqs.edit', element: FaqsElementForm },
              { path: ':id', permission: 'cms.additional_faqs.edit', element: FaqsElementForm },
            ],
          },
        ],
      },
      {
        path: 'default-packing',
        children: [
          {
            path: '',
            permission: 'cms.default_packing.view',
            element: DefaultPackingIndex,
          },
          {
            path: 'create',
            permission: 'cms.default_packing.edit',
            element: DefaultPackingForm,
          },
          {
            path: ':id',
            permission: 'cms.default_packing.edit',
            element: DefaultPackingForm,
          },
        ],
      },
    ],
  },
  {
    path: 'crm',
    children: [
      {
        path: 'orders',
        children: [
          {
            path: '',
            permission: 'crm.orders.view',
            element: OrderIndex,
          },
        ],
      },
      {
        path: 'site-applications',
        children: [
          {
            path: '',
            permission: 'crm.site_applications.view',
            element: SiteApplicationIndex,
          },
        ],
      },
      {
        path: 'binotel-auto-reply-settings',
        children: [
          {
            path: '',
            permission: 'crm.binotel_auto_reply_settings.view',
            element: BinotelAutoReplyIndex,
          },
          {
            path: 'create',
            permission: 'crm.binotel_auto_reply_settings.edit',
            element: BinotelAutoReplyForm,
          },
          {
            path: ':id',
            permission: 'crm.binotel_auto_reply_settings.edit',
            element: BinotelAutoReplyForm,
          },
        ],
      },
      {
        path: 'promo-codes',
        children: [
          {
            path: '',
            permission: 'crm.promo_codes.view',
            element: PromoCodeIndex,
          },
          {
            path: 'create',
            permission: 'crm.promo_codes.edit',
            element: PromoCodeForm,
          },
          {
            path: ':id',
            permission: 'crm.promo_codes.edit',
            element: PromoCodeForm,
          },
        ],
      },
    ],
  },
  {
    path: 'statistics',
    children: [
      { path: '', element: StatisticsPage },
      { path: 'conversion', permission: 'statistics.visitors_and_conversion', element: Conversion },
      { path: 'site_statistic', permission: 'statistics.site_statistic', element: SiteStatistic },
    ],
  },
];

const createRoute = ({ element, children, noAuth, ...route }) => {
  const Component = noAuth ? element : withAuth(element);

  if (!element && children) {
    return (
      <Route key={route.path} {...route}>
        {children.map(createRoute)}
      </Route>
    );
  }

  return (
    <Route key={route.path} {...route} element={<Component />}>
      {children && children.map(createRoute)}
    </Route>
  );
};

const mapRoutes = () => {
  return routes.map(createRoute);
};

export default mapRoutes;
