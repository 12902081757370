import React from 'react';
import PropTypes from 'prop-types';
import { ButtonStyledGroup, ButtonStyled } from './styled';

// @Todo props spreading

const Button = ({ type = 'default', shape, icon, size, outlined, ghost, transparented, raised, squared, color, social, loading, children, ...rest }) => {
  return (
    <ButtonStyled
      squared={squared}
      outlined={outlined ? 1 : 0}
      ghost={ghost}
      transparent={transparented ? 1 : 0}
      raised={raised ? 1 : 0}
      data={type}
      size={size}
      shape={shape}
      type={type}
      icon={icon}
      color={color}
      social={social}
      loading={loading}
      {...rest}
    >
      {children}
    </ButtonStyled>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'link', 'dark', 'light', 'white', 'dashed', 'error', 'default']),
  shape: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  size: PropTypes.string,
  color: PropTypes.string,
  outlined: PropTypes.bool,
  transparented: PropTypes.bool,
  raised: PropTypes.bool,
  squared: PropTypes.bool,
  social: PropTypes.bool,
  loading: PropTypes.bool,
  ghost: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node]),
};

const BtnGroup = ({ children }) => {
  return <ButtonStyledGroup>{children}</ButtonStyledGroup>;
};

BtnGroup.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export { Button, BtnGroup };
