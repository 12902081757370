function convert(formData, path, value) {
  if (typeof value !== 'undefined') {
    if (value === 'true' || value === true) {
      value = '1';
    } else if (value === 'false' || value === false) {
      value = '0';
    } else if (value === null) {
      value = '';
    }

    if (Array.isArray(value)) {
      value.forEach((elem, index) => {
        formData = convert(formData, `${path}[${index}]`, elem);
      });
    } else if (typeof value === 'object' && !(value instanceof File)) {
      Object.entries(value).forEach(([key, elem]) => {
        formData = convert(formData, `${path}[${key}]`, elem);
      });
    } else {
      formData.append(path, value);
    }
  }

  return formData;
}

export default function (values) {
  let formData = new FormData();

  Object.entries(values).forEach(([key, value]) => {
    formData = convert(formData, key, value);
  });

  return formData;
}
