// import store from "@/redux/store";
// import { setShowForbiddenMessage } from "@/redux/reducers/auth/actionCreators";
/* eslint-disable */
import { get } from "lodash";

export default class Method {
    constructor(promise, client) {
        this.notifiableConfig = false;
        this.thenQueue = [];
        this.catchQueue = [];
        this.otherQueue = [];
        this.finallyQueue = [];
        this.validationQueue = [];
        this.unauthorizedQueue = [];
        this.dataQueue = [];
        this.codes = {};
        this.promise = promise;

        setTimeout(() => {
            if (this.notifiableConfig) {
                const promiseMessage = get(this.notifiableConfig, "promise", "Please wait...");
                const successMessage = get(this.notifiableConfig, "success", "Successfully saved! 👌");
                const errorMessage = get(this.notifiableConfig, "error", "Something went wrong 🤯");
            }
        });

        promise
            .then(async (response) => {
                for (let i = 0; i < this.dataQueue.length; i++) {
                    await this.dataQueue[i](response.data);
                }
                for (let i = 0; i < this.thenQueue.length; i++) {
                    await this.thenQueue[i](response);
                }
            })
            .finally(async (response) => {
                for (let i = 0; i < this.finallyQueue.length; i++) {
                    await this.finallyQueue[i](response);
                }
            })
            .catch(async (error) => {
                const {response} = error;
                let ignoreCatch = false;
                if (response) {
                    const {status} = response;
                    if (this.codes[status]) {
                        ignoreCatch = true;
                        for (let i = 0; i < this.codes[status].length; i++) {
                            await this.codes[status][i](response);
                        }
                    }
                    if (status === 422) {
                        ignoreCatch = true;
                        for (let i = 0; i < this.validationQueue.length; i++) {
                            await this.validationQueue[i](response.data.message, response.data.errors);
                        }
                    }
                    if (status === 401) {
                        ignoreCatch = true;
                        for (let i = 0; i < this.unauthorizedQueue.length; i++) {
                            await this.unauthorizedQueue[i](response.data.message);
                        }
                        // client.onLogout();
                    }

                    if (status === 403) {
                        // store.dispatch(setShowForbiddenMessage(true));
                    }
                }
                if (!ignoreCatch) {
                    for (let i = 0; i < this.otherQueue.length; i++) {
                        await this.otherQueue[i](response, error);
                    }
                }
                for (let i = 0; i < this.catchQueue.length; i++) {
                    await this.catchQueue[i](response, error);
                }
            });
    }

    /**
     * @param {boolean|Object} options
     * @returns {Method}
     */
    notifiable(options = true) {
        this.notifiableConfig = options;
        return this;
    }

    /**
     * @param callback
     * @returns {Method}
     */
    then(callback) {
        this.thenQueue.push(callback);
        return this;
    }

    /**
     * @param callback
     * @returns {Method}
     */
    finally(callback) {
        this.finallyQueue.push(callback);
        return this;
    }

    /**
     * @param callback
     * @returns {Method}
     */
    catch(callback) {
        this.catchQueue.push(callback);
        return this;
    }

    /**
     * @param callback
     * @returns {Method}
     */
    other(callback) {
        this.otherQueue.push(callback);
        return this;
    }

    /**
     * @param callback
     * @returns {Method}
     */
    data(callback) {
        this.dataQueue.push(callback);
        return this;
    }

    /**
     * @param callback
     * @returns {Method}
     */
    validation(callback) {
        this.validationQueue.push(callback);
        return this;
    }

    /**
     * @param callback
     * @returns {Method}
     */
    unauthorized(callback) {
        this.unauthorizedQueue.push(callback);
        return this;
    }

    /**
     * @param code
     * @param callback
     * @returns {Method}
     */
    code(code, callback) {
        if (!this.codes[code]) {
            this.codes[code] = [callback];
        } else {
            this.codes[code].push(callback);
        }
        return this;
    }

    /**
     * @returns {Promise}
     */
    getOriginalPromise() {
        return this.promise;
    }

    getData() {
        return new Promise((resolve) => this.data((data) => resolve(data)));
    }
}
