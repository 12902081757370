import { InputNumber } from 'antd';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { isNumber } from 'lodash';

const MoneyInput = ({ value, onChange, ...rest }) => {
  const handleChange = (value) => {
    onChange(value ? value * 100 : null);
  };

  const displayValue = useMemo(() => {
    if (isNumber(value)) {
      return value / 100;
    }

    return '';
  }, [value]);

  return <InputNumber style={{ width: '100%' }} {...rest} value={displayValue} onChange={handleChange} />;
};

MoneyInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};

export default MoneyInput;
