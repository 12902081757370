import { Checkbox, Col, Form, message, Radio, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { isEmpty } from 'lodash';
import { Main, SegmentFormStyled } from '../../../styled';
import { FormWrapper } from '../../style';
import { PageHeader } from '@/components/page-headers/page-headers';
import { Cards } from '@/components/cards/frame/cards-frame';
import useFormValues from '@/hooks/useFormValues';
import withAuthLayout from '@/layouts/withAuthLayout';
import FormInput from '@/form/FormInput';
import {
  PRODUCT_TYPE_BOUQUET,
  PRODUCT_TYPE_FLOWER,
  PRODUCT_TYPE_PLANT,
  PRODUCT_TYPE_POSTCARD,
  PRODUCT_TYPE_SWEET,
  PRODUCT_TYPE_TOY,
  productTypeToBillingGoodType,
} from '@/constants/products';
import FormSelectAsync from '@/form/FormSelectAsync';
import { Button } from '@/components/buttons/buttons';
import FormTextArea from '@/form/FormTextArea';
import AsyncSelect from '@/widgets/AsyncSelect';
import ProductImageUpload from '@/components/Product/ProductImageUpload';
import ProductSizeForm from '@/components/Product/ProductSizeForm';
import ProductItemsForm from '@/components/Product/ProductItemsForm';
import ProductItemsPrices from '@/components/Product/ProductItemsPrices';
import ProductSizesPrices from '@/components/Product/ProductSizesPrices';
import config from '@/config.json';
import FrontAppLinkByCityDropdown from '@/widgets/FrontAppLinkByCity';
import isSizedProduct from '@/helpers/isSizedProduct';

const ProductForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { form, id, handleSubmit, loading, errors } = useFormValues('products', true);
  const images = Form.useWatch('images', form);
  const productType = Form.useWatch('type', form);
  const billingGoodId = Form.useWatch('billing_good_id', form);
  const items = Form.useWatch('items', form);
  const sizes = Form.useWatch('sizes', form);
  const slug = Form.useWatch('slug', form);

  const saveAndCreateNext = () => {
    handleSubmit(form.getFieldsValue(), () => {
      message.success(t('success_message'));
      setTimeout(() => {
        form.resetFields();
        navigate(`../create`, { replace: true });
      }, 500);
    });
  };

  return (
    <>
      <PageHeader
        ghost
        title={t(id ? 'products.edit_page_header' : 'products.create_page_header')}
        buttons={[
          !id && (
            <Button size="large" type="info" onClick={saveAndCreateNext} raised key={1} loading={loading}>
              {t('saveAndCreateNext')}
            </Button>
          ),
          <Button size="large" type="success" onClick={() => handleSubmit(form.getFieldsValue())} raised key={2} loading={loading}>
            {t('save')}
          </Button>,
          <Button
            key={3}
            className="btn-cancel"
            size="large"
            onClick={() => {
              navigate(-1);
            }}
          >
            {t('cancel')}
          </Button>,
        ]}
      />
      <Main>
        <SegmentFormStyled>
          <FormWrapper>
            <Form
              style={{ width: '100%' }}
              form={form}
              layout="vertical"
              onFinish={handleSubmit}
              initialValues={{
                type: PRODUCT_TYPE_BOUQUET,
                approved: true,
              }}
            >
              <Row gutter={[24, 24]}>
                <Col md={12} xs={24}>
                  <div className="segment-block">
                    <div className="segment-content">
                      <Cards title={t('products.details')}>
                        <Form.Item name="type" label={t('products.items.type')}>
                          <Radio.Group disabled={Boolean(id)}>
                            <Radio value={PRODUCT_TYPE_BOUQUET}>{t(`products.types.${PRODUCT_TYPE_BOUQUET}`)}</Radio>
                            <Radio value={PRODUCT_TYPE_FLOWER}>{t(`products.types.${PRODUCT_TYPE_FLOWER}`)}</Radio>
                            <Radio value={PRODUCT_TYPE_TOY}>{t(`products.types.${PRODUCT_TYPE_TOY}`)}</Radio>
                            <Radio value={PRODUCT_TYPE_PLANT}>{t(`products.types.${PRODUCT_TYPE_PLANT}`)}</Radio>
                            <Radio value={PRODUCT_TYPE_SWEET}>{t(`products.types.${PRODUCT_TYPE_SWEET}`)}</Radio>
                            <Radio value={PRODUCT_TYPE_POSTCARD}>{t(`products.types.${PRODUCT_TYPE_POSTCARD}`)}</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <FormInput name="name" label={t('products.name')} error={errors.name} required placeholder="Троянда аваланч" />
                        <FormInput name="short_name" label={t('products.short_name')} error={errors.short_name} required placeholder="Троянда аваланч" />
                        {id && (
                          <FormInput
                            addonBefore={config.frontAppUrl}
                            addonAfter={<FrontAppLinkByCityDropdown pathname={`product/${slug}`} />}
                            name="slug"
                            label={t('products.url')}
                            error={errors.slug}
                            required
                            disabled
                          />
                        )}
                        <FormSelectAsync name="categories" label={t('products.categories')} resource="categories/search" multiple error={errors.categories} />
                        <FormTextArea name="description" label={t('products.description')} error={errors.description} placeholder="sdafa" />
                        <Form.Item name="approved" valuePropName="checked">
                          <Checkbox>{t('products.moderated')}</Checkbox>
                        </Form.Item>
                      </Cards>
                    </div>
                  </div>
                </Col>
                <Col md={12} xs={24}>
                  <div className="segment-block">
                    <div className="segment-content">
                      <Cards title={t('products.image')}>
                        <Form.Item name="images" valuePropName={null}>
                          <ProductImageUpload value={images} />
                        </Form.Item>
                      </Cards>
                    </div>
                  </div>
                </Col>
                <Col md={12} xs={24}>
                  <div className="segment-block">
                    <div className="segment-content">
                      <Cards title={t('products.nomenclature')}>
                        {isSizedProduct(productType) ? (
                          <>
                            <Form.Item
                              label={t('products.billing_good_id')}
                              name="billing_good_id"
                              required
                              validateStatus={errors.billing_good_id ? 'error' : false}
                              help={errors.billing_good_id}
                            >
                              <AsyncSelect resource="billing/goods-list" params={{ type: productTypeToBillingGoodType[productType] }} disabled={Boolean(id)} />
                            </Form.Item>
                            {billingGoodId && (
                              <Form.Item name="sizes" noStyle>
                                <ProductSizeForm billingGoodId={billingGoodId} errors={errors} hasHeight={+productType !== PRODUCT_TYPE_SWEET} />
                              </Form.Item>
                            )}
                          </>
                        ) : (
                          <Form.Item name="items" required noStyle>
                            <ProductItemsForm errors={errors} />
                          </Form.Item>
                        )}
                      </Cards>
                    </div>
                  </div>
                </Col>
                <Col md={12} xs={24}>
                  <div className="segment-block">
                    <div className="segment-content">
                      <Cards title={t('products.price_city_1c')}>
                        {productType === PRODUCT_TYPE_BOUQUET && !isEmpty(items) && <ProductItemsPrices items={items} />}
                        {isSizedProduct(productType) && !isEmpty(sizes) && billingGoodId && <ProductSizesPrices sizes={sizes} billingGoodId={billingGoodId} />}
                      </Cards>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          </FormWrapper>
        </SegmentFormStyled>
      </Main>
    </>
  );
};

export default withAuthLayout(ProductForm);
