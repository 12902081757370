import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import ApiClient from '@/helpers/apiClient/ApiClient';
import { Modal } from '@/components/modals/antd-modals';
import priceFormat from '@/helpers/priceFormat';
import { ORDER_DELIVERY_TYPE_COURIER, ORDER_DELIVERY_TYPE_SELF, ORDER_PAYMENT_TYPE_CASH, ORDER_STATUS_NEED_CONFIRMATION } from '@/constants/orders';
import ApproveOrCancelOrderCell from '@/components/Order/ApproveOrCancelOrderCell';

const ShortOrderInfoModal = ({ id, onClose }) => {
  const { t } = useTranslation();

  const [order, setOrder] = useState(null);

  useEffect(() => {
    ApiClient.call('get', `orders/${id}`).data(({ data }) => setOrder(data));
  }, [id]);

  if (!order) {
    return;
  }

  return (
    <Modal visible title={`Замовлення №${order.billing_number}`} onCancel={onClose} width={1200} footer={null}>
      {order.status === ORDER_STATUS_NEED_CONFIRMATION && order.payment_type === ORDER_PAYMENT_TYPE_CASH && (
        <div className="d-flex justify-content-between mb-30">
          <strong>{t('orders.confirmation')}</strong>
          <div>
            <ApproveOrCancelOrderCell id={order.id} />
          </div>
        </div>
      )}
      <Row gutter={[30, 30]}>
        <Col md={12} xs={24}>
          <Card title={t('orders.client')} style={{ height: '100%' }}>
            <ul>
              <li className="d-flex justify-content-between mb-20">
                <strong>{t('orders.client_name')}</strong>
                <span>{order.client.name}</span>
              </li>
              <li className="d-flex justify-content-between mb-20">
                <strong>{t('orders.client_surname')}</strong>
                <span>{order.client.last_name}</span>
              </li>
              <li className="d-flex justify-content-between mb-20">
                <strong>{t('orders.client_telephone')}</strong>
                <span>{order.client.phone_number}</span>
              </li>
            </ul>
          </Card>
        </Col>
        <Col md={12} xs={24}>
          <Card title={t('orders.product')} style={{ height: '100%' }}>
            <ol>
              {order.items.map((item) => {
                return (
                  <li>
                    <span className="d-flex justify-content-between mb-20">
                      <Link to={`/cms/products/${item.product_id}`} target="_blank">
                        {item.name}
                      </Link>
                      <span>{priceFormat(item.price)}</span>
                      <strong style={{ textDecoration: 'underline' }}>{item.count} шт</strong>
                    </span>
                    <ul>
                      {item.goods_data.map((good) => {
                        return (
                          <li>
                            <span className="d-flex justify-content-between mb-20">
                              <span>{good.name}</span>
                              <span>
                                {good.price} {t('uah_short')}
                              </span>
                              <strong style={{ textDecoration: 'underline' }}>{good.quantity} шт</strong>
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              })}
            </ol>
            <div className="d-flex justify-content-end mt-30">
              <strong>Сума товарів - {priceFormat(order.items_sum)}</strong>
            </div>
          </Card>
        </Col>
        <Col md={12} xs={24}>
          <Card title={t('orders.delivery')} style={{ height: '100%' }}>
            <ul>
              <li className="d-flex justify-content-between mb-20">
                <strong>{t('orders.type')}</strong>
                <span>
                  {t(`orders.delivery_types.${order.delivery_type}`)} {order.delivery_type === ORDER_DELIVERY_TYPE_SELF && <span> ({order.store.name})</span>}
                </span>
              </li>
              <li className="d-flex justify-content-between mb-20">
                <strong>{t('orders.date_delivery_or_pickup')}</strong>
                <span>{moment(order.delivery_date).format('DD.MM.Y')}</span>
              </li>
              <li className="d-flex justify-content-between mb-20">
                <strong>{t('orders.period_delivery_or_pickup')}</strong>
                <span>
                  {moment(order.delivery_time_from, 'HH:mm:ss').format('HH:mm')} - {moment(order.delivery_time_to, 'HH:mm:ss').format('HH:mm')}
                </span>
              </li>
              {order.delivery_type === ORDER_DELIVERY_TYPE_COURIER && (
                <>
                  <li className="d-flex justify-content-between mb-20">
                    <strong>{t('orders.address')}</strong>
                    <span>{order.delivery_address_line}</span>
                  </li>
                </>
              )}
              <li className="d-flex justify-content-between mb-20">
                <strong>{t('orders.recipient')}</strong>
                <span>
                  {order.recipient_name}, {order.recipient_phone_number}
                </span>
              </li>
              {order.comment && (
                <li className="d-flex justify-content-between mb-20">
                  <strong>{t('orders.comment')}</strong>
                  <span>{order.comment}</span>
                </li>
              )}
              {order.delivery_details && (
                <li className="d-flex justify-content-between mb-20">
                  <strong>{t('orders.to_courier')}</strong>
                  <span>{order.delivery_details}</span>
                </li>
              )}
            </ul>
          </Card>
        </Col>
        <Col md={12} xs={24}>
          <Card title={t('orders.payment')} style={{ height: '100%' }}>
            <ul>
              <li className="d-flex justify-content-between mb-20">
                <strong>{t('orders.type')}</strong>
                <span>{t(`orders.payment_types.${order.payment_type}`)}</span>
              </li>
              <li className="d-flex justify-content-between mb-20">
                <strong>{t('orders.status')}</strong>
                <span>{t(`orders.payment_statuses.${order.payment_status}`)}</span>
              </li>
              {Boolean(order.delivery_price) && (
                <li className="d-flex justify-content-between mb-20">
                  <strong>{t('orders.delivery')}</strong>
                  <span>{priceFormat(order.delivery_price)}</span>
                </li>
              )}
              {Boolean(order.discount_amount) && (
                <li className="d-flex justify-content-between mb-20">
                  <strong>{t('orders.discount')}</strong>
                  <span>- {priceFormat(order.discount_amount)}</span>
                </li>
              )}
              <li className="d-flex justify-content-between mb-20">
                <strong>{t('orders.total_sum')}</strong>
                <span>
                  <strong>{priceFormat(order.sum)}</strong>
                </span>
              </li>
            </ul>
          </Card>
        </Col>
      </Row>
    </Modal>
  );
};

ShortOrderInfoModal.propTypes = {
  id: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ShortOrderInfoModal;
