import React from 'react';
import { Checkbox, Col, Divider, Form, Input, Radio, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Main } from '../../../styled';
import { FormWrapper } from '../../style';
import FormInput from '@/form/FormInput';
import FormSelect from '@/form/FormSelect';
import withAuthLayout from '@/layouts/withAuthLayout';
import useFormValues from '@/hooks/useFormValues';
import CheckboxErrorsTable from '@/components/Fop/CheckboxErrorsTable';
import FormSubmit from '@/form/FormSubmit';
import { Cards } from '@/components/cards/frame/cards-frame';
import { PageHeader } from '@/components/page-headers/page-headers';
import Alert from '@/components/alerts/alerts';

const TYPE_FOP = 1;
const TYPE_TOV = 2;

const FopForm = () => {
  const { t } = useTranslation();
  const { form, id, handleSubmit, loading, errors } = useFormValues('fops');
  const type = Form.useWatch('type', form);

  return (
    <>
      <PageHeader ghost title={t('fops.list_page_header')} />
      <Main>
        <Cards headless>
          <Row justify="center">
            <Col xl={12} md={16} xs={24}>
              {id && (
                <div style={{ marginBottom: 20 }}>
                  <Alert type="warning" description={t('fops.alert_description')} message={t('fops.alert_message')} />
                </div>
              )}
              <FormWrapper>
                <Form
                  style={{ width: '100%' }}
                  form={form}
                  layout="vertical"
                  onFinish={handleSubmit}
                  initialValues={{
                    available_in_bot: !id,
                    is_used_on_site: false,
                  }}
                >
                  <FormInput name="name" required label={t('fops.fields.name')} error={errors.name} placeholder="Вінниця" />
                  <FormInput name="iban" required label={t('fops.fields.iban')} error={errors.iban} />
                  <Divider />
                  <Row gutter={30}>
                    <Col span={24}>
                      <Form.Item name="type" required label={t('fops.fields.type')} validateStatus={errors.type ? 'error' : false} help={errors.type}>
                        <Radio.Group>
                          <Radio value={TYPE_FOP}>{t('fops.type_fop')}</Radio>
                          <Radio value={TYPE_TOV}>{t('fops.type_tov')}</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                      <FormInput required name="liq_pay_public_key" label={t('fops.fields.liq_pay_public_key')} error={errors.liq_pay_public_key} />
                    </Col>
                    <Col md={12} xs={24}>
                      <FormInput required name="liq_pay_private_key" label={t('fops.fields.liq_pay_private_key')} error={errors.liq_pay_private_key} />
                    </Col>
                    <Divider />
                    <Col md={12} xs={24}>
                      <FormInput required name="checkbox_license_key" label={t('fops.fields.checkbox_license_key')} error={errors.checkbox_license_key} />
                    </Col>
                    <Col md={12} xs={24}>
                      <FormInput required name="checkbox_pin_code" label={t('fops.fields.checkbox_pin_code')} error={errors.checkbox_pin_code} />
                    </Col>
                    {type === TYPE_TOV && (
                      <Col span={24}>
                        <FormSelect
                          name="tax_codes"
                          label={t('fops.fields.tax_codes')}
                          options={[]}
                          mode="tags"
                          placeholder={t('fops.fields.tax_codes')}
                          required
                          error={errors.tax_codes}
                        />
                      </Col>
                    )}
                    {id && (
                      <Col span={24}>
                        <Form.Item label={t('fops.fields.checkbox_access_token')} name="checkbox_access_token" validateStatus={null}>
                          <Input.TextArea disabled rows={4} />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                  <Divider />
                  <Form.Item name="available_in_bot" valuePropName="checked">
                    <Checkbox defaultChecked={!id}>{t('fops.fields.available_in_bot')}</Checkbox>
                  </Form.Item>
                  <Form.Item name="is_used_on_site" valuePropName="checked">
                    <Checkbox disabled={type !== TYPE_TOV}>{t('fops.fields.is_used_on_site')}</Checkbox>
                  </Form.Item>
                  <FormSubmit loading={loading} />
                </Form>
              </FormWrapper>
            </Col>
          </Row>
        </Cards>
        {id && (
          <div id="errors">
            <CheckboxErrorsTable fopId={id} />
          </div>
        )}
      </Main>
    </>
  );
};

export default withAuthLayout(FopForm);
