import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { Card } from 'antd';
import PropTypes from 'prop-types';
import { StatisticCardWrapper } from '@/components/statistic/conversion/styled';

function StatisticCard({ title, icon }) {
  return (
    <StatisticCardWrapper>
      <Card className="statistic-card-content">
        <div className="statistic-card-title__container">
          <h2 className="statistic-card-title">{title}</h2>
          <FeatherIcon icon={icon} className="statistic-card-icon" />
        </div>
      </Card>
    </StatisticCardWrapper>
  );
}

StatisticCard.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
};

export default StatisticCard;
