import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Cards } from '../../../cards/frame/cards-frame';
import FilterButtons from '@/components/statistic/siteStatistic/FilteredButton';
import useDataFetcher from '@/components/statistic/siteStatistic/hooks/useDataFetcher';
import { RevenueChartWrapper } from '@/components/statistic/siteStatistic/styled';
import ChartjsDonutChart from '@/components/statistic/siteStatistic/ChartjsDonutChart';
import Spinner from '@/widgets/Spinner';
import { colorsArray } from '@/components/utilities/statistic/utilities';

const formattedData = (locationData) => {
  const labels = [];
  const datasets = [];
  const backgroundColors = [];

  locationData.forEach((location, i) => {
    labels.push(location.city);
    datasets.push(location.revenue);
    backgroundColors.push(colorsArray[i]);
  });
  return { labels, datasets, backgroundColors };
};

const CitiesRevenue = () => {
  const { t } = useTranslation();
  const { periodState, setPeriodState, data: locationData, isLoading } = useDataFetcher('site-statistic/cities-revenue', 'year');
  const { datasets, labels, backgroundColors } = useMemo(() => formattedData(locationData), [locationData]);

  return (
    <RevenueChartWrapper>
      {locationData !== null && (
        <Cards
          isbutton={<FilterButtons periodState={periodState} setPeriodState={setPeriodState} />}
          title={t('site_statistic.cities_revenue.main_title')}
          size="large"
        >
          {isLoading ? (
            <div style={{ height: '278px' }}>
              <Spinner />
            </div>
          ) : (
            <div className="revenue-doughnut">
              <ChartjsDonutChart
                labels={labels}
                datasets={[
                  {
                    data: datasets,
                    backgroundColor: backgroundColors,
                  },
                ]}
              />
            </div>
          )}
        </Cards>
      )}
    </RevenueChartWrapper>
  );
};

export default CitiesRevenue;
