import React from 'react';
import PropTypes, { object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ModalStyled } from './styled';
import { Button } from '../buttons/buttons';

const Modal = ({ onCancel, className = 'atbd-modal', onOk, visible, title, type, color, footer, width = 620, children }) => {
  const { t } = useTranslation();

  return (
    <ModalStyled
      title={title}
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
      type={color ? type : false}
      width={width}
      className={className}
      footer={
        footer || footer === null
          ? footer
          : [
              <Button type="secondary" key="back" onClick={onCancel}>
                {t('cancel')}
              </Button>,
              <Button type={type} key="submit" onClick={onOk}>
                {t('confirm')}
              </Button>,
            ]
      }
    >
      {children}
    </ModalStyled>
  );
};

Modal.propTypes = {
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  visible: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  footer: PropTypes.arrayOf(object),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node]),
};

const alertModal = ModalStyled;
export { Modal, alertModal };
