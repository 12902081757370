import React, { useState } from 'react';
import { Col, Row } from 'antd';
import FeatherIcon from 'feather-icons-react';
import PropTypes from 'prop-types';
import { first, intersection } from 'lodash';
import classNames from 'classnames';
import { FormWrapper } from '../container/pages/style';
import { Cards } from '../components/cards/frame/cards-frame';

const TabForm = ({ tabs = [], errors = {} }) => {
  const [activeTab, setActiveTab] = useState(first(tabs)?.key);
  const cards = (
    <div className="card-nav">
      <ul>
        {tabs.map((tab) => {
          const hasError = intersection(Object.keys(errors), tab.fields).length;
          return (
            <li key={tab.key}>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <a
                className={classNames({
                  active: activeTab === tab.key,
                  'text-warning': hasError,
                })}
                onClick={() => setActiveTab(tab.key)}
              >
                {tab.icon && <FeatherIcon icon={tab.icon} className={classNames({ 'text-warning': hasError })} size={14} />}
                {tab.name}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );

  return (
    <>
      <Row gutter={25}>
        <Col sm={24} xs={24}>
          <FormWrapper>
            <Cards title={cards}>
              {tabs.map((tab) => {
                return (
                  <div style={{ display: activeTab === tab.key ? 'block' : 'none' }} key={tab.key}>
                    <Row justify="center">
                      <Col xl={12} lg={16} md={24} xs={24}>
                        {tab.component}
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </Cards>
          </FormWrapper>
        </Col>
      </Row>
    </>
  );
};

TabForm.propTypes = {
  tabs: PropTypes.array,
  errors: PropTypes.object,
};

export default TabForm;
