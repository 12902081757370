import { useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import { uk } from 'react-date-range/dist/locale';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonGroup } from './styled';
import { Button } from '@/components/buttons/buttons';
import { customCalendarInputs, customCalendarLabels } from '@/components/statistic/siteStatistic/calendar/customCalendarLabels';
import { ItemWraper } from '@/components/datePicker/style';

const defaultRangeData = {
  datePickerInternational: null,
  dateRangePicker: {
    selection: {
      startDate: moment().startOf('month').toDate(),
      endDate: moment().toDate(),
      key: 'selection',
    },
  },
};

const DateRangePickerOne = ({ range, setRange, onApply }) => {
  const [dataRangeState, setDataRangeState] = useState(range);
  const { t } = useTranslation();

  const { dateRangePicker } = dataRangeState;
  const { startDate, endDate } = dateRangePicker.selection;

  if (!startDate || !endDate) {
    return;
  }

  const handleRangeChange = (obj) => {
    setDataRangeState((prevState) => ({
      ...prevState,
      dateRangePicker: {
        ...prevState.dateRangePicker,
        ...obj,
      },
    }));
  };

  return (
    <ItemWraper>
      <DateRangePicker
        locale={uk}
        onChange={handleRangeChange}
        showSelectionPreview
        moveRangeOnFirstSelection={false}
        className="PreviewArea"
        months={2}
        ranges={[dateRangePicker.selection]}
        direction="horizontal"
        staticRanges={customCalendarLabels}
        inputRanges={customCalendarInputs}
      />

      <ButtonGroup>
        <Button
          size="small"
          type="primary"
          onClick={() => {
            setRange(dataRangeState);
            onApply();
          }}
        >
          {t('site_statistic.calendar_buttons.apply')}
        </Button>
        <Button
          size="small"
          type="white"
          outlined
          onClick={() => {
            onApply();
            setDataRangeState(defaultRangeData);
            setRange(defaultRangeData);
          }}
        >
          {t('site_statistic.calendar_buttons.clear')}
        </Button>
        <Button
          size="small"
          type="white"
          outlined
          onClick={() => {
            onApply();
          }}
        >
          {t('site_statistic.calendar_buttons.cancel')}
        </Button>
      </ButtonGroup>
    </ItemWraper>
  );
};

DateRangePickerOne.propTypes = {
  range: PropTypes.object.isRequired,
  onApply: PropTypes.func.isRequired,
  setRange: PropTypes.func.isRequired,
};

export default DateRangePickerOne;
