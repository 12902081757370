import React from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { Cards } from '@/components/cards/frame/cards-frame';
import FilterButtons from '@/components/statistic/siteStatistic/FilteredButton';
import Spinner from '@/widgets/Spinner';
import useDataFetcher from '@/components/statistic/siteStatistic/hooks/useDataFetcher';

const TopCities = () => {
  const { periodState, setPeriodState, data: locationData, isLoading } = useDataFetcher('site-statistic/top-cities', 'year');
  const { t } = useTranslation();

  const locationColumns = [
    {
      title: t('site_statistic.top_cities_title.city'),
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: t('site_statistic.top_cities_title.count'),
      dataIndex: 'count',
      key: 'count',
    },
    {
      title: t('site_statistic.top_cities_title.revenue'),
      dataIndex: 'revenue',
      key: 'revenue',
      render: (value) => <span>₴ {Intl.NumberFormat('uk-UA').format(value)}</span>,
    },
  ];

  return (
    <Cards
      isbutton={<FilterButtons periodState={periodState} setPeriodState={setPeriodState} />}
      title={t('site_statistic.top_cities_title.main_title')}
      size="large"
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="location-table">
          <Table columns={locationColumns} dataSource={locationData} pagination={false} />
        </div>
      )}
    </Cards>
  );
};

export default TopCities;
