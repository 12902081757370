import { Col, Row, Skeleton } from 'antd';
import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import withAuthLayout from '@/layouts/withAuthLayout';
import { PageHeader } from '@/components/page-headers/page-headers';
import { Main } from '@/container/styled';
import { Cards } from '@/components/cards/frame/cards-frame';
import AuthorBox from '@/container/pages/account/ProfileAuthorBox';
import { SettingWrapper } from '@/container/pages/account/style';
import Profile from '@/container/pages/account/Profile';
import Password from '@/container/pages/account/Password';
import Notification from '@/container/pages/account/Notificaiton';

const AccountPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeader ghost title={t('account.my_account')} />

      <Main>
        <Row gutter={25}>
          <Col xxl={6} lg={8} md={10} xs={24}>
            <Suspense
              fallback={
                <Cards headless>
                  <Skeleton avatar />
                </Cards>
              }
            >
              <AuthorBox />
            </Suspense>
          </Col>
          <Col xxl={18} lg={16} md={14} xs={24}>
            <SettingWrapper>
              <Routes>
                <Route path="" element={<Profile />} />
                <Route path="profile" element={<Profile />} />
                <Route path="password" element={<Password />} />
                <Route path="notification" element={<Notification />} />
              </Routes>
            </SettingWrapper>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default withAuthLayout(AccountPage);
