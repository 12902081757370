import styled from 'styled-components';

export const StatisticCardWrapper = styled.div`
  .statistic-card-content {
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  ,
  .statistic-card-title__container {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .statistic-card-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 0;
  }
  .statistic-card-icon {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
  }
`;

export const StatisticChartsTitleWrap = styled.div`
  .statistic-chart-title_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
`;

export const ComboChartWrap = styled.div`
  .combo-chart_container {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const PieChartWrap = styled.div`
  .pie-chart_container {
    max-width: 100%;
    display: flex;
    justify-content: center;
    border: 1px solid #e3e6ef;
    border-radius: 5px;
    padding: 5px;
    flex-wrap: wrap;
    gap: 5px;
    //min-height: 450px;
  }

  .pie-chart_elements_container {
    width: 400px;
    box-sizing: border-box;
  }
`;

export const NoDataPieMessageWrap = styled.div`
  width: 400px;
  height: 300px;
  box-sizing: border-box;

  .no-data-pie-title {
    font-size: 12px;
    font-weight: bold;
  }

  .no-data-pie-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
  }
  .no-data-pie-value {
    position: absolute;
    left: 50px;
  }
`;

export const RadioGroupStyle = styled.div`
  box-shadow: inset 0px 0px 20px 0px rgba(219, 219, 219, 0.4);
  border-radius: 5px;

  .custom-radio-group .ant-radio-button-wrapper {
    background-color: transparent;
    color: rgb(146, 153, 184);
    padding: 0 15px;
    height: 30px;
    line-height: 30px;
    border: 0;
    border-radius: 5px;
  }

  .custom-radio-group .ant-radio-button-wrapper:last-child {
    margin-right: 0px;
  }

  .custom-radio-group .ant-radio-button-wrapper:hover,
  .custom-radio-group .ant-radio-button-wrapper:focus {
    color: rgb(146, 153, 184, 0.3);
    border-color: #ececfd;
  }

  .custom-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #fff !important;
    color: #a1a1a1 !important;
    border-color: #f4f5f7;
  }

  .custom-radio-group .ant-radio-button-wrapper-checked:hover,
  .custom-radio-group .ant-radio-button-wrapper-checked:focus {
    background-color: #f4f5f7;
    border-color: #f4f5f7;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: transparent;
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    width: 0 !important;
  }

  .ant-radio-button-wrapper:focus-within,
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    box-shadow: 0 0 2px 2px rgb(146, 153, 184, 0.4);
    font-weight: bold;
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: 0 0 2px 2px rgba(146, 153, 184, 0.4);
  }
`;
