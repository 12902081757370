import styled from 'styled-components';

const ProductImageUploadStyled = styled.div`
  margin-top: 10px !important;
  box-sizing: border-box;
  padding: 0;
  color: #272b41;
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  line-height: 1.5715;

  .ant-upload {
    border-spacing: 6px;
    border-width: 2px;
    border-radius: 10px;
    background: ${({ theme }) => theme['bg-color-light']};
    border-color: ${({ theme }) => theme['border-color-deep']};
    padding: 50px;
    @media only screen and (max-width: 575px) {
      padding: 15px !important;
    }

    .ant-upload-drag-icon {
      i,
      svg {
        color: ${({ theme }) => theme['extra-light-color']};
      }
    }

    .ant-upload-text {
      font-weight: 500;
      margin-bottom: 8px;
    }

    .ant-upload-hint {
      font-size: 15px;
      font-weight: 500;
      color: ${({ theme }) => theme['gray-color']};

      span {
        color: ${({ theme }) => theme['secondary-color']};
      }
    }
  }

  .list-container {
    transition: opacity 0.3s, height 0.3s;

    .list-item {
      height: 100%;
      padding: 0;
      border: 0 none;
      margin-top: 25px;
      border-radius: 4px;
      position: relative;
      font-size: 14px;

      .list-item-info {
        height: 100%;
        padding: 0;
        transition: background-color 0.3s;
        display: flex;
        -moz-box-align: center;
        align-items: center;
        width: 100%;
        justify-content: space-between;

        .list-item-thumbnail {
          position: relative;
          top: 0;
          min-width: 100px;
          width: auto;
          height: 100%;
          line-height: 60px;
          text-align: center;
          opacity: 0.8;

          .primary-image {
            border: 3px solid #a3dea3;
          }

          img {
            max-width: 100px;
            width: 100%;
            height: 100%;
            border-radius: 6px;
            display: block;
            overflow: hidden;
          }
        }

        .list-item-name {
          font-weight: 500;
          color: rgb(39, 43, 65);
          flex: auto;
          display: inline-block;
          box-sizing: border-box;
          max-width: 100%;
          padding: 0 8px 0 48px;
          overflow: hidden;
          line-height: 44px;
          white-space: nowrap;
          text-overflow: ellipsis;
          transition: all 0.3s;
          width: 100%;
        }

        .list-item-actions {
          position: relative;
          top: 0;
          flex: 0;
          right: 0;
          display: flex;
          gap: 20px;

          .ant-btn-sm {
            height: 22px;
            line-height: 1;
            vertical-align: top;

            .delete-btn {
              width: 15px;
              color: rgb(255, 77, 79);
            }

            .make-primary-btn {
              width: 15px;
              color: rgb(132, 205, 132);
            }
          }
        }
      }
    }
  }
`;

const ProductSizeFromStyled = styled.div`
  .container {
    margin: 20px 0 20px 0;

    .heading {
      display: flex;
      justify-content: space-between;
    }

    .actions {
      display: flex;
      justify-content: space-between;
    }
  }

  .ant-empty {
    margin-bottom: 20px;
  }
`;

const ProductSizeStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  margin-bottom: 5px;

  .ant-form-item {
    width: 100%;

    .ant-form-item-control-input-content {
      display: flex;
    }

    .ant-btn {
      height: 48px;
      padding: 0 10px;
    }
  }

  .fa {
    cursor: pointer;
    font-size: 22px;
  }
`;

const ProductItemStyled = styled.div`
  margin-bottom: 20px;
  position: relative;

  .error {
    margin-left: 20px;
    display: flex;
    gap: 5px;
    color: red;
    align-items: center;
  }
`;

export { ProductImageUploadStyled, ProductSizeFromStyled, ProductSizeStyled, ProductItemStyled };
