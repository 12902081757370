import { useTranslation } from 'react-i18next';
import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { CardToolbox, Main } from '@/container/styled';
import { PageHeader } from '@/components/page-headers/page-headers';
import Table from '@/widgets/Table/Table';
import withAuthLayout from '@/layouts/withAuthLayout';

const DefaultPackingIndex = () => {
  const { t } = useTranslation();

  const columns = React.useMemo(
    () => [
      {
        title: t('default_packing.fields.type'),
        dataIndex: 'type',
        key: 'type',
        render: (value) => <span>{t(`default_packing.types.${value}`)}</span>,
      },
      {
        title: t('default_packing.fields.city_id'),
        dataIndex: ['city', 'name'],
        key: 'city_id',
      },
    ],
    [],
  );

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={t('default_packing.list_page_header')}
          buttons={[
            <Button className="btn-add_new" size="default" type="primary" key="1">
              <Link to="/cms/default-packing/create">+ {t('default_packing.create_button')}</Link>
            </Button>,
          ]}
        />
      </CardToolbox>

      <Main>
        <Table route="cms/default-packing" resource="default-packing" columns={columns} permissionNamespace="cms.default_packing" />
      </Main>
    </>
  );
};

export default withAuthLayout(DefaultPackingIndex);
