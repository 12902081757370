import { useTranslation } from 'react-i18next';
import { Checkbox, Col, Form, Row, Space } from 'antd';
import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import FontAwesome from 'react-fontawesome';
import withAuthLayout from '@/layouts/withAuthLayout';
import useFormValues from '@/hooks/useFormValues';
import { PageHeader } from '@/components/page-headers/page-headers';
import { Main } from '@/container/styled';
import { Cards } from '@/components/cards/frame/cards-frame';
import { ActionsWrapper, FormWrapper } from '@/container/pages/style';
import FormInput from '@/form/FormInput';
import FormSubmit from '@/form/FormSubmit';
import FormSelectAsync from '@/form/FormSelectAsync';
import { Button } from '@/components/buttons/buttons';
import { ItemsStyled } from '@/container/pages/cms/mainPageCarousel/styles';
import MoneyInput from '@/widgets/MoneyInput';

const MainPageCarouselForm = () => {
  const { t } = useTranslation();
  const { id, form, loading, handleSubmit, errors } = useFormValues('main-page/carousel');

  return (
    <>
      <PageHeader ghost title={id ? t('slider_elements.edit_page_header') : t('slider_elements.create_page_header')} />
      <Main>
        <Cards headless>
          <Row justify="center">
            <Col xs={24} md={20} lg={16} xl={12}>
              <FormWrapper>
                <Form style={{ width: '100%' }} form={form} layout="vertical" onFinish={handleSubmit} initialValues={{ is_active: true }}>
                  <FormInput name="title" label={t('slider_elements.fields.title')} error={errors.title} required />
                  <FormSelectAsync name="city_id" resource="cities/search" label={t('main_page_carousel.fields.city_id')} error={errors.city_id} required />
                  <FormSelectAsync
                    name="category_id"
                    resource="categories/search"
                    label={t('main_page_carousel.fields.category_id')}
                    error={errors.category_id}
                    required
                  />
                  <Form.Item name="is_active" valuePropName="checked">
                    <Checkbox>{t('main_page_carousel.fields.is_active')}</Checkbox>
                  </Form.Item>
                  <Form.List name="products">
                    {(fields, { add, remove, move }) => (
                      <div>
                        <h3>{t('main_page_carousel.fields.items')}</h3>
                        <ItemsStyled>
                          <thead>
                            <tr>
                              <th style={{ width: 50 }}>{t('main_page_carousel.fields.order')}</th>
                              <th>{t('main_page_carousel.fields.product_id')}</th>
                              <th style={{ width: 125 }}>{t('main_page_carousel.fields.old_price')}</th>
                              <th style={{ width: 200 }}>{t('main_page_carousel.fields.operation')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map((field, index) => {
                              return (
                                <tr key={field.name}>
                                  <td>{index + 1}</td>
                                  <td>
                                    <FormSelectAsync
                                      {...field}
                                      name={[field.name, 'id']}
                                      resource="products/search"
                                      label={t('main_page_carousel.fields.product_id')}
                                      error={errors[`products.${field.name}`]}
                                      required
                                    />
                                  </td>
                                  <td>
                                    <Form.Item
                                      name={[field.name, 'old_price']}
                                      help={errors[`products.${field.name}.old_price`]}
                                      validateStatus={errors[`products.${field.name}.old_price`] ? 'error' : false}
                                      label={t('main_page_carousel.fields.old_price')}
                                    >
                                      <MoneyInput />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Space>
                                      <Button type="primary" icon={<FontAwesome name="arrow-circle-down" />} onClick={() => move(index, index + 1)} />
                                      <Button type="primary" icon={<FontAwesome name="arrow-circle-up" />} onClick={() => move(index, index - 1)} />
                                      <Button type="danger" icon={<DeleteOutlined />} onClick={() => remove(field.name)} />
                                    </Space>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </ItemsStyled>
                        <Button onClick={() => add({ name: '' })}>{t('add')}</Button>
                      </div>
                    )}
                  </Form.List>
                  <ActionsWrapper>
                    <FormSubmit loading={loading} />
                  </ActionsWrapper>
                </Form>
              </FormWrapper>
            </Col>
          </Row>
        </Cards>
      </Main>
    </>
  );
};

export default withAuthLayout(MainPageCarouselForm);
