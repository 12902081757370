import { useTranslation } from 'react-i18next';
import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import FeatherIcon from 'feather-icons-react';
import { Badge } from 'antd';
import { CardToolbox, Main } from '@/container/styled';
import { PageHeader } from '@/components/page-headers/page-headers';
import Table from '@/widgets/Table/Table';
import withAuthLayout from '@/layouts/withAuthLayout';
import AsyncSelectFilter from '@/widgets/Table/AsyncSelectFilter';
import DateRangeFilter from '@/widgets/Table/DateRangeFilter';
import TextFilter from '@/widgets/Table/TextFilter';

import {
  INVOICE_PAYMENT_STATUS_FAILED,
  INVOICE_PAYMENT_STATUS_REVERSED,
  INVOICE_PAYMENT_STATUS_SUCCESS,
  INVOICE_PAYMENT_STATUS_WAITING,
  INVOICE_PAYMENT_STATUS_WAIT_SECURE,
} from '@/constants/invoices';
import { Tag } from '@/components/tags/tags';
import CreateOrViewReceiptAction from '@/components/Invoice/CreateOrViewReceiptAction';

const PAYMENT_STATUSES = {
  [INVOICE_PAYMENT_STATUS_WAITING]: 'geekblue',
  [INVOICE_PAYMENT_STATUS_SUCCESS]: 'lime',
  [INVOICE_PAYMENT_STATUS_FAILED]: 'volcano',
  [INVOICE_PAYMENT_STATUS_REVERSED]: 'blue',
  [INVOICE_PAYMENT_STATUS_WAIT_SECURE]: 'geekblue',
};

const InvoiceIndex = () => {
  const { t } = useTranslation();

  const columns = React.useMemo(() => [
    {
      title: t('invoices.columns.id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('invoices.columns.order_number'),
      dataIndex: 'order_number',
      key: 'order_number',
      filterDropdown: (props) => <TextFilter {...props} />,
    },
    {
      title: t('invoices.columns.amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: (value) => <span>{value} грн.</span>,
      sorter: true,
    },
    {
      title: t('invoices.columns.payment_status'),
      dataIndex: 'payment_status',
      key: 'payment_status',
      render: (value) => (
        <span>
          <Tag color={PAYMENT_STATUSES[value]}>{t(`invoices.payment_statuses.${value}`)}</Tag>
        </span>
      ),
      filters: [
        {
          text: t(`invoices.payment_statuses.${INVOICE_PAYMENT_STATUS_WAITING}`),
          value: INVOICE_PAYMENT_STATUS_WAITING,
        },
        {
          text: t(`invoices.payment_statuses.${INVOICE_PAYMENT_STATUS_SUCCESS}`),
          value: INVOICE_PAYMENT_STATUS_SUCCESS,
        },
        {
          text: t(`invoices.payment_statuses.${INVOICE_PAYMENT_STATUS_FAILED}`),
          value: INVOICE_PAYMENT_STATUS_FAILED,
        },
        {
          text: t(`invoices.payment_statuses.${INVOICE_PAYMENT_STATUS_REVERSED}`),
          value: INVOICE_PAYMENT_STATUS_REVERSED,
        },
        {
          text: t(`invoices.payment_statuses.${INVOICE_PAYMENT_STATUS_WAIT_SECURE}`),
          value: INVOICE_PAYMENT_STATUS_WAIT_SECURE,
        },
      ],
    },
    {
      title: t('fops.type_fop'),
      dataIndex: 'fop',
      key: 'fop_id',
      render: (fop) => {
        return (
          <span>
            <Link to={`/accounting/fops/${fop.id}`}>{fop.name}</Link>
          </span>
        );
      },
      filterDropdown: (props) => <AsyncSelectFilter {...props} searchUrl="fops/search" />,
    },
    {
      title: t('invoices.columns.created_at'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value) => <span>{moment(value).format('DD.MM.Y HH:mm:ss')}</span>,
      filterDropdown: (props) => <DateRangeFilter {...props} />,
      sorter: true,
    },
    {
      title: t('invoices.columns.paid_at'),
      dataIndex: 'paid_at',
      key: 'paid_at',
      render: (value) => <span>{value ? moment(value).format('DD.MM.Y HH:mm:ss') : '-'}</span>,
      filterDropdown: (props) => <DateRangeFilter {...props} />,
      sorter: true,
    },
    {
      title: t('invoices.fiscalization'),
      dataIndex: 'checkbox_receipt',
      key: 'checkbox_receipt',
      render: (value) => (
        <Tag color={value ? 'lime' : 'volcano'}>
          {value ? (
            <span title={moment(value.created_at).format('DD.MM.Y HH:mm:ss')} style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
              Так
              <FeatherIcon icon="clock" size="14" />
            </span>
          ) : (
            'Ні'
          )}
        </Tag>
      ),
      filters: [
        {
          text: 'Так',
          value: 1,
        },
        {
          text: 'Ні',
          value: 0,
        },
      ],
      filterMultiple: false,
    },
    {
      title: t('invoices.columns.checkbox_error_logs_count'),
      dataIndex: 'checkbox_error_logs_count',
      render: (value, row) =>
        value ? (
          <Link to={`/accounting/invoices/${row.id}/checkbox-errors`} target="_blank">
            {t('invoices.columns.checkbox_error_logs_count')}{' '}
            <span style={{ display: 'inline-flex' }}>
              <Badge count={value} />
            </span>
          </Link>
        ) : (
          `${t('invoices.columns.not_errors')}`
        ),
    },
  ]);

  return (
    <>
      <CardToolbox>
        <PageHeader ghost title={t('invoices.list_page_header')} />
      </CardToolbox>

      <Main>
        <Table
          route="accounting/invoices"
          resource="invoices"
          columns={columns}
          permissionNamespace="accounting.invoices"
          defaultSort={{ field: 'created_at', direction: 'descend' }}
          actionOptions={{ isEditable: false, isDeletable: false }}
          additionalActions={(row, setData) => {
            return [
              <CreateOrViewReceiptAction
                id={row.id}
                resource="invoices"
                key={row.id}
                receipt={row.checkbox_receipt}
                onSell={(receipt) => {
                  setData((data) => {
                    const itemIndex = data.findIndex((elem) => elem.id === row.id);
                    const items = [...data];
                    items[itemIndex] = { ...items[itemIndex], checkbox_receipt: receipt };

                    return items;
                  });
                }}
              />,
            ];
          }}
        />
      </Main>
    </>
  );
};

export default withAuthLayout(InvoiceIndex);
