import React, { useRef, useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Space, TimePicker } from 'antd';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const TimeRangeFilter = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [state, setState] = useState({
    start: selectedKeys[0] ? dayjs(selectedKeys[0], 'HH:mm') : null,
    end: selectedKeys[1] ? dayjs(selectedKeys[1], 'HH:mm') : null,
    endOpen: false,
  });

  const disabledStartDate = () => {
    const { start, end } = state;
    if (!start || !end) {
      return false;
    }

    return start.valueOf() > end.valueOf();
  };

  const disabledEndDate = () => {
    const { start, end } = state;
    if (!end || !start) {
      return false;
    }
    return end.valueOf() <= start.valueOf();
  };

  const onChange = (field, value) => {
    setState((prevState) => {
      return {
        ...prevState,
        [field]: value,
      };
    });
  };

  const handleStartOpenChange = (open) => {
    if (!open) {
      onChange('endOpen', true);
    }
  };

  const handleEndOpenChange = (open) => {
    onChange('endOpen', open);
  };

  const apply = () => {
    const { start, end } = state;
    if (start && end) {
      const startDate = start.format('HH:mm');
      const endDate = end.format('HH:mm');

      setSelectedKeys([startDate, endDate]);
      confirm();
    }
  };

  const reset = () => {
    setState({ start: null, end: null, endOpen: false });
    setSelectedKeys([]);
    clearFilters();
    confirm();
  };

  return (
    <div ref={ref} style={{ padding: '10px' }}>
      <div>
        <TimePicker
          disabledDate={disabledStartDate}
          value={state.start}
          placeholder="З"
          onChange={(value) => onChange('start', value)}
          onOpenChange={handleStartOpenChange}
          style={{ margin: 5 }}
          format="HH:mm"
          minuteStep={10}
        />

        <TimePicker
          disabledDate={disabledEndDate}
          value={state.end}
          placeholder="По"
          onChange={(value) => onChange('end', value)}
          open={state.endOpen}
          onOpenChange={handleEndOpenChange}
          style={{ margin: 5 }}
          format="HH:mm"
          minuteStep={10}
        />
      </div>
      <Space>
        <Button type="primary" onClick={apply}>
          {t('apply')}
        </Button>
        <Button type="link" onClick={reset}>
          {t('clear')}
        </Button>
      </Space>
    </div>
  );
};

TimeRangeFilter.propTypes = {
  setSelectedKeys: PropTypes.func,
  selectedKeys: PropTypes.array,
  confirm: PropTypes.func,
  clearFilters: PropTypes.func,
};

export default TimeRangeFilter;
