import { useTranslation } from 'react-i18next';
import { Checkbox, Col, Form, Row } from 'antd';
import React from 'react';
import FormSelectAsync from '../../../../form/FormSelectAsync';
import withAuthLayout from '@/layouts/withAuthLayout';
import useFormValues from '@/hooks/useFormValues';
import { PageHeader } from '@/components/page-headers/page-headers';
import { Main } from '@/container/styled';
import { Cards } from '@/components/cards/frame/cards-frame';
import { ActionsWrapper, FormWrapper } from '@/container/pages/style';
import FormInput from '@/form/FormInput';
import FormTextArea from '@/form/FormTextArea';
import FormSubmit from '@/form/FormSubmit';

const BinotelAutoReplyForm = () => {
  const { t } = useTranslation();
  const { form, loading, handleSubmit, errors } = useFormValues('binotel-auto-reply-settings');

  return (
    <>
      <PageHeader ghost title={t('binotel_auto_reply_settings.list_page_header')} />
      <Main>
        <Cards headless>
          <Row justify="center">
            <Col xl={12} md={16} xs={24}>
              <FormWrapper>
                <Form style={{ width: '100%' }} form={form} layout="vertical" onFinish={handleSubmit} initialValues={{ is_active: true }}>
                  <FormInput name="name" label={t('binotel_auto_reply_settings.fields.name')} required error={errors.name} />
                  <FormInput name="pbx_number" label={t('binotel_auto_reply_settings.fields.pbx_number')} required error={errors.pbx_number} />
                  <FormSelectAsync
                    resource="binotel-auto-reply-settings/senders"
                    name="sender"
                    required
                    label={t('binotel_auto_reply_settings.fields.sender')}
                    error={errors.sender}
                  />
                  <FormTextArea
                    name="reply_message"
                    label={t('binotel_auto_reply_settings.fields.reply_message')}
                    showCount
                    required
                    error={errors.reply_message}
                  />
                  <Form.Item name="is_active" valuePropName="checked">
                    <Checkbox>{t('binotel_auto_reply_settings.fields.is_active')}</Checkbox>
                  </Form.Item>
                  <ActionsWrapper>
                    <FormSubmit loading={loading} />
                  </ActionsWrapper>
                </Form>
              </FormWrapper>
            </Col>
          </Row>
        </Cards>
      </Main>
    </>
  );
};

export default withAuthLayout(BinotelAutoReplyForm);
