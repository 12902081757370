import { JsonParam, NumberParam, useQueryParam } from 'use-query-params';
import { omit } from 'lodash';

export default function useUrlParams() {
  const [filtersParam, setFiltersParam] = useQueryParam('filters', JsonParam);
  const [pageParam, setPageParam] = useQueryParam('page', NumberParam);
  const [sortParam, setSortParam] = useQueryParam('sort', JsonParam);

  const setFilter = (key, value) => {
    if (value === null || value === undefined) {
      setFiltersParam(omit(filtersParam, [key]));
    } else {
      setFiltersParam({ ...filtersParam, [key]: value });
    }
  };

  const setFilterOnly = (key, value) => {
    if (value === null || value === undefined) {
      setFiltersParam(undefined);
    } else {
      setFiltersParam({ [key]: value });
    }
  };

  const resetFilters = () => {
    setFiltersParam(undefined);
  };

  const setSort = (field, order, updateType = 'pushIn') => {
    if (!order) {
      setSortParam(undefined, updateType);
    } else {
      setSortParam({ field, order }, updateType);
    }
  };

  const setPage = (number) => {
    setPageParam(number);
  };

  const reset = () => {
    setPageParam(undefined);
    setFiltersParam(undefined);
    setSortParam(undefined);
  };

  return {
    filters: filtersParam,
    setFilter,
    setFilterOnly,
    resetFilters,
    page: pageParam,
    sort: sortParam,
    setSort,
    setPage,
    reset,
  };
}
