import styled from 'styled-components';

const ItemsStyled = styled.table`
  width: 100%;
  margin-bottom: 30px;

  &,
  th,
  td {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-collapse: collapse;
    padding: 20px;
  }

  thead tr {
    background: rgba(0, 0, 0, 0.05);
  }
`;

export { ItemsStyled };
