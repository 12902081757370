import { Row, Col, PageHeader } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Main } from '../../styled';
import withAuthLayout from '@/layouts/withAuthLayout';
import StatisticCard from '@/container/pages/statistics/StatisticCard';

function StatisticsPage() {
  const { t } = useTranslation();
  return (
    <div>
      <PageHeader ghost title={t('statistics.list_page_header')} />

      <Main>
        <Row gutter={[16, 16]} type="flex">
          <Col xs={24} sm={24} md={{ span: 8, offset: 4 }}>
            <Link to="/statistics/conversion">
              <StatisticCard title={t('statistics.card_title.conversion_and_analytics')} icon="trending-up" />
            </Link>
          </Col>
          <Col xs={24} sm={24} md={{ span: 8, offset: -4 }}>
            <Link to="/statistics/site_statistic">
              <StatisticCard title={t('statistics.card_title.site_statistic')} icon="pie-chart" />
            </Link>
          </Col>

          <Col xs={24} sm={24} md={{ span: 8, offset: 4 }}>
            <StatisticCard title={t('statistics.card_title.conversion_and_analytics')} />
          </Col>
          <Col xs={24} sm={24} md={{ span: 8, offset: -4 }}>
            <StatisticCard title={t('statistics.card_title.conversion_and_analytics')} />
          </Col>
        </Row>
      </Main>
    </div>
  );
}

export default withAuthLayout(StatisticsPage);
