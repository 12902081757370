import { Button, Col, DatePicker, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useDateRangeFilter from '@/components/statistic/conversion/hooks/useDateRangeFilter';

const { RangePicker } = DatePicker;

const DateRangeFilter = ({ onConfirm }) => {
  const { range, handleRangeChange, clearRange } = useDateRangeFilter();
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 16]} type="flex" style={{ marginBottom: '20px' }}>
      <Col>
        <RangePicker value={[moment(range[0]), moment(range[1])]} size="small" onChange={handleRangeChange} onClear={clearRange} />
      </Col>
      <Col>
        <Button type="primary" icon={<SearchOutlined />} onClick={() => onConfirm(range[0], range[1])} disabled={range.length === 0}>
          {t('search')}
        </Button>
      </Col>
    </Row>
  );
};

DateRangeFilter.propTypes = {
  onConfirm: PropTypes.func.isRequired,
};

export default DateRangeFilter;
