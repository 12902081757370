import React, { useState } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import FeatherIcon from 'feather-icons-react';
import NeedCallModal from '@/components/Order/NeedCallModal';

const CallButton = ({ clientData, onSuccess }) => {
  const [showCallModal, setShowCallModal] = useState(false);

  return (
    <>
      {showCallModal && <NeedCallModal clientData={clientData} onClose={() => setShowCallModal(false)} onSuccess={onSuccess} />}

      <Button style={{ cursor: 'pointer', boxShadow: 'none' }} onClick={() => setShowCallModal(true)}>
        <FeatherIcon icon="phone" size={16} />
      </Button>
    </>
  );
};

CallButton.propTypes = {
  clientData: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default CallButton;
