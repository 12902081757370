import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row, Table as AntdTable } from 'antd';
import { useTranslation } from 'react-i18next';
import { defaultsDeep, map } from 'lodash';
import { CloseCircleOutlined, SettingFilled, SyncOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import ActionRow from './ActionsRow';
import DraggableColumns from './DraggableColumns';
import { UserTableStyleWrapper } from '@/container/pages/style';
import { TableWrapper, TopToolBox } from '@/container/styled';
import usePaginator from '@/hooks/usePaginator';
import { useAuth } from '@/helpers/userContext';
import { Cards } from '@/components/cards/frame/cards-frame';
import ApiClient from '@/helpers/apiClient/ApiClient';
import useUrlParams from '@/hooks/useUrlParams';

const Table = ({
  route,
  resource,
  columns = [],
  permissionNamespace,
  rowKey = 'id',
  additionalFilters,
  additionalActions,
  defaultSort = null,
  actionOptions = {
    isEditable: true,
    isViewable: true,
    isDeletable: true,
  },
}) => {
  actionOptions = defaultsDeep(actionOptions, {
    isEditable: true,
    isViewable: true,
    isDeletable: true,
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { checkPerm } = useAuth();
  const { data, setData, total, fetch, loading, setPerPage } = usePaginator(resource);
  const { filters, setFilter, reset, sort, setSort, page, setPage } = useUrlParams();
  const [columnsData, setColumnsData] = useState(columns);
  const [columnsConfig, setColumnsConfig] = useState([]);
  const [init, setInit] = useState(false);
  const [relation, setRelation] = useState(null);
  const [openColumnSettings, setOpenColumnSetting] = useState(false);
  const [focused, setFocused] = useState(null);

  useEffect(() => {
    if (defaultSort) {
      setSort(defaultSort.field, defaultSort.direction, 'replaceIn');
    }

    ApiClient.call('get', `${resource}/meta`)
      .data((res) => {
        setRelation(res.relation);
        const config = res.columns;
        setColumnsConfig(config);
        setColumnsData((value) => {
          if (config.length === 0) {
            return value;
          }

          return value.filter((e) => config.indexOf(e.key) > -1).sort((a, b) => config.indexOf(a.key) - config.indexOf(b.key));
        });
      })
      .finally(() => {
        setInit(true);
      });
  }, [resource]);

  useEffect(() => {
    if (init) {
      fetch();
    }
  }, [fetch, init]);

  useEffect(() => {
    const { isEditable, isDeletable } = actionOptions;
    const hasActions = isEditable || isDeletable;

    if ((hasActions || additionalActions) && init) {
      setColumnsData((value) => {
        return [
          ...value,
          {
            title: 'Дії',
            key: 'action',
            dataIndex: 'action',
            fixed: 'right',
            width: 70,
            render: (_, row) => (
              <ActionRow
                canEdit={checkPerm(`${permissionNamespace}.edit`)}
                route={route}
                resource={resource}
                id={row[rowKey]}
                onAcceptDelete={fetch}
                options={actionOptions}
                additionalActions={additionalActions ? () => additionalActions(row, setData) : null}
              />
            ),
          },
        ];
      });
    }
  }, [route, resource, permissionNamespace, init, actionOptions.isEditable, actionOptions.isDeletable]);

  useEffect(() => {
    setColumnsData((value) => {
      return value.map((elem) => {
        return {
          ...elem,
          filteredValue: filters ? filters[elem.key] : null,
        };
      });
    });
  }, [filters]);

  useEffect(() => {
    setColumnsData((value) => {
      return value.map((elem) => {
        let order = null;
        if (sort?.field === elem.key) {
          order = sort?.order;
        }
        return {
          ...elem,
          sortOrder: order,
        };
      });
    });
  }, [sort]);

  const onSaveColumns = (newColumns) => {
    ApiClient.call('post', 'auth/save-columns', {}, { relation, columns: newColumns }).then(() => {
      setOpenColumnSetting(false);
      navigate(0);
    });
  };

  const handleTableChange = (pagination, filtersValue, sorter) => {
    map(filtersValue, (filter, field) => {
      setFilter(field, filter);
    });

    setSort(sorter.columnKey, sorter.order);
  };

  const noFilters = !filters && !sort;

  return (
    <Cards headless>
      {openColumnSettings && (
        <DraggableColumns
          columns={columns}
          userColumns={columnsConfig}
          onClose={() => setOpenColumnSetting(false)}
          onSave={onSaveColumns}
          relation={relation}
        />
      )}
      <Row gutter={15}>
        <Col span={24}>
          <TopToolBox>
            <Row style={{ justifyContent: additionalFilters ? 'space-between' : 'end' }}>
              {additionalFilters && <Col lg={15}>{additionalFilters(filters)}</Col>}
              <Col lg={3} xs={24}>
                <div className="table-toolbox-actions">
                  <Button size="small" type="secondary" icon={<SyncOutlined />} onClick={fetch} loading={loading}>
                    {t('table.refresh')}
                  </Button>
                  <Button size="small" type="secondary" icon={<CloseCircleOutlined />} onClick={reset} disabled={noFilters} loading={loading}>
                    {t('table.clear_filters')}
                  </Button>
                  <Button size="small" type="primary" onClick={() => setOpenColumnSetting(true)}>
                    <SettingFilled />
                  </Button>
                </div>
              </Col>
            </Row>
          </TopToolBox>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col span={24}>
          <UserTableStyleWrapper>
            <TableWrapper className="table-responsive">
              <AntdTable
                onChange={handleTableChange}
                loading={loading}
                dataSource={data}
                columns={columnsData}
                pagination={{
                  defaultPageSize: 20,
                  total,
                  showSizeChanger: true,
                  showTotal: () => t('table.total', { count: total }),
                  onShowSizeChange: (_, perPage) => setPerPage(perPage),
                  onChange: (number) => setPage(number),
                  current: page,
                }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: () =>
                      setFocused((old) => {
                        return old === rowIndex ? null : rowIndex;
                      }),
                  };
                }}
                rowClassName={(record, index) => (index === focused ? 'table-row-green' : '')}
                rowKey={rowKey}
              />
            </TableWrapper>
          </UserTableStyleWrapper>
        </Col>
      </Row>
    </Cards>
  );
};

Table.propTypes = {
  route: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  permissionNamespace: PropTypes.string,
  additionalFilters: PropTypes.func,
  actionOptions: PropTypes.object,
  additionalActions: PropTypes.func,
  defaultSort: PropTypes.object,
  rowKey: PropTypes.string,
};

export default Table;
