export const PRODUCT_TYPE_FLOWER = 1;
export const PRODUCT_TYPE_BOUQUET = 2;
export const PRODUCT_TYPE_TOY = 3;
export const PRODUCT_TYPE_PLANT = 4;
export const PRODUCT_TYPE_SWEET = 5;
export const PRODUCT_TYPE_POSTCARD = 6;

export const PRODUCT_ITEM_TYPE_FLOWER = 1;
export const PRODUCT_ITEM_TYPE_PACK = 2;
export const PRODUCT_ITEM_TYPE_BOX = 3;
export const PRODUCT_ITEM_TYPE_TAPE = 4;

export const BILLING_GOOD_TYPE_FLOWER = 'GOODS_FLOWERS';
export const BILLING_GOOD_TYPE_BOX = 'BOX';
export const BILLING_GOOD_TYPE_PAPER = 'PAPER';
export const BILLING_GOOD_TYPE_TAPE = 'TAPE';
export const BILLING_GOOD_TYPE_POSTCARD = 'POSTCARD';
export const BILLING_GOOD_TYPE_TOY = 'GOODS_TOY';
export const BILLING_GOOD_TYPE_PLANT = 'GOODS_FLOWERPOT';
export const BILLING_GOOD_TYPE_SWEET = 'GOODS_SWEETS';

export const productTypeToBillingGoodType = {
  [PRODUCT_TYPE_FLOWER]: BILLING_GOOD_TYPE_FLOWER,
  [PRODUCT_TYPE_TOY]: BILLING_GOOD_TYPE_TOY,
  [PRODUCT_TYPE_PLANT]: BILLING_GOOD_TYPE_PLANT,
  [PRODUCT_TYPE_SWEET]: BILLING_GOOD_TYPE_SWEET,
  [PRODUCT_TYPE_POSTCARD]: BILLING_GOOD_TYPE_POSTCARD,
};

export const productItemTypeToBillingGoodType = {
  [PRODUCT_ITEM_TYPE_FLOWER]: BILLING_GOOD_TYPE_FLOWER,
  [PRODUCT_ITEM_TYPE_PACK]: BILLING_GOOD_TYPE_PAPER,
  [PRODUCT_ITEM_TYPE_BOX]: BILLING_GOOD_TYPE_BOX,
  [PRODUCT_ITEM_TYPE_TAPE]: BILLING_GOOD_TYPE_TAPE,
};
