import React from 'react';
import { Button, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import AsyncSelect from '../AsyncSelect';

const AsyncSelectFilter = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, searchUrl }) => {
  return (
    <div style={{ padding: '15px 25px' }}>
      <AsyncSelect resource={searchUrl} multiple value={selectedKeys.length ? selectedKeys : null} onChange={(value) => setSelectedKeys(value)} />
      <Space style={{ marginTop: 10 }} size="large">
        <Button type="primary" onClick={confirm} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
          Пошук
        </Button>
        <Button type="link" size="small" onClick={clearFilters}>
          Очистити
        </Button>
      </Space>
    </div>
  );
};

AsyncSelectFilter.propTypes = {
  setSelectedKeys: PropTypes.func,
  confirm: PropTypes.func,
  clearFilters: PropTypes.func,
  searchUrl: PropTypes.string,
  selectedKeys: PropTypes.array,
};

export default AsyncSelectFilter;
