import styled from 'styled-components';
import { Card } from 'antd';

const StyledCard = styled(Card)`
  .card-content {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: red;
  }
`;

export default StyledCard;
