import React, { useState } from 'react';
import { Row, Col, Form, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { ChangePasswordWrapper } from './style';
import { Cards } from '@/components/cards/frame/cards-frame';
import { BasicFormWrapper } from '@/container/styled';
import Heading from '@/components/heading/heading';
import FormInput from '@/form/FormInput';
import ApiClient from '@/helpers/apiClient/ApiClient';

function Password() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState({});

  const handleSubmit = (values) => {
    setErrors({});
    ApiClient.call('post', 'auth/update-password', {}, values)
      .data(() => {
        message.success('Успішно');
      })
      .validation((info, err) => {
        setErrors(err);
      });
  };

  return (
    <ChangePasswordWrapper>
      <Cards
        title={
          <div className="setting-card-title">
            <Heading as="h4">{t('account.password.heading')}</Heading>
          </div>
        }
      >
        <Row justify="center">
          <Col lg={12} sm={20} xs={24}>
            <BasicFormWrapper>
              <Form form={form} name="changePassword" onFinish={handleSubmit}>
                <FormInput
                  type="password"
                  name="old_password"
                  label={t('account.password.fields.old_password')}
                  error={errors.old_password}
                  required
                  autoComplete="new-password"
                />
                <FormInput
                  type="password"
                  name="password"
                  label={t('account.password.fields.password')}
                  error={errors.password}
                  required
                  autoComplete="new-password"
                />
                <p className="input-message">{t('account.password.min_password_length')}</p>
                <FormInput
                  type="password"
                  name="password_confirmation"
                  label={t('account.password.fields.password_confirmation')}
                  error={errors.password_confirmation}
                  required
                  autoComplete="new-password"
                />
                <Form.Item>
                  <div className="setting-form-actions">
                    <Button htmlType="submit" type="primary">
                      {t('save')}
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </BasicFormWrapper>
          </Col>
        </Row>
      </Cards>
    </ChangePasswordWrapper>
  );
}

export default Password;
