import { Navigate, useLocation } from 'react-router-dom';
import React from 'react';
import UnauthorizedError from '../helpers/UnauthorizedError';
import { useAuth } from '@/helpers/userContext';
import { useError } from '@/helpers/errorContext';

export function withAuth(Component, permission) {
  return (props) => {
    const auth = useAuth();
    const location = useLocation();
    const { setError } = useError();

    if (!auth.user) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (permission && !auth.checkPerm(permission)) {
      setError(new UnauthorizedError(403));
    }

    return <Component {...props} />;
  };
}
