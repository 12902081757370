import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ApiClient from '@/helpers/apiClient/ApiClient';
import StyledCard from '@/components/smsBalance/style';

const SmsBalance = () => {
  const [balance, setSmsBalance] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    ApiClient.call('get', '/data')
      .data((data) => {
        setSmsBalance(data.sms_balance);
      })
      .catch((error) => {
        setErrorMessage(error.data.message);
      });
  }, []);

  return (
    <StyledCard title={t('sms_balance')}>
      {!errorMessage.length ? (
        <p style={{ color: parseInt(balance) < 200 ? 'red' : 'black' }}>{parseFloat(balance)} грн.</p>
      ) : (
        <p className="card-content">{errorMessage}</p>
      )}
    </StyledCard>
  );
};

export default SmsBalance;
