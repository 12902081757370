import React, { useEffect, useState } from 'react';
import { Row, Col, Form, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { Cards } from '@/components/cards/frame/cards-frame';
import { Button } from '@/components/buttons/buttons';
import { BasicFormWrapper } from '@/container/styled';
import Heading from '@/components/heading/heading';
import FormInput from '@/form/FormInput';
import PhoneInput from '@/widgets/PhoneInput';
import ApiClient from '@/helpers/apiClient/ApiClient';

function Profile() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState({});

  useEffect(() => {
    ApiClient.call('get', 'auth/personal-info').data(({ data }) => {
      form.resetFields();
      form.setFieldsValue(data);
    });
  }, []);

  const handleSubmit = (values) => {
    setErrors({});
    ApiClient.call('post', 'auth/update-personal-info', {}, values)
      .data(({ data }) => {
        form.resetFields();
        form.setFieldsValue(data);
        message.success(t('success_message'));
      })
      .validation((info, err) => {
        setErrors(err);
      });
  };

  const handleCancel = (e) => {
    e.preventDefault();
    form.resetFields();
  };

  return (
    <Cards
      title={
        <div className="setting-card-title">
          <Heading as="h4">{t('account.profile.personal_info')}</Heading>
          <span>{t('account.profile.profile_settings')}</span>
        </div>
      }
    >
      <Row justify="center">
        <Col xxl={12} md={18} xs={24}>
          <BasicFormWrapper>
            <Form name="editProfile" form={form} onFinish={handleSubmit}>
              <Row gutter={24}>
                <Col span={12}>
                  <FormInput name="first_name" label={t('account.profile.fields.first_name')} required error={errors.first_name} />
                </Col>
                <Col span={12}>
                  <FormInput name="last_name" label={t('account.profile.fields.last_name')} required error={errors.last_name} />
                </Col>
                <Col span={12}>
                  <FormInput name="email" label={t('account.profile.fields.email')} required error={errors.email} />
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="phone_number"
                    label={t('account.profile.fields.phone_number')}
                    validateStatus={errors.phone_number ? 'error' : null}
                    help={errors.phone_number}
                  >
                    <PhoneInput />
                  </Form.Item>
                </Col>
              </Row>
              <div className="setting-form-actions">
                <Button size="default" htmlType="submit" type="primary">
                  {t('save')}
                </Button>
                &nbsp; &nbsp;
                <Button size="default" onClick={handleCancel} type="light">
                  {t('cancel')}
                </Button>
              </div>
            </Form>
          </BasicFormWrapper>
        </Col>
      </Row>
    </Cards>
  );
}

export default Profile;
