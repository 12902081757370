import React from 'react';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import { Tag } from '@/components/tags/tags';
import { PRODUCT_ITEM_TYPE_BOX, PRODUCT_ITEM_TYPE_FLOWER, PRODUCT_ITEM_TYPE_PACK, PRODUCT_ITEM_TYPE_TAPE } from '@/constants/products';

const typeColors = {
  [PRODUCT_ITEM_TYPE_FLOWER]: 'magenta',
  [PRODUCT_ITEM_TYPE_PACK]: 'gold',
  [PRODUCT_ITEM_TYPE_TAPE]: 'blue',
  [PRODUCT_ITEM_TYPE_BOX]: 'purple',
};

const ProductItemElem = ({ item, onEdit, onDelete, invalid }) => {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <span>
            <Tag color={typeColors[item.type]}>{t(`products.product_item_types.${item.type}`)}</Tag>
          </span>
          <span>{item.name}</span>

          {invalid && (
            <span className="error">
              <FeatherIcon icon="info" /> <span>{t('validation_error')}</span>
            </span>
          )}
        </div>
        <span>
          {item.quantity}шт <EditOutlined onClick={onEdit} /> <CloseOutlined onClick={onDelete} />
        </span>
      </div>
    </>
  );
};

ProductItemElem.propTypes = {
  item: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  invalid: PropTypes.any,
};

export default ProductItemElem;
