import { useCallback, useState } from 'react';
import { Card, Col, Row } from 'antd';
import PropTypes from 'prop-types';
import { sumBy } from 'lodash';
import ComboChart from '@/components/statistic/conversion/ComboChart';
import ApiClient from '@/helpers/apiClient/ApiClient';
import PieChart from '@/components/statistic/conversion/PieChart';
import { StatisticChartsTitleWrap } from '@/components/statistic/conversion/styled';
import Spinner from '@/widgets/Spinner';
import DateRangeFilter from '@/components/statistic/conversion/DateRangeFilter';
import useStatisticChartsData from '@/components/statistic/conversion/hooks/useStatisticChartsData';
import { createPieChartData } from '@/components/utilities/statistic/utilities';
import useFetchStatistics from '@/components/statistic/conversion/hooks/useFetchStatistics';
import { StatisticsRecord } from '@/components/statistic/conversion/StatisticsRecord';

function StatisticCharts({ city }) {
  const [loading, setLoading] = useState(false);
  const [receiptsData, setReceiptsData] = useState([]);
  const [revenueData, setRevenueData] = useState({ offline: [], online: [], total: [], summary: 0 });
  const { chartsData, PeriodFilter, StoreFilter } = useStatisticChartsData(receiptsData);

  const fetchReceipts = useCallback(
    (start, end) => {
      setLoading(true);
      ApiClient.call('get', '/statistic/visitors-count', {
        city_id: city.id,
        start_date: start,
        end_date: end,
      })
        .data(({ data }) => {
          setReceiptsData(StatisticsRecord.storeFormat(data));
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [city.id],
  );

  const fetchRevenue = useCallback(
    (start, end) => {
      setLoading(true);
      ApiClient.call('get', '/statistic/revenue', { city_id: city.id, start_date: start, end_date: end })
        .data(({ data }) => {
          setRevenueData({
            offline: createPieChartData('offline', data),
            online: createPieChartData('online', data),
            total: createPieChartData('total', data),
            summary: sumBy(data, 'total'),
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [city.id],
  );

  const { fetchCallback } = useFetchStatistics(fetchReceipts, fetchRevenue);

  return (
    <Col xs={24} sm={24}>
      <Card>
        <StatisticChartsTitleWrap>
          <Row className="statistic-chart-title_container">
            <h2 style={{ margin: 0 }}>{city.name}</h2>
            <PeriodFilter />
          </Row>
        </StatisticChartsTitleWrap>
        <DateRangeFilter onConfirm={fetchCallback} />
        <Row gutter={[16, 48]}>
          <Col xs={24} sm={24} md={24}>
            {loading ? <Spinner /> : <ComboChart length={receiptsData.length} data={chartsData} />}
            <StoreFilter />
          </Col>
          <Col xs={24} sm={24} md={24}>
            {loading ? (
              <Spinner />
            ) : (
              <PieChart offlineData={revenueData.offline} onlineData={revenueData.online} totalData={revenueData.total} summary={revenueData.summary} />
            )}
          </Col>
        </Row>
      </Card>
    </Col>
  );
}

StatisticCharts.propTypes = {
  city: PropTypes.object.isRequired,
};

export default StatisticCharts;
