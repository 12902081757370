import { Col, Form, InputNumber, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import withAuthLayout from '@/layouts/withAuthLayout';
import { PageHeader } from '@/components/page-headers/page-headers';
import { Main } from '@/container/styled';
import { Cards } from '@/components/cards/frame/cards-frame';
import { ActionsWrapper, FormWrapper } from '@/container/pages/style';
import FormInput from '@/form/FormInput';
import FormSubmit from '@/form/FormSubmit';
import useFormValues from '@/hooks/useFormValues';
import FormSelectAsync from '@/form/FormSelectAsync';

import 'react-quill/dist/quill.snow.css';

function FaqsElementForm() {
  const { t } = useTranslation();
  const { id, form, loading, handleSubmit, errors } = useFormValues('main-page/additional-faqs');

  return (
    <>
      <PageHeader ghost title={id ? t('faqs.edit_page_header') : t('faqs.create_page_header')} />
      <Main>
        <Cards headless>
          <Row justify="center">
            <Col xs={24} md={20} lg={16} xl={12}>
              <FormWrapper>
                <Form style={{ width: '100%' }} form={form} layout="vertical" onFinish={handleSubmit}>
                  <FormSelectAsync name="city_id" resource="cities/search" label={t('main_page_carousel.fields.city_id')} error={errors.city_id} required />
                  <FormInput name="title" label={t('faqs.fields.title')} error={errors.title} required />

                  <Form.Item name="placement" label={t('faqs.fields.placement')} validateStatus={errors.placement ? 'error' : null} help={errors.placement}>
                    <InputNumber style={{ width: '100%' }} min={1} />
                  </Form.Item>

                  <Form.Item name="html" label={t('faqs.fields.html')} validateStatus={errors.html ? 'error' : null} help={errors.html}>
                    <ReactQuill theme="snow" />
                  </Form.Item>

                  <ActionsWrapper>
                    <FormSubmit loading={loading} />
                  </ActionsWrapper>
                </Form>
              </FormWrapper>
            </Col>
          </Row>
        </Cards>
      </Main>
    </>
  );
}

export default withAuthLayout(FaqsElementForm);
