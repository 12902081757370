import { useCallback, useState } from 'react';
import { concat, isEqual } from 'lodash';
import usePrevious from './usePrevious';
import useUrlParams from './useUrlParams';
import ApiClient from '../helpers/apiClient/ApiClient';
import UnauthorizedError from '../helpers/UnauthorizedError';
import ServerError from '../helpers/ServerError';
import { useError } from '@/helpers/errorContext';

const DEFAULT_PER_PAGE = 20;

export default function usePaginator(resource, infinite = false) {
  const { filters, page, sort, setPage } = useUrlParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastPage, setLastPage] = useState(null);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [hasMore, setHasMore] = useState(false);
  const { setError: setGlobalError } = useError();
  const prevFilters = usePrevious(filters);

  const fetch = useCallback(() => {
    setLoading(true);
    const params = {
      page,
      per_page: perPage,
      sort,
      filters,
    };

    ApiClient.call('get', resource, params)
      .data((res) => {
        const { meta } = res;
        setTotal(meta.total);
        setLastPage(meta.last_page);
        setHasMore(Boolean(meta.current_page !== meta.last_page));
        if (infinite && isEqual(filters, prevFilters)) {
          setData((value) => concat(value, res.data));
        } else {
          setData(res.data);
        }
      })
      .code(403, () => {
        setGlobalError(new UnauthorizedError('403'));
      })
      .code(500, () => {
        setGlobalError(new ServerError('500'));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page, resource, perPage, sort, filters, infinite]);

  const next = () => {
    if (page >= lastPage) {
      setPage(lastPage);
    } else {
      // eslint-disable-next-line no-shadow
      setPage((currentPage) => currentPage + 1);
    }
  };

  const prev = () => {
    if (page >= 2) {
      // eslint-disable-next-line no-shadow
      setPage((currentPage) => currentPage - 1);
    } else {
      setPage(1);
    }
  };

  return {
    data,
    setData,
    total,
    fetch,
    hasMore,
    loading,
    setPerPage,
    next: page < lastPage ? next : () => {},
    prev: page > 1 ? prev : () => {},
  };
}
