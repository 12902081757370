import React, { useEffect, useState } from 'react';
import { Checkbox, Col, Divider, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { isArray, map } from 'lodash';
import { Main } from '../../../styled';
import { ActionsWrapper } from '../../style';
import FormInput from '@/form/FormInput';
import withAuthLayout from '@/layouts/withAuthLayout';
import { PageHeader } from '@/components/page-headers/page-headers';
import ApiClient from '@/helpers/apiClient/ApiClient';
import useFormValues from '@/hooks/useFormValues';
import FormSubmit from '@/form/FormSubmit';
import TabForm from '@/widgets/TabForm';

const Permissions = ({ value = [], onChange, all }) => {
  const triggerChange = (changedValue) => {
    onChange?.(changedValue);
  };

  const handleCheckbox = (id) => {
    const data = new Set(value);
    if (data.has(id)) {
      data.delete(id);
    } else {
      data.add(id);
    }

    triggerChange(Array.from(data));
  };

  return map(all, (elem, group) => {
    return (
      <div key={group}>
        <Divider orientation="left" plain>
          <h3>{group}</h3>
        </Divider>
        <Row justify="start">
          {map(elem, (items, sub) => {
            return (
              <Col xl={10} md={16} xs={24} key={sub}>
                {isArray(items) && <h4>{sub}</h4>}
                {isArray(items) ? (
                  items.map((item) => {
                    return (
                      <Checkbox key={item.id} checked={value?.includes(item.id)} onChange={() => handleCheckbox(item.id)} style={{ width: '100%' }}>
                        {item.name}
                      </Checkbox>
                    );
                  })
                ) : (
                  <Checkbox key={items.id} checked={value?.includes(items.id)} onChange={() => handleCheckbox(items.id)}>
                    {items.name}
                  </Checkbox>
                )}
              </Col>
            );
          })}
        </Row>
      </div>
    );
  });
};

const RoleForm = () => {
  const { t } = useTranslation();
  const { form, handleSubmit, loading, errors } = useFormValues('roles');
  const [accesses, setAccesses] = useState({});

  useEffect(() => {
    ApiClient.call('get', 'roles/permissions-list').data(setAccesses);
  }, []);

  return (
    <>
      <PageHeader ghost title={t('role.list_page_header')} />
      <Main>
        <Form style={{ width: '100%' }} form={form} name="info" layout="vertical" onFinish={handleSubmit}>
          <TabForm
            tabs={[
              {
                key: 'main',
                name: 'Основне',
                component: (
                  <>
                    <FormInput name="name" error={errors.name} label={t('role.name')} placeholder={t('role.name')} />
                  </>
                ),
              },
              {
                key: 'permissions',
                name: 'Привілеї',
                component: (
                  <Form.Item name="permissions" noStyle>
                    <Permissions all={accesses.permissions} />
                  </Form.Item>
                ),
              },
            ]}
          />
          <ActionsWrapper>
            <FormSubmit loading={loading} />
          </ActionsWrapper>
        </Form>
      </Main>
    </>
  );
};

export default withAuthLayout(RoleForm);
