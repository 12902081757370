import PropTypes from 'prop-types';
import { Empty, Spin, Table } from 'antd';
import { useEffect, useState } from 'react';
import { map } from 'lodash';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import priceFormat from '@/helpers/priceFormat';
import ApiClient from '@/helpers/apiClient/ApiClient';

const expandedRowRenderItems = (record) => {
  const columns = [
    {
      title: 'Позиція',
      dataIndex: 'item_name',
      key: 'item_name',
      align: 'left',
    },
    {
      title: 'Ціна за шт',
      dataIndex: 'single_price',
      key: 'single_price',
      render: (value) => <span className={classNames(value === 0 && 'text-line-through')}>{priceFormat(value)}</span>,
      align: 'left',
    },
    {
      title: 'Кількість шт',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'left',
    },
    {
      title: 'Залишки',
      dataIndex: 'remains',
      key: 'remains',
      align: 'left',
    },
    {
      title: 'Наявність',
      dataIndex: 'in_stock',
      key: 'in_stock',
      align: 'left',
      render: (value) => <span>{value ? 'Так' : 'Ні'}</span>,
    },
    {
      title: 'Тотал',
      dataIndex: 'total',
      key: 'total',
      render: (value, record) => <span className={classNames(!record.in_stock && 'text-line-through')}>{priceFormat(value)}</span>,
      align: 'left',
    },
  ];

  return (
    <Table
      columns={columns}
      rowClassName={(record) => (record.in_stock ? 'bg-success' : 'bg-danger')}
      dataSource={record.items.map((e, i) => {
        return { ...e, key: i };
      })}
      pagination={false}
    />
  );
};

const expandedRowRenderStorages = (record) => {
  const columns = [
    {
      title: 'Назва Торгової Точки',
      dataIndex: 'storage_name',
      key: 'storage_name',
      align: 'left',
      render: (value, record) => <span>{record.is_primary ? <strong>{value} (Головний)</strong> : value}</span>,
    },
    {
      title: 'В наявності',
      dataIndex: 'in_stock',
      key: 'in_stock',
      render: (value) => <span>{value ? 'Так' : 'Ні'}</span>,
      align: 'left',
    },
    {
      title: 'Ціна',
      dataIndex: 'total',
      key: 'total',
      render: (value, record) => <span className={classNames(!record.in_stock && 'text-line-through')}>{priceFormat(value)}</span>,
      align: 'left',
    },
  ];

  return (
    <Table
      columns={columns}
      rowClassName={(record) => (record.in_stock ? 'bg-success' : 'bg-danger')}
      expandable={{
        expandedRowRender: expandedRowRenderItems,
      }}
      dataSource={record.storages_prices.map((e, i) => {
        return { ...e, key: i };
      })}
      pagination={false}
    />
  );
};

const ProductItemsPrices = ({ items }) => {
  const [prices, setPrices] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    const data = map(items, (e) => {
      return { good_id: e.billing_good_id, name: e.name, characteristic_id: e.billing_good_characteristic_id, quantity: e.quantity };
    });

    ApiClient.call('get', 'products/product-items-prices', { items: data }).then(({ data }) => {
      setPrices(data);
      setLoading(false);
    });
  }, [items]);

  const columns = [
    {
      title: t('products.columns.city_name'),
      dataIndex: 'city_name',
      key: 'city_name',
      align: 'left',
    },
    {
      title: t('products.columns.in_stock'),
      dataIndex: 'in_stock',
      key: 'in_stock',
      render: (value) => <span>{value ? 'Так' : 'Ні'}</span>,
      align: 'left',
    },
    {
      title: t('products.columns.price'),
      dataIndex: 'price',
      key: 'price',
      render: (value, record) => <span className={classNames(!record.in_stock && 'text-line-through')}>{priceFormat(value)}</span>,
      align: 'left',
    },
  ];

  if (loading) {
    return <Spin />;
  }

  if (prices.length === 0) {
    return <Empty />;
  }

  return (
    <div>
      <Table
        columns={columns}
        rowClassName={(record) => (record.in_stock ? 'bg-success' : 'bg-danger')}
        expandable={{
          expandedRowRender: expandedRowRenderStorages,
        }}
        dataSource={prices.map((e, i) => {
          return { ...e, key: i };
        })}
        size="small"
        pagination={false}
      />
    </div>
  );
};

ProductItemsPrices.propTypes = {
  items: PropTypes.object,
};

export default ProductItemsPrices;
