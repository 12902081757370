import PropTypes from 'prop-types';
import NoDataPieMessage from '@/components/statistic/conversion/NoDataPieMessage';

function NoDataWrapper({ noData, children, title }) {
  if (noData) {
    return <NoDataPieMessage title={title} />;
  }

  return children;
}

NoDataWrapper.propTypes = {
  noData: PropTypes.bool,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default NoDataWrapper;
