import { Button } from 'antd';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ApiClient from '@/helpers/apiClient/ApiClient';
import { alertModal } from '@/components/modals/antd-modals';

const ActionRow = ({ route, resource, onAcceptDelete, id, canEdit, options, additionalActions, ...props }) => {
  const { t } = useTranslation();

  const showConfirm = () => {
    alertModal.confirm({
      title: t('modal.accept_delete_message'),
      onOk() {
        ApiClient.call('delete', `${resource}/${id}`).data(() => {
          if (onAcceptDelete) {
            onAcceptDelete();
          }
        });
      },
      onCancel() {},
    });
  };

  return (
    <div className="table-actions" {...props}>
      {canEdit && options.isEditable && (
        <Button className="btn-icon" type="info" shape="circle">
          <Link to={`/${route}/${id}`}>
            <FeatherIcon icon="edit" size={16} />
          </Link>
        </Button>
      )}
      {canEdit && options.isDeletable && (
        <Button className="btn-icon" type="danger" onClick={showConfirm} shape="circle">
          <FeatherIcon icon="trash-2" size={16} />
        </Button>
      )}
      {additionalActions && additionalActions()}
    </div>
  );
};

ActionRow.propTypes = {
  route: PropTypes.string,
  resource: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onAcceptDelete: PropTypes.func,
  canEdit: PropTypes.bool,
  options: PropTypes.object,
  additionalActions: PropTypes.func,
};

export default ActionRow;
