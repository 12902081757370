import { Record } from 'immutable';
import { flatMap, map, sumBy } from 'lodash';
import { groupBy } from 'lodash/collection';

export class StatisticsRecord extends Record({
  id: '',
  name: '',
  data: [],
  receipts_total: null,
  visitors_total: null,
}) {
  // eslint-disable-next-line no-useless-constructor
  constructor(...args) {
    super(...args);
  }

  static storeFormat(data) {
    return data.map((item) => {
      return new StatisticsRecord({
        id: item.store_id,
        name: item.store_name,
        data: item.data,
        receipts_total: item.receipts_total,
        visitors_total: item.visitors_total,
      });
    });
  }

  static cityFormat(data) {
    // eslint-disable-next-line camelcase
    const groupByCityId = groupBy(data, ({ city_id }) => city_id);

    return map(groupByCityId, (cityData) => {
      const flattenedData = flatMap(cityData, 'data');
      const groupedData = groupBy(flattenedData, 'date');

      const transformedData = map(groupedData, (items, date) => ({
        date,
        visitors: sumBy(items, 'visitors'),
        receipts: sumBy(items, 'receipts'),
      }));

      return new StatisticsRecord({
        id: cityData[0].city_id,
        data: transformedData,
        name: cityData[0].city_name,
        receipts_total: sumBy(transformedData, 'receipts'),
        visitors_total: sumBy(transformedData, 'visitors'),
      });
    });
  }
}
