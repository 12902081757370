import axios from 'axios';
import qs from 'qs';
import Method from './Method';

export default class Client {
  constructor(baseURL) {
    this.instance = axios.create({
      baseURL,
    });
    this.onLogout = () => {};
  }

  setHeader(name, value) {
    this.instance.defaults.headers.common[name] = value;
  }

  removeHeader(name) {
    delete this.instance.defaults.headers[name];
  }

  setOnLogout(cb) {
    this.onLogout = cb;
    return this;
  }

  authorize(token) {
    this.setHeader('Authorization', `Bearer ${token}`);
  }

  unauthorize() {
    this.removeHeader('AUTHORIZATION');
  }

  /**
   * @param method
   * @param url
   * @param params
   * @param data
   * @param options
   * @returns {Method}
   */
  call(method, url, params = {}, data = {}, options = {}) {
    return new Method(
      this.instance.request({
        method,
        url,
        params,
        data,
        // eslint-disable-next-line no-shadow
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
        ...options,
      }),
      this,
    );
  }
}
