import React from 'react';
import { get } from 'lodash';
import { Col, Form, Row } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AsyncSelect from '@/widgets/AsyncSelect';
import { Button } from '@/components/buttons/buttons';
import GeoClient from '@/helpers/apiClient/GeoClient';
import { DeliveryPointStyled } from '@/components/City/styled';

const DeliveryPointBlock = ({ index, zone, onChange, onDelete, regionId, errors }) => {
  const { t } = useTranslation();

  return (
    <DeliveryPointStyled>
      <Row gutter={24}>
        <Col md={12}>
          <Form.Item
            validateStatus={get(errors, `delivery_points.${index}.ext_district_id`) ? 'error' : false}
            help={get(errors, `delivery_points.${index}.ext_district_id`)}
            label={t('delivery_points.region')}
          >
            <AsyncSelect
              disabled={!regionId}
              value={zone.ext_district_id}
              params={{ region_id: regionId }}
              resource="districts"
              client={GeoClient}
              onChange={(value, { children }) => {
                onChange('ext_district_id', value);
                onChange('district_name', children);
              }}
            />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            validateStatus={get(errors, `delivery_points.${index}.ext_city_id`) ? 'error' : false}
            help={get(errors, `delivery_points.${index}.ext_city_id`)}
            label={t('delivery_points.city')}
          >
            <AsyncSelect
              disabled={!zone.ext_district_id || !regionId}
              value={zone.ext_city_id}
              params={{ region_id: regionId, district_id: zone.ext_district_id }}
              resource="cities"
              client={GeoClient}
              onChange={(value, { children }) => {
                onChange('ext_city_id', value);
                onChange('city_name', children);
              }}
            />
          </Form.Item>
        </Col>
        <Col md={24}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button type="danger" onClick={onDelete}>
              {t('remove')}
            </Button>
          </div>
        </Col>
      </Row>
    </DeliveryPointStyled>
  );
};

DeliveryPointBlock.propTypes = {
  index: PropTypes.number,
  zone: PropTypes.object,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  errors: PropTypes.any,
  regionId: PropTypes.string.isRequired,
};

export default DeliveryPointBlock;
