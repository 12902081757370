import { useCallback, useState } from 'react';
import { Button, Col, Row } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal } from '@/components/modals/antd-modals';
import ApiClient from '@/helpers/apiClient/ApiClient';
import { NeedCallModalErrorMessage, NeedCallModalWrapperButton } from '@/components/Order/styled';

const NeedCallModal = ({ clientData, onClose, onSuccess }) => {
  const { t } = useTranslation();
  const { id, name, phone } = clientData;

  const [errorMessage, setErrorMessage] = useState(null);

  const successOrder = useCallback(() => {
    ApiClient.call('post', `orders/${id}/called`)
      .data(() => {
        onSuccess();
        onClose();
      })
      .code(400, ({ data: { message } }) => {
        setErrorMessage(message);
      });
  }, [id, onSuccess]);

  const handleCancel = useCallback(() => {
    onClose();
    if (errorMessage) {
      onSuccess();
    }
  }, [errorMessage, onClose, onSuccess]);

  return (
    <Modal visible title={t('orders.need_call_modal_title')} onCancel={handleCancel} width={600} footer={null} className="centered">
      {errorMessage ? (
        <NeedCallModalErrorMessage>{errorMessage}</NeedCallModalErrorMessage>
      ) : (
        <>
          <Row>
            <Col span={12}>
              <strong>{t('orders.need_call_modal_name')}: </strong>
              <span>{name}</span>
            </Col>
            <Col span={12}>
              <strong>{t('orders.need_call_modal_phone')}: </strong>
              <span>{phone}</span>
            </Col>
          </Row>
          <NeedCallModalWrapperButton>
            <Button type="primary" onClick={() => successOrder()}>
              {t('orders.need_call_button')}
            </Button>
          </NeedCallModalWrapperButton>
        </>
      )}
    </Modal>
  );
};

NeedCallModal.propTypes = {
  clientData: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default NeedCallModal;
