import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import CategoryCard from './CategoryCard';
import { CategoryGridStyled } from '@/components/CategoryPreview/style';

const isFullWidth = (index, length) => {
  const iterationIndex = index % 10;

  return new Set([0, 3, 5, 8]).has(iterationIndex) && length - 1 === index;
};

/**
 * First row on each iteration should take 1 row if it's penultimate
 *
 * @param index
 * @param length
 * @returns {boolean}
 */
const isMinHeight = (index, length) => {
  const iterationIndex = index % 10;

  return iterationIndex === 0 && length === index + 2;
};

const CategoryGridWeb = ({ items }) => {
  return (
    <CategoryGridStyled>
      {items.map((category, i) => {
        return (
          <div key={i} className={classNames('gridElem', isFullWidth(i, items.length) && 'gridElemFw', isMinHeight(i, items.length) && 'gridElemMh')}>
            <Link to={`/cms/categories/${category.id}`}>
              <CategoryCard category={category} />
            </Link>
          </div>
        );
      })}
    </CategoryGridStyled>
  );
};

CategoryGridWeb.propTypes = {
  items: PropTypes.array.isRequired,
};

export default CategoryGridWeb;
