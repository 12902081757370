import { useTranslation } from 'react-i18next';
import React from 'react';
import { Button, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import classNames from 'classnames';
import { StringParam, useQueryParam } from 'use-query-params';
import { CardToolbox, Main, PageSorting } from '../../../styled';
import { PageHeader } from '@/components/page-headers/page-headers';
import withAuthLayout from '@/layouts/withAuthLayout';
import Table from '@/widgets/Table/Table';
import CategoryPreview from '@/components/CategoryPreview/CategoryPreview';

const DISPLAY_TYPE_TABLE = 'table';
const DISPLAY_TYPE_GRID = 'grid';

const CategoryIndex = () => {
  const { t } = useTranslation();
  const [displayType] = useQueryParam('display', StringParam);

  const columns = React.useMemo(() => [
    {
      title: t('categories.fields.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('categories.fields.is_active'),
      dataIndex: 'is_active',
      key: 'is_active',
      render: (value) => <span className={classNames('status-text', value ? 'active' : 'deactivate')}>{value ? 'Так' : 'Ні'}</span>,
    },
    {
      title: t('categories.fields.products_count'),
      dataIndex: 'products_count',
      key: 'products_count',
    },
  ]);

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={displayType === DISPLAY_TYPE_TABLE ? t('categories.list_page_header_table') : t('categories.list_page_header_grid')}
          buttons={[
            <Button className="btn-add_new" size="default" type="primary" key="1">
              <Link to="/cms/categories/create">+ {t('categories.create_button')}</Link>
            </Button>,
          ]}
        />
      </CardToolbox>

      <Main>
        <Row gutter={25}>
          <Col xs={24}>
            <PageSorting>
              <div className="page-sort-bar">
                <div className="page-sort-group">
                  <div className="sort-group">
                    <div className="layout-style">
                      <Link to={`?display=${DISPLAY_TYPE_TABLE}`} className={classNames({ active: displayType === DISPLAY_TYPE_TABLE })}>
                        <FeatherIcon icon="list" size={16} />
                      </Link>
                      <Link to={`?display=${DISPLAY_TYPE_GRID}`} className={classNames({ active: displayType === DISPLAY_TYPE_GRID })}>
                        <FeatherIcon icon="grid" size={16} />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </PageSorting>
          </Col>
        </Row>
        {displayType === DISPLAY_TYPE_TABLE ? (
          <Table columns={columns} route="cms/categories" resource="categories" permissionNamespace="cms.categories" />
        ) : (
          <CategoryPreview />
        )}
      </Main>
    </>
  );
};

export default withAuthLayout(CategoryIndex);
