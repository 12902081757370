import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { CardToolbox, Main } from '../../../styled';
import { PageHeader } from '@/components/page-headers/page-headers';
import Table from '@/widgets/Table/Table';
import withAuthLayout from '@/layouts/withAuthLayout';

const FopsIndex = () => {
  const { t } = useTranslation();

  const columns = React.useMemo(() => [
    {
      title: t('fops.fields.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('fops.fields.bot'),
      dataIndex: 'available_in_bot',
      key: 'available_in_bot',
      render: (value) => {
        return <span>{value ? t('fops.fields.active') : t('fops.fields.disabled')}</span>;
      },
    },
  ]);

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={t('fops.list_page_header')}
          buttons={[
            <Button className="btn-add_new" size="default" type="primary" key="1">
              <Link to="/accounting/fops/create">+ {t('fops.create_button')}</Link>
            </Button>,
          ]}
        />
      </CardToolbox>

      <Main>
        <Table route="accounting/fops" resource="fops" columns={columns} permissionNamespace="accounting.fops" />
      </Main>
    </>
  );
};

export default withAuthLayout(FopsIndex);
