import { useEffect, useState } from 'react';

export default function (data, onChange) {
  const [immutable, setImmutable] = useState(data);

  useEffect(() => {
    onChange(immutable.toJS());
  }, [immutable]);

  return [immutable, setImmutable];
}
