import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import ApiClient from '../../helpers/apiClient/ApiClient';
import { Cards } from '../cards/frame/cards-frame';
import { Button } from '../buttons/buttons';
import { checkboxOperations } from '@/constants/checkboxErrorLogs';

const CheckboxErrorsTable = ({ fopId }) => {
  const [logs, setLogs] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    ApiClient.call('get', `fops/${fopId}/checkbox-error-logs`).data(({ data }) => {
      setLogs(data);
    });
  }, [fopId]);

  const clear = useCallback(() => {
    ApiClient.call('post', `fops/${fopId}/clear-checkbox-error-logs`).data(() => {
      setLogs([]);
    });
  }, [fopId]);

  const columns = React.useMemo(() => [
    {
      title: t('fops.checkbox_errors.columns.id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('fops.checkbox_errors.columns.message'),
      dataIndex: 'message',
      key: 'message',
    },
    {
      title: t('fops.checkbox_errors.columns.operation'),
      dataIndex: 'operation',
      key: 'operation',
      render: (value) => <span>{checkboxOperations[value]}</span>,
    },
    {
      title: t('fops.checkbox_errors.columns.amount'),
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: t('fops.checkbox_errors.columns.source'),
      dataIndex: 'source',
      key: 'source',
    },
    {
      title: t('fops.checkbox_errors.columns.updated_at'),
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (value) => <span>{moment(value).format('DD.MM.Y HH:mm:ss')}</span>,
    },
  ]);

  return (
    <div style={{ marginTop: 20 }}>
      <Cards
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h3>{t('fops.checkbox_errors.title')}</h3>
            {Boolean(logs.length) && (
              <Button type="danger" onClick={clear}>
                <span style={{ color: '#fff' }}>{t('clear')}</span>
              </Button>
            )}
          </div>
        }
      >
        <Table columns={columns} dataSource={logs} rowKey="id" pagination={{ defaultPageSize: 50 }} />
      </Cards>
    </div>
  );
};

CheckboxErrorsTable.propTypes = {
  fopId: PropTypes.string.isRequired,
};

export default CheckboxErrorsTable;
