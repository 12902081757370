import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';
import moment from 'moment';
import FontAwesome from 'react-fontawesome';
import { message } from 'antd';
import PropTypes from 'prop-types';
import { CardToolbox, Main } from '@/container/styled';
import { PageHeader } from '@/components/page-headers/page-headers';
import Table from '@/widgets/Table/Table';
import withAuthLayout from '@/layouts/withAuthLayout';
import { Tag } from '@/components/tags/tags';

import { APPLICATION_STATUS_PROCESSED, APPLICATION_STATUS_WAIT_REPLY } from '@/constants/siteApplications';
import { Button } from '@/components/buttons/buttons';
import ApiClient from '@/helpers/apiClient/ApiClient';
import AsyncSelectFilter from '@/widgets/Table/AsyncSelectFilter';
import DateRangeFilter from '@/widgets/Table/DateRangeFilter';

const STATUSES = {
  [APPLICATION_STATUS_WAIT_REPLY]: 'orange',
  [APPLICATION_STATUS_PROCESSED]: 'green',
};

const CloseApplication = ({ id, status, onStatusChange }) => {
  const { t } = useTranslation();
  const close = useCallback(() => {
    ApiClient.call('post', `site-applications/${id}/close`).data(({ status }) => {
      onStatusChange(status);
      message.success(t('site_applications.order_processed'));
    });
  }, [id]);

  return (
    <>
      {status === APPLICATION_STATUS_PROCESSED ? (
        <FontAwesome name="check-circle" size="2x" style={{ color: 'green' }} title={t('site_applications.order_processed')} />
      ) : (
        <Button type="success" onClick={close}>
          {t('site_applications.order_close')}
        </Button>
      )}
    </>
  );
};

CloseApplication.propTypes = {
  id: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  onStatusChange: PropTypes.func.isRequired,
};

const SiteApplicationIndex = () => {
  const { t } = useTranslation();

  const columns = React.useMemo(() => [
    {
      title: t('site_applications.columns.id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('site_applications.columns.city_id'),
      dataIndex: 'city',
      key: 'city_id',
      render: (value) => <span>{value?.name}</span>,
      filterDropdown: (props) => <AsyncSelectFilter {...props} searchUrl="cities/search" />,
    },
    {
      title: t('site_applications.columns.status'),
      dataIndex: 'status',
      render: (value) => <Tag color={STATUSES[value]}>{t(`site_applications.statuses.${value}`)}</Tag>,
      filters: [
        {
          text: t(`site_applications.statuses.${APPLICATION_STATUS_WAIT_REPLY}`),
          value: APPLICATION_STATUS_WAIT_REPLY,
        },
        {
          text: t(`site_applications.statuses.${APPLICATION_STATUS_PROCESSED}`),
          value: APPLICATION_STATUS_PROCESSED,
        },
      ],
    },
    {
      title: t('site_applications.columns.created_at'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value) => <span>{moment(value).format('DD.MM.Y HH:mm:ss')}</span>,
      filterDropdown: (props) => <DateRangeFilter {...props} />,
      sorter: true,
    },
    {
      title: t('site_applications.columns.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('site_applications.columns.phone'),
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: t('site_applications.columns.email'),
      dataIndex: 'email',
      key: 'email',
    },
  ]);

  return (
    <div>
      <CardToolbox>
        <PageHeader ghost title={t('site_applications.list_page_header')} />
      </CardToolbox>

      <Main>
        <Table
          route="crm/site-applications"
          resource="site-applications"
          columns={columns}
          permissionNamespace="crm.site_applications"
          actionOptions={{ isEditable: false, isDeletable: false }}
          defaultSort={{ field: 'created_at', direction: 'descend' }}
          additionalActions={(row, setData) => {
            return (
              <CloseApplication
                id={row.id}
                status={row.status}
                onStatusChange={(status) => {
                  setData((data) => {
                    const index = data.findIndex((el) => el.id === row.id);
                    const items = [...data];
                    items[index] = { ...items[index], status };

                    return items;
                  });
                }}
              />
            );
          }}
        />
      </Main>
    </div>
  );
};

export default withAuthLayout(SiteApplicationIndex);
