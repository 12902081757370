/* eslint-disable */
import React from "react";
import { ErrorContext } from "./errorContext";
// import Error500 from "../pages/error/Error500";
import Error404 from "../container/pages/404";
import Error403 from "../container/pages/403";
import Error500 from "../container/pages/500";

export default class ErrorBoundary extends React.Component {
    static contextType = ErrorContext;

    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        this.context.setError(error);
    }

    render() {
        const {error} = this.context;
        if (error) {
            if (error.name === "NotFoundError") {
                return <Error404 />;
            }
            if (error.name === "UnauthorizedError") {
                return <Error403 />
            }

             return <Error500 />;
        }

        return this.props.children;
    }
}
