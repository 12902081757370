import { Link } from 'react-router-dom';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const FilterButtons = ({ periodState, setPeriodState }) => {
  const [filterState, setFilterState] = useState(periodState);
  const { t } = useTranslation();

  const handleActiveChangePeriods = (value) => {
    return (event) => {
      event.preventDefault();
      setFilterState(value);
      setPeriodState(value);
    };
  };

  const filterButtonsData = [
    { name: t('site_statistic.periods.today'), period: 'today' },
    { name: t('site_statistic.periods.week'), period: 'week' },
    { name: t('site_statistic.periods.month'), period: 'month' },
    { name: t('site_statistic.periods.year'), period: 'year' },
  ];

  return (
    <div className="card-nav">
      <ul>
        {filterButtonsData.map((item, index) => (
          <li className={filterState === item.period ? 'active' : 'deactivate'} key={index}>
            <Link onClick={handleActiveChangePeriods(item.period)} to="#">
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

FilterButtons.propTypes = {
  periodState: PropTypes.string.isRequired,
  setPeriodState: PropTypes.func.isRequired,
};

export default FilterButtons;
