import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, message, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { BasicFormWrapper, Main } from '../../../styled';
import { HorizontalFormStyleWrap } from '../../../forms/overview/Style';
import { ActionsWrapper, FormWrapper } from '../../style';
import withAuthLayout from '@/layouts/withAuthLayout';
import ApiClient from '@/helpers/apiClient/ApiClient';
import Heading from '@/components/heading/heading';
import FormInput from '@/form/FormInput';
import FormSelect from '@/form/FormSelect';
import TabForm from '@/widgets/TabForm';
import useFormValues from '@/hooks/useFormValues';
import FormSubmit from '@/form/FormSubmit';
import { useAuth } from '@/helpers/userContext';
import { PageHeader } from '@/components/page-headers/page-headers';

const TAB_PERSONAL_INFO = 1;
const TAB_CREDENTIALS = 2;

const PersonalInfo = () => {
  const { t } = useTranslation();
  const { form, id, handleSubmit, loading, errors } = useFormValues('users');
  const [roles, setRoles] = useState([]);
  const { user, checkPerm } = useAuth();

  useEffect(() => {
    ApiClient.call('get', 'roles/list').data((data) => {
      setRoles(data);
    });
  }, []);

  return (
    <div className="user-info-form">
      <BasicFormWrapper>
        <HorizontalFormStyleWrap className="sDash_input-form">
          <Form style={{ width: '100%' }} form={form} name="info" layout="vertical" onFinish={handleSubmit} disabled={!checkPerm('administrating.users.edit')}>
            <Heading className="form-title" as="h4">
              {t('personal_info')}
            </Heading>
            <FormInput name="first_name" label={t('user.first_name')} error={errors.first_name} placeholder="Володимир" />
            <FormInput name="last_name" label={t('user.last_name')} error={errors.last_name} placeholder="Зеленський" />
            <FormInput name="email" label={t('user.email')} error={errors.email} placeholder="test@gmail.com" />
            <FormSelect name="role_id" label={t('user.role')} options={roles} error={errors.role_id} placeholder={t('user.choose_role')} />
            {user.is_admin && (
              <Form.Item name="is_admin" valuePropName="checked">
                <Checkbox>{t('user.full_access')}</Checkbox>
              </Form.Item>
            )}
            {!id && (
              <>
                <FormInput name="password" label={t('user.password')} type="password" error={errors.password} />
                <FormInput name="password_confirmation" label={t('user.password_confirmation')} type="password" error={errors.password_confirmation} />
              </>
            )}
            <ActionsWrapper>
              <FormSubmit loading={loading} />
            </ActionsWrapper>
          </Form>
        </HorizontalFormStyleWrap>
      </BasicFormWrapper>
    </div>
  );
};

const Credentials = ({ id }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    ApiClient.call('patch', `users/update-credentials/${id}`, {}, values).data(() => {
      message.success('Данні оновленно!');
    });
  };

  return (
    <div className="user-info-form">
      <FormWrapper>
        <Form style={{ width: '100%' }} form={form} name="info" layout="vertical" onFinish={handleSubmit}>
          <Heading className="form-title" as="h4">
            {t('authorization')}
          </Heading>
          <FormInput name="password" label={t('user.password')} type="password" autoComplete="new-password" />
          <FormInput name="password_confirmation" label={t('user.password_confirmation')} type="password" />
          <ActionsWrapper>
            <FormSubmit />
          </ActionsWrapper>
        </Form>
      </FormWrapper>
    </div>
  );
};

const UserForm = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { checkPerm } = useAuth();

  const tabs = [
    {
      key: TAB_PERSONAL_INFO,
      name: t('personal_info'),
      icon: 'user',
      component: <PersonalInfo id={id} />,
    },
  ];

  if (id && checkPerm('administrating.users.edit')) {
    tabs.push({
      key: TAB_CREDENTIALS,
      name: t('authorization'),
      icon: 'briefcase',
      component: <Credentials id={id} />,
    });
  }

  return (
    <>
      <PageHeader ghost title={t('profile_edit')} />
      <Main>
        <TabForm tabs={tabs} />
      </Main>
    </>
  );
};

Credentials.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default withAuthLayout(UserForm);
