import { PageHeader, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Main } from '@/container/styled';
import withAuthLayout from '@/layouts/withAuthLayout';
import ApiClient from '@/helpers/apiClient/ApiClient';
import Spinner from '@/widgets/Spinner';
import StatisticCharts from '@/components/statistic/conversion/StatisticCharts';
import StatisticsCities from '@/components/statistic/conversion/statisticsCities';
import StoreNoCity from '@/components/statistic/conversion/storeNoCity';

function Conversion() {
  const { t } = useTranslation();
  const [citiesData, setCitiesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    ApiClient.call('get', '/statistic/cities')
      .data(({ data }) => {
        setCitiesData(data);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <div>
      <PageHeader ghost title={t('conversion.list_page_header')} />

      <Main>
        <Row gutter={[16, 16]} type="flex" align="center" justify="center">
          <StatisticsCities />
          {isLoading ? <Spinner /> : citiesData.map((city) => <StatisticCharts city={city} key={city.id} />)}
          <StoreNoCity />
        </Row>
      </Main>
    </div>
  );
}

export default withAuthLayout(Conversion);
