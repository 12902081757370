import React from 'react';
import PropTypes from 'prop-types';
import { CategoryStyled } from './style';

const CategoryCard = ({ category, isMobile, style, textColor = '#fff' }) => {
  return (
    <CategoryStyled isMobile={isMobile} style={style}>
      {category.image && <img src={category.image.sizes.medium} alt="" />}
      <span style={{ color: textColor }}>{category.name}</span>
    </CategoryStyled>
  );
};

CategoryCard.propTypes = {
  category: PropTypes.object.isRequired,
  isMobile: PropTypes.any,
  style: PropTypes.object,
  textColor: PropTypes.string,
};

export default CategoryCard;
