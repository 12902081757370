import React, { useCallback, useEffect, useState } from 'react';
import { fromJS } from 'immutable';
import { Empty, message, Spin } from 'antd';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import slugify from 'slugify';
import { useTranslation } from 'react-i18next';
import ProductSizeElem from './ProductSizeElem';
import { ProductSizeFromStyled } from './style';
import { Button } from '@/components/buttons/buttons';
import ApiClient from '@/helpers/apiClient/ApiClient';
import useImmutableData from '@/hooks/useImmutableData';

const ProductSizeForm = ({ value = [], onChange, billingGoodId, errors, hasHeight = true }) => {
  const [data, setData] = useImmutableData(fromJS(value), onChange);
  const [loading, setLoading] = useState(false);
  const [isInit, setIsInit] = useState(false);

  const { t } = useTranslation();

  const fetchFromBilling = useCallback(() => {
    setLoading(true);
    ApiClient.call('GET', 'billing/get-sizes-for-product', { billing_good_id: billingGoodId }).data((data) => {
      setData(fromJS(data));
      setLoading(false);
    });
  }, [setLoading, setData, billingGoodId]);

  useEffect(() => {
    if (!value.length || isInit) {
      fetchFromBilling();
    }
    setIsInit(true);
  }, [fetchFromBilling]);

  const handleChangeSize = (id) => {
    return (key, value) => {
      setData((data) => {
        return data.setIn([id, key], value);
      });
    };
  };

  const handleChangeSizeIsPrimary = (id) => {
    return (value) => {
      setData((data) => {
        return data.map((elem, index) => {
          if (id === index) {
            return elem.set('is_primary', value);
          }
          return elem.set('is_primary', false);
        });
      });
    };
  };

  const beautify = () => {
    setData((data) => {
      return data.map((elem) => {
        return elem.update((value) => {
          const name = value
            .get('name')
            .match(/\d+\s*см/)
            ?.toString();

          return value
            .set('name', name || value.get('name'))
            .set('height', get(name?.match(/(\d+)\sсм/), 1) || null)
            .set('slug', slugify(name || value.get('name'), { lower: true }));
        });
      });
    });
    message.success(t('success_message'));
  };

  if (loading) {
    return <Spin size="large" style={{ marginTop: 20 }} />;
  }

  return (
    <ProductSizeFromStyled>
      {data.size ? (
        <div className="container">
          <div className="heading">
            <h3>{t('products.sizes')}:</h3>
          </div>
          {data.map((size, index) => {
            return (
              <ProductSizeElem
                key={index}
                size={size.toJS()}
                billingGoodId={billingGoodId}
                onChange={handleChangeSize(index)}
                onIsPrimaryChange={handleChangeSizeIsPrimary(index)}
                nameError={errors && get(errors, `sizes.${index}.name`)}
                heightError={errors && get(errors, `sizes.${index}.height`)}
                slugError={errors && get(errors, `sizes.${index}.slug`)}
                isPrimaryError={errors && get(errors, `sizes.${index}.is_primary`)}
                hasHeight={hasHeight}
              />
            );
          })}
          <div className="actions">
            <Button type="primary" onClick={fetchFromBilling}>
              {t('products.synchronize_with_1c')}
            </Button>
            <Button type="dashed" onClick={beautify} disabled={data.find((el) => el.getIn(['name']) === '')}>
              {t('products.transform')}
            </Button>
          </div>
        </div>
      ) : (
        <Empty />
      )}
    </ProductSizeFromStyled>
  );
};

ProductSizeForm.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onChange: PropTypes.func,
  billingGoodId: PropTypes.string.isRequired,
  errors: PropTypes.object,
  hasHeight: PropTypes.bool,
};

export default ProductSizeForm;
