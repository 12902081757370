import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CategoryCard from './CategoryCard';

const CategoryGridMobile = ({ items }) => {
  return (
    <>
      {items.map((item, i) => {
        const isSecond = i === 1;

        return (
          <Link to={`/cms/categories/${item.id}`} key={item.id}>
            <CategoryCard category={item} isMobile textColor={isSecond ? '#346556' : '#fff'} />
          </Link>
        );
      })}
    </>
  );
};

CategoryGridMobile.propTypes = {
  items: PropTypes.array.isRequired,
};

export default React.memo(CategoryGridMobile);
