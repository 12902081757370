import Styled from 'styled-components';

const NeedCallModalErrorMessage = Styled.p`
  color: red;
  font-weight: 600;
`;

const NeedCallModalWrapperButton = Styled.div`
  display: flex;
  justify-content: end;
  margin-top: 20px;
`;

export { NeedCallModalErrorMessage, NeedCallModalWrapperButton };
