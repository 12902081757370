import React from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import AsyncSelect from '../widgets/AsyncSelect';

const FormSelectAsync = ({ name, label, error, resource, params, disabled, multiple = false, required = false }) => {
  return (
    <Form.Item
      name={name}
      required={required}
      validateStatus={error ? 'error' : false}
      help={error}
      label={label}
      // getValueProps={(value) => {
      //   return { value: value?.map((e) => e.id) };
      // }}
    >
      <AsyncSelect resource={resource} params={params} disabled={disabled} multiple={multiple} />
    </Form.Item>
  );
};

FormSelectAsync.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  error: PropTypes.any,
  resource: PropTypes.string.isRequired,
  params: PropTypes.object,
  disabled: PropTypes.bool,
  multiple: PropTypes.any,
  required: PropTypes.any,
};

export default FormSelectAsync;
