import { Empty } from 'antd';
import PropTypes from 'prop-types';
import { NoDataPieMessageWrap } from '@/components/statistic/conversion/styled';

function NoDataPieMessage({ title }) {
  return (
    <NoDataPieMessageWrap>
      <h3 className="no-data-pie-title">{title}</h3>
      <div className="no-data-pie-container">
        <Empty className="no-data-pie-value" />
      </div>
    </NoDataPieMessageWrap>
  );
}

NoDataPieMessage.propTypes = {
  title: PropTypes.string,
};

export default NoDataPieMessage;
