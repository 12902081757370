import { Input } from 'antd';
import React from 'react';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';

const PhoneInput = ({ value, onChange, disabled = false }) => {
  const handleChange = (number) => {
    onChange(number.replace(/(\+)|(\s)|(\()|(\))|(_)/g, ''));
  };

  return (
    <InputMask mask="+38 (099) 999 99 99" value={value} onChange={(e) => handleChange(e.target.value)} disabled={disabled}>
      <Input type="tel" />
    </InputMask>
  );
};

PhoneInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default PhoneInput;
