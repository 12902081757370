import FeatherIcon from 'feather-icons-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CardBarChart2, EChartCard } from '@/components/statistic/siteStatistic/styled';
import Heading from '@/components/heading/heading';
import ChartjsBarChartTransparent from '@/components/statistic/siteStatistic/ChartjsBarChartTransparent';
import { Cards } from '@/components/cards/frame/cards-frame';
import Spinner from '@/widgets/Spinner';

const CardChunk = ({ title, cardData, isLoading = false, fixedValue = 2 }) => {
  const { data, labels, value, rate } = cardData;
  const { t } = useTranslation();

  return (
    <Cards headless>
      {isLoading ? (
        <Spinner />
      ) : (
        <EChartCard>
          <div className="card-chunk">
            <CardBarChart2>
              <Heading as="h1">{value ? Intl.NumberFormat('uk-UA').format(Number(value).toFixed(fixedValue)) : 0}</Heading>
              <span>{title}</span>
              <p>
                <span className={rate?.toString().startsWith('-') ? 'growth-downward' : 'growth-upward'}>
                  <FeatherIcon icon={rate?.toString().startsWith('-') ? 'arrow-down' : 'arrow-up'} /> {rate?.toFixed(2)}%
                </span>
                <span>{t('site_statistic.card_chunk_text')}</span>
              </p>
            </CardBarChart2>
          </div>
          <div className="card-chunk">
            <ChartjsBarChartTransparent
              labels={labels}
              datasets={[
                {
                  data,
                  backgroundColor: '#EFEFFE',
                  hoverBackgroundColor: '#5F63F2',
                  label: title,
                  barPercentage: 1,
                },
              ]}
            />
          </div>
        </EChartCard>
      )}
    </Cards>
  );
};

CardChunk.propTypes = {
  title: PropTypes.string,
  cardData: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  fixedValue: PropTypes.number,
};

export default CardChunk;
