import Styled from 'styled-components';
import { Row } from 'antd';

const ButtonGroup = Styled.div`
    border-top: 1px solid #EEEFF2;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    margin: -4px -4px -15px;
    p{
        font-size: 13px;
    margin: ${({ theme }) => (theme.rtl ? '0 0 0 20px' : '0 20px 0 0')};
        font-weight: 500;
        color: ${({ theme }) => theme['gray-color']};
    }
    button {
        font-size: 12px;
        margin: 4px;
        height: 32px;
        padding: 0px 13.26px;
    }
`;

const HeaderWrapper = Styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 30px;
  
  @media (max-width: 600px){
    flex-direction: column;
    margin-bottom: 20px;
    padding-right: 0;
  }
`;

const SpanPeriod = Styled.span`
  font-size: 12px;
  background: #f3f3f3;
  color: black;
  padding: 1px 3px;
  margin-right: 10px;
 `;

export { ButtonGroup, HeaderWrapper, SpanPeriod };
